import React, { useState, useEffect, useContext } from 'react';
import '../../../../scss/userMaintenance/_search.scss';
import { getRole_id } from '../../../../service/userMaintenance/userManagement.service';
import MenuContext from '../../../../context/MenuContext';

export default function Search({ handleSearchUserRole, roleList }) {
    const [username, setUsername] = useState("");
    const [role_id, setRole_id] = useState(0);
    const { permission } = useContext(MenuContext);

    const searchUserRole = (username, role_id) => {
        handleSearchUserRole(username, role_id);
    }

    return (
        <>
            <div className="row user-man-row">
                <div className="col-xl-5">
                    <label>Username :</label>
                    <input
                        type="text"
                        className="user-name"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        maxLength={50}
                        disabled={!permission.some((res) => res === "search")}
                    />
                </div>
                <div className="col-xl-4">
                    <label>Role :</label>
                    <select
                        className="role-select"
                        onChange={(e) => setRole_id(e.target.value)}
                        value={role_id}
                        disabled={!permission.some((res) => res === "search")}
                    >
                        <option value={0}></option>
                        {roleList?.map((roles, index) => {
                            return (
                                <>
                                    <option key={index} value={roles.role_id}>
                                        {roles.role_name}
                                    </option>
                                </>
                            )
                        })}
                    </select>
                </div>
                <div className="col-xl-3">
                    <button
                        type="submit"
                        className={!username && role_id == 0 ? "disable-search-button" : "button-search"}
                        disabled={(!username && role_id == 0) || !permission.some((res) => res === "search")}
                        onClick={() => searchUserRole(username, role_id)}
                    >
                        <span>ค้นหา</span>
                    </button>
                </div>
            </div>
        </>
    )
}
