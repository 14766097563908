import axios from "axios";
const etaxApiUrl = `${process.env.REACT_APP_ETAX_API_URL}/amos-taxinvoice/tax_invoice`;
const fullTaxInvoiceRequestable = `${process.env.REACT_APP_ETAX_API_URL}/creditnote/order/full_tax_invoice/requestable`;
const basicAuth = process.env.REACT_APP_BASIC_AUTHORIZATION_ETAX_API;
const xApiKey = process.env.REACT_APP_ETAX_API_KEY;

const putEtaxData = (request) => {
  const req = {
    "national_id_card": request.national_id_card,
    "mobile_number": request.mobile_number,
    "customer_type_no": request.customer_type_no,
    "address": {
      "first_name": request.address.first_name,
      "last_name": request.address.last_name,
      "company_name": request.address.company_name,
      "branch_number": request.address.branch_number,
      "house": request.address.house,
      "place": request.address.place,
      "moo": request.address.moo,
      "soi": request.address.soi,
      "street": request.address.street,
      "province": request.address.province,
      "district": request.address.district,
      "sub_district": request.address.sub_district,
      "postal_code": request.address.postal_code
    }
  };

  const config = {
    method: 'put',
    url: `${etaxApiUrl}/?ordersource=${request.ordersource}&external_orderno=${request.external_orderno}`,
    headers: {
      'Authorization': basicAuth,
      'Content-Type': 'application/json',
      'x-api-key': xApiKey
    },
    data: req
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

const postEtaxData = (request) => {
  const config = {
    method: 'post',
    url: `${etaxApiUrl}`,
    headers: {
      'Authorization': basicAuth,
      'Content-Type': 'application/json',
      'x-api-key': xApiKey
    },
    data: request
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

const getEtaxData = async (external_orderno) => {
  const config = {
    method: 'get',
    url: `${etaxApiUrl}/?external_orderno=${external_orderno}`,
    headers: {
      'Authorization': basicAuth,
      'Content-Type': 'application/json',
      'x-api-key': xApiKey
    }
  };
  const data = await axios(config)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.log(err);
      return {};
    })
  return data;
};

const getFullTaxInvoiceRequestable = async (orderNo, orderSource) => {
  const config = {
    method: 'get',
    url: `${fullTaxInvoiceRequestable}?order_no=${orderNo}&ordersource=${orderSource}`,
    headers: {
      'Authorization': basicAuth,
      'Content-Type': 'application/json',
      'x-api-key': xApiKey
    }
  };
  const data = await axios(config)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.log(err);
      return {};
    })
  return data;
};

export { getEtaxData, postEtaxData, putEtaxData, getFullTaxInvoiceRequestable };
