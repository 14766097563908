import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getOrderSourceList } from "../../../../service/etax/etax.service";
import "../../../../scss/etax/_searchEtax.scss";
import MenuContext from "../../../../context/MenuContext";

const SearchEtax = ({ handleSearchData, alertNotFound }) => {
  const { permission } = useContext(MenuContext);
  const [external_orderno, setExternal_orderno] = useState("");

  const clickSearch = (external_orderno) => {
    handleSearchData(external_orderno);
  };

  return (
    <>
      <div className="row margin-bottom">
        <div className="col-5 etax-search">
          <label>ค้นหา : </label>
          <input
            name="external_orderno"
            type="text"
            className={alertNotFound ? "form-control alert-red" : "form-control"}
            placeholder="เลขที่คำสั่งซื้อ"
            aria-label="Search"
            value={external_orderno}
            maxlength={10}
            onChange={(e) => setExternal_orderno(e.target.value)
            }
          />
          {alertNotFound ? "" : <FontAwesomeIcon icon={faSearch} />}
        </div>

        {/*<div className="col-5 etax-search">
          <label className="doc-type">OrderSource</label>
          <select
            name="orderSource"
            className="form-select"
            aria-label="Default select example"
            value={orderSource}
            onChange={(e) => setOrderSource(e.target.value)}
          >
            <option value=""></option>
            {orderSourceList?.map((item, index) => {
              return (
                <>
                  <option key={index} value={item.key}>
                    {item.shortname} ({item.key})
                  </option>
                </>
              );
            })}
          </select>
        </div>
         <div className="col-5 etax-search">
                    <label className="doc-type">ประเภทเอกสาร</label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={searchEtaxData.doccumentType}
                        onChange={(e) => handleChange(e)}
                    >
                        <option value="0">ทั้งหมด</option>
                        <option value="1">ใบเสร็จรับเงิน</option>
                        <option value="2">ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ</option>
                        <option value="3">ใบเสร็จรับเงิน/ใบกำกับภาษี</option>
                    </select>
                </div> */}
        <div className="col-2 etax-button">
          <button
            className="btn btn-primary"
            onClick={() => clickSearch(external_orderno)}
            disabled={
              !external_orderno &&
              permission.some((res) => res === "search")
            }
          >
            ค้นหา
          </button>
        </div>
        {alertNotFound && <div className="alert-not-found">ไม่พบข้อมูล</div>}
      </div>
      <br />
    </>
  );
};
export default SearchEtax;
