import axios, { AxiosRequestConfig } from "axios";
import fs from "fs";

const apiUrl = process.env.REACT_APP_API_URL;
const EcnApiUrl = process.env.REACT_APP_ECN_API_URL;
const xApiKey = process.env.REACT_APP_ETAX_API_KEY;

const getCreditNoteList = async (orderOrCreditNoteNo, status) => {
  const data = await axios
    .get(
      `${EcnApiUrl}/grrs/creditnote?document_no=${orderOrCreditNoteNo}&status=${status}`,
      {
        headers: {
          "x-api-key": xApiKey,
        },
      }
    )
    .then((res) => {
      if (res.data.response_code !== 100) return res.data;
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return [];
    });
  return data;
};

const getOrderSourceList = async () => {
  const data = await axios
    .get(`${EcnApiUrl}/order/ordersource`, {
      headers: {
        "x-api-key": xApiKey,
      },
    })
    .then((res) => {
      if (res.data.response_code !== 100) return [];
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return [];
    });
  return data;
};

const getCreditNoteDetails = async (ecn_id) => {
  const data = await axios
    .get(`${EcnApiUrl}/grrs/creditnote/${ecn_id}`, {
      headers: {
        "x-api-key": xApiKey,
      },
    })
    .then((res) => {
      if (res.data.response_code !== 100) return {};
      return res.data.response_data;
    })
    .catch((e) => {
      console.log("e", e);
      return {};
    });
  return data;
};

const getEcnStatus = async () => {
  const data = await axios
    .get(`${EcnApiUrl}/status`, {
      headers: {
        "x-api-key": xApiKey,
      },
    })
    .then((res) => {
      if (res.data.response_code !== 100) return [];
      return res.data.response_data;
    })
    .catch((e) => {
      console.log("e", e);
      return [];
    });
  return data;
};

const getOrderDetailsByOrderNo = async (orderNo, orderSource) => {
  const data = await axios
    .get(
      `${EcnApiUrl}/grrs/return_request/creditnote/order?order_no=${orderNo}&ordersource=${orderSource}`,
      {
        headers: {
          "x-api-key": xApiKey,
        },
      }
    )
    .then((res) => {
      if (res.data.response_code !== 100) return res.data;
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return {};
    });

  return data;
};

const postCalculateRefund = async (
  order_no,
  ordersource,
  action_type,
  order_detail_list
) => {
  const data = await axios
    .post(
      `${EcnApiUrl}/grrs/return_request/creditnote/calculate_refund`,
      {
        order_no: order_no,
        ordersource: ordersource,
        action_type: action_type,
        order_detail_list: order_detail_list,
      },
      {
        headers: {
          "x-api-key": xApiKey,
        },
      }
    )
    .then((res) => {
      if (res.data.response_code !== 100) return {};
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return {};
    });
  return data;
};

const postReturnRequestCreditNote = async (
  order_no,
  ordersource,
  action_type,
  total_refund
) => {
  const data = await axios
    .post(
      `${EcnApiUrl}/grrs/return_request/creditnote`,
      {
        order_header: {
          order_no: order_no,
          ordersource: ordersource,
          action_type: action_type,
        },
        total_refund: total_refund,
      },
      {
        headers: {
          "x-api-key": xApiKey,
        },
      }
    )
    .then((res) => {
      if (res.data.response_code !== 100) return res.data;
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return {};
    });

  return data;
};

const putCreditnoteTotalRefund = async (ecn_id, total_refund) => {
  const data = await axios
    .put(
      `${EcnApiUrl}/grrs/creditnote/total_refund`,
      {
        ecn_id: ecn_id,
        total_refund: total_refund,
      },
      {
        headers: {
          "x-api-key": xApiKey,
        },
      }
    )
    .then((res) => {
      if (res.data.response_code !== 100) return res.data;
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return [];
    });

  return data;
};

const getImportFileApprove = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const data = await axios({
    method: "post",
    url: `${EcnApiUrl}/grrs/creditnote/workflow/approve/import`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data", "x-api-key": xApiKey },
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log("e", e);
      return {};
    });
  return data;
};

const getExportCreditNoteRequest = async () => {
  const data = await axios({
    method: "get",
    url: `${EcnApiUrl}/grrs/creditnote/request/export`,
    headers: {
      "Content-Type": "application/vnd.ms-excel",
      "x-api-key": xApiKey,
    },
    responseType: "blob",
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log("e", e);
      return {};
    });
  return data;
};

export {
  getCreditNoteList,
  getOrderSourceList,
  getCreditNoteDetails,
  getEcnStatus,
  getOrderDetailsByOrderNo,
  postCalculateRefund,
  postReturnRequestCreditNote,
  putCreditnoteTotalRefund,
  getImportFileApprove,
  getExportCreditNoteRequest,
};
