import React, { useState } from "react";
import "../../Share/FormRequestCancel/FormRequestCancel.scss";
import { numberWithCommas } from "../../../../../helper/commonHelper";

function SummaryRefundCancelView({
  cancellationInitial,
  refundAllMemberPoint = 0,
  refundNonAllMemberPoint = 0,
  cancelAllMemberPoint = 0,
  cancelNonAllMemberPoint = 0,
}) {
  const [isDisplay, setIsDisplay] = useState(true);
  const { orders } = cancellationInitial;

  return (
    <div className="form-request-cancel">
      <div className="header-request-cancel header-request-cancel-view">
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">
          สรุปยอดโอนคืนเงิน และ ยกเลิก ALL Point , M-Stamp ,
          และการสะสมแต้มทั้งหมด
        </span>
      </div>
      {isDisplay && (
        <div className="body-request-cancel">
          <div className="container">
            <table className="summary-refund-table ">
              <tbody>
                <tr>
                  <td>ยอดคืนเงินสินค้าทั้งสิ้น (รวมภาษีมูลค่าเพิ่ม)</td>
                  <td className="fw-bold text-end">
                    ฿ {numberWithCommas(orders.invoice_value)}
                  </td>
                </tr>

                <tr className="striped">
                  <td>ยอดโอนคืน ALL Point (แต้ม)</td>
                  <td className="fw-bold text-end">
                    {numberWithCommas(refundAllMemberPoint)}
                  </td>
                </tr>

                <tr>
                  <td>ยอดโอนคืน M-Stamp / เหรียญ (บาท) </td>
                  <td className="fw-bold text-end">
                    ฿ {numberWithCommas(refundNonAllMemberPoint)}
                  </td>
                </tr>

                <tr className="striped">
                  <td>ยกเลิก ALL Point ที่ได้รับ (แต้ม)</td>
                  <td className="fw-bold text-end">
                    {numberWithCommas(cancelAllMemberPoint)}
                  </td>
                </tr>

                <tr>
                  <td>ยกเลิก M-Stamp / เหรียญ ที่ได้รับ (บาท)</td>
                  <td className="fw-bold text-end">
                  ฿ {numberWithCommas(cancelNonAllMemberPoint)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table className="detail-cancle-table">
            <tbody>
              <tr className="">
                <th className="th-left col-md-6">สินค้า</th>
                <th className="text-center col-md-3">จำนวน</th>
                <th className="th-right text-center col-md-3">ราคา</th>
              </tr>
              {orders.order_detail_list.map((order, index) => (
                <tr className="">
                  <td className="col-md-6">
                    <div>{order.item_name}</div>
                    <div>รหัสสินค้า {order.item_no}</div>
                  </td>
                  <td className="text-center col-md-3">{order?.return_qty}</td>
                  <td className="text-center col-md-3">
                    <span className="mr-2">฿</span>
                    <span>{numberWithCommas(order?.price_unit)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SummaryRefundCancelView;
