const equalObject = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

const checkAccessToken = (statusCode) => {
  if (statusCode === 401) {
    localStorage.clear();
    window.location.href = "/";
  }
};

export { equalObject, checkAccessToken };
