import React, { useState, useEffect, useContext } from "react";
import FormSearch from "../../components/Share/FormSearch";
import Table from "../../components/Share/Table";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { Col, Container, Row } from "react-bootstrap";
import "./CustomerService.scss";
import { useHistory } from "react-router-dom";
import { postCancellationRequestApproval } from "../../../../service/grrs/master.service";
import { postExportCancellationRequest } from "../../../../service/grrs/contactCenter.service";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";
function CustomerService(props) {
  const FileDownload = require("js-file-download");
  const [rawData, setRawData] = useState([]);
  const [exportData, setExportData] = useState(defaultFilter);
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { permission } = useContext(MenuContext);
  const isCanSearch = permission.some((res) => res === "search");
  let history = useHistory();

  const exportDataToExcel = () => {
    setIsButtonLoading(true);
    postExportCancellationRequest(exportData).then((res) => {
      const contentDisposition = res?.headers["content-disposition"];
      if (res !== null && contentDisposition !== undefined) {
        const filename = contentDisposition.split("filename=")[1].trim();
        FileDownload(res.data, filename);
      }
      setIsButtonLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    postCancellationRequestApproval(defaultFilter).then((res) => {
      if (res?.data === undefined) {
        setRawData([]);
        setIsNoData(true);
        setIsLoading(false);
      } else {
        setRawData(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  const goRequestCancel = (row) => {
    history.push(
      `/grrs/customer-service/พิจารณายกเลิกการสั่งซื้อ/customer-service-request-cancel/${row.original.order_no}&${row.original.ordersource}&${row.original.customer_request_no}`
    );
  };

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />

            <Col md={9} className="content">
              <LoadingSpinner isLoading={isLoading} />
              <div className="container customer-service-container">
                <h1 className="h1-header">Customer Care Data List</h1>
                <Row>
                  <Col md={12}>
                    <button
                      className="export-to-excel grrs-white-button"
                      onClick={exportDataToExcel}
                    >
                      <img
                        className="icon-excel"
                        src="/assets/icons/excel-app.svg"
                        alt="excel"
                      />
                      <span className="font-size">Export To Excel</span>
                      <span
                        class="spinner-border spinner-border-xl button-spinner"
                        role="status"
                        aria-hidden="true"
                        hidden={!isButtonLoading}
                      ></span>
                    </button>

                    <FormSearch
                      setSearchData={setRawData}
                      setExportData={setExportData}
                      setIsLoading={setIsLoading}
                    />
                  </Col>
                </Row>
                {!isLoading && permission?.length !== 0 && (
                  <>
                    <Row className="table-content">
                      <Table
                        data={isCanSearch ? rawData : []}
                        redirectFunction={goRequestCancel}
                        isNoData={isNoData}
                        setIsNoData={setIsNoData}
                        isCanSearch={isCanSearch}
                      />
                    </Row>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <PermissionModal permission={permission} backPage={"/grrs"} />
    </Container>
  );
}

export default CustomerService;

const defaultFilter = {
  reason_code: null,
  refund_channel_code: null,
  request_refund_status_code: null,
  request_type_code: null,
  customer_request_no: null,
  order_date_to: null,
  order_no: null,
  phone_no: null,
  ordersource: null,
  order_date_from: null,
};
