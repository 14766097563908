import { paginate } from "../../../utils/paginate";
import Pagination from "../../common/components/Pagination";
import Table from "./common/Table";
import React, { useState, useContext, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Search from "./common/Search";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import { getOrderReturnWarehouse } from "../../../service/freedelivery/freedelivery.service";
import MenuContext from "../../../context/MenuContext";
import PermissionModal from "../../common/components/PermissionModal";

const ReturnWareHouse = () => {
  const [users, setUsers] = useState([]);
  const [isUserEmpty, setIsUserEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerNum, setCustomerNum] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchDataObj, setSearchDataObj] = useState({});
  const { permission } = useContext(MenuContext);

  const returnWareHouseStatus = [
    "C3",
    "C5",
    "C7",
    "C8"];
  const setSearchData = (data) => {
    setUsers(data);
    if (data?.length === 0) {
      setIsUserEmpty(true);
    } else {
      setIsUserEmpty(false);
    }
  };

  function searchPhoneNum(nums) {
    return nums.filter(
      (num) =>
        num.billNum.includes(customerNum) ||
        num.phoneNum_receive.includes(customerNum)
    );
  }

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  const handleChangePageSize = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchData = () => {
    setIsLoading(true);
    getOrderReturnWarehouse(searchDataObj).then((res) => {
      setSearchData(res);
      setIsLoading(false);
    });
  };

  const paginatedUsers = paginate(users, currentPage, pageSize);

  return (
    <>
      <div className="container">
        <div className="title-customer-care">
          <h1 className="mt-4">ออเดอร์คืนคลัง</h1>
        </div>
        <Search
          handleSearchData={handleSearchData}
          setSearchDataObj={setSearchDataObj}
          setIsLoading={setIsLoading}
          backlog={true}
          orderPhoneNumber={true}
        />

        <LoadingSpinner isLoading={isLoading} />
        {users?.length !== 0 && !isUserEmpty && !isLoading && permission?.length !== 0 && (
          <div className="contract-customer">
            <Table users={paginatedUsers} fieldTable={true} dataFrom={returnWareHouseStatus}/>
            <Pagination
              itemsCount={users?.length}
              pageSize={pageSize}
              onPageSizeChange={handleChangePageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        {users?.length === 0 && isUserEmpty && !isLoading && (
          <>
            <img
              src={
                process.env.PUBLIC_URL + "/assets/pictures/data-not-found.png"
              }
              className="rounded mx-auto d-block"
              id="noOrderImg"
              alt="..."
            ></img>
            <h2 className="text-not-found">ไม่พบข้อมูล</h2>
          </>
        )}
        <PermissionModal
          permission={permission}
          backPage={"/free-delivery"}
        />
      </div>
    </>
  );
};

export default ReturnWareHouse;
