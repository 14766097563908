import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "../../../../scss/userMaintenance/_table.scss";
import { Link } from "react-router-dom";
import MenuContext from "../../../../context/MenuContext";

export default function Table(props) {
  const { users = [] } = props;
  const { permission } = useContext(MenuContext);

  return (
    <>
      <div className="table-responsive user-management-table">
        <table className="user-management-thead">
          <thead>
            <tr>
              <th className="first-col-th">
                <span>Status</span>
              </th>
              <th>
                <span>Username</span>
              </th>
              <th>
                <span>Role</span>
              </th>
              <th className="last-col-th">
                <span>Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => {
              return (
                <tr key={index} className={index % 2 != 0 ? "col-xl-2 striped" : "col-xl-2"}>
                  <td
                    className={
                      user.active == "Y" ? "active-green" : "inactive-red"
                    }
                  >
                    {user.active == "Y" ? "Active" : "Inactive"}
                  </td>
                  <td className="col-xl-3">{user.username}</td>
                  <td className="col-xl-3">
                    <div>
                      {user.role_id?.map((roleLists, index) => {
                        return <span key={index}>{roleLists?.role_name}</span>;
                      })}
                    </div>
                  </td>
                  <td className="col-xl-4 last-col-edit">
                    {
                      permission.some((res) => res === "update") ?
                        (
                          <Link to={`/user-maintenance/user-management/edit-user/${user.username}`}>
                            <span className="edit-icon">
                              <FontAwesomeIcon icon={faEdit} />
                            </span>
                          </Link>
                        )
                        : (
                          "-"
                        )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
