import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getStatus } from "./../../../../helper/freedeliveryHelper";

const Table = (props) => {
  const { users, fieldTable ,dataFrom} = props;
  const location = useLocation();
  let arrPath = location.pathname.split("/");
  let subPath = `${arrPath[2]}`;

  let history = useHistory();

  function handleClick(val, path) {
    history.push({
      pathname: `/free-delivery/${path}/order/${val.order_no}`,
    });
  }

  const status = (status) => {
    return getStatus(status);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table-primary">
          <thead className="table-header">
            <tr>
              {fieldTable ? <th>รหัสคลัง</th> : null}
              {fieldTable ? <th>ชื่อคลัง</th> : null}
              <th>ใบสั่งซื้อ</th>
              <th>ชื่อผู้สั่ง</th>
              <th>เบอร์โทรผู้สั่ง</th>
              <th>ชื่อผู้รับ</th>
              <th>เบอร์โทรผู้รับ</th>
              <th>เลขที่ Invoice</th>
              <th>สถานะ</th>
              <th>ช่องทางสั่งซื้อ</th>
              <th>จำนวนกล่อง</th>
              <th>รหัสร้านสาขา</th>
              <th>ชื่อร้าน</th>
              <th>สายส่ง</th>
              <th>จำนวนวันที่ของอยู่ที่ร้าน</th>
            </tr>
          </thead>
          <tbody>
            {users.map((val) => {
              
              let result = dataFrom.some(
                (res) => res === val.order_status
              );
              if (result) {
                return (
                  <>
                    <tr
                      key={val.id}
                      className={val.id % 2 === 0 ? "light-blue" : ""}
                      onDoubleClick={() => handleClick(val, subPath)}
                    >
                      {fieldTable ? <td>{val.warehouse_id}</td> : null}
                      {fieldTable ? <td>{val.warehouse_name}</td> : null}
                      <td>{val.order_no}</td>
                      <td>{val.order_customer_name}</td>
                      <td>{val.order_telephone_no}</td>
                      <td>{val.reciever_customer_name}</td>
                      <td>{val.reciever_telephone_no}</td>
                      <td>{val.invoice_no}</td>
                      <td>{status(val.order_status)}</td>
                      <td>{val.ordersource}</td>
                      <td>{val.box_qty}</td>
                      <td>{val.store_id}</td>
                      <td>{val.store_name}</td>
                      <td>{val.route_id}</td>
                      <td>{val.date_at_branch}</td>
                    </tr>
                  </>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
