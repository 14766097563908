const getMenuIdByURL = (url) => {
  if (url.includes("/free-delivery/contact-customer")) {
    return "31";
  }
  if (url.includes("/free-delivery/whouse-return")) {
    return "32";
  }
  if (url.includes("/free-delivery/outside-delivery-area")) {
    return "33";
  }
  if (url.includes("/free-delivery")) {
    return "22";
  }
  if (url.includes("/ecn/pre-request")) {
    return "43";
  }
  if (url.includes("/ecn/คำขอคืนสินค้า")) {
    return "44";
  }
  if (url.includes("/ecn/add-ecreditnote")) {
    return "44";
  }
  if (url.includes("/ecn/detail")) {
    return "44";
  }
  if (url.includes("/ecn")) {
    return "42";
  }
  if (url.includes("/etax")) {
    return "21";
  }
  if (url.includes("/user-maintenance/role-management")) {
    return "52";
  }
  if (url.includes("/user-maintenance/role-manage-user")) {
    return "52";
  }
  if (url.includes("/user-maintenance/role-add-role")) {
    return "52";
  }
  if (url.includes("/user-maintenance/role-edit-role")) {
    return "52";
  }
  if (url.includes("/user-maintenance/role-add-user")) {
    return "52";
  }
  if (url.includes("/user-maintenance/user-management")) {
    return "53";
  }
  if (url.includes("/user-maintenance")) {
    return "51";
  }
  if (url.includes("/grrs/contact-center/สร้างคำขอแทนลูกค้า")){
    return "56";
  }
  if (url.includes("/grrs/contact-center")){
    return "55";
  }
  if (url.includes("/grrs/customer-service/พิจารณายกเลิกการสั่งซื้อ")){
    return "58";
  }
  if (url.includes("/grrs/customer-service/พิจารณาเปลี่ยนสินค้า")){
    return "59";
  }
  if (url.includes("/grrs/customer-service/พิจารณาคืนสินค้า/คืนเงิน")){
    return "60";
  }
  if (url.includes("/grrs/customer-service")){
    return "57";
  }
  if (url.includes("/grrs/management-team/Approve-เปลี่ยน-คืนสินค้า-คืนเงิน")){
    return "62";
  }
  if (url.includes("/grrs/management-team")){
    return "61";
  }
  if (url.includes("/grrs/warehouse/รับสินค้าและ-approve-CN")){
    return "64";
  }
  if (url.includes("/grrs/warehouse")){
    return "63";
  }
  if (url.includes("/grrs/accounting-return/create-file")){
    return "66";
  }
  if (url.includes("/grrs/accounting-return/download-file/โอนคืนเงินสด")){
    return "68";
  }
  if (url.includes("/grrs/accounting-return/download-file/โอนคืนเงินออนไลน์")){
    return "69";
  }
  if (url.includes("/grrs/accounting-return/download-file/All-Point-M-stamp-ชดเชย")){
    return "70";
  }
  if (url.includes("/grrs/accounting-return/download-file")){
    return "67";
  }
  if (url.includes("/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด")){
    return "72";
  }
  if (url.includes("/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินออนไลน์")){
    return "73";
  }
  if (url.includes("/grrs/accounting-return/ยืนยันผลการคืนเงิน")){
    return "71";
  }
  if (url.includes("/grrs/accounting-return")){
    return "65";
  }
  if (url.includes("/grrs/return/ทำรายการเช็คคืนสินค้า")){
    return "75";
  }
  if (url.includes("/grrs/return")){
    return "74";
  }
  if (url.includes("/grrs")){
    return "54";
  }

  return "0";
};

const getSubMenuIdByURL = (url) => {
  if (url.includes("/free-delivery")) {
    return "22";
  }
  if (url.includes("/ecn")) {
    return "42";
  }
  if (url.includes("/etax")) {
    return "21";
  }
  if (url.includes("/user-maintenance")) {
    return "51";
  }
  if (url.includes("/grrs")) {
    return "54";
  }

  return "0";
};

const numberWithCommas = (x) => {
  if (x == undefined) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const handleEmptyStringValue = (value) => {
  if (value === "") {
    return null;
  } else {
    return value;
  }
}

export { getMenuIdByURL, getSubMenuIdByURL, numberWithCommas, handleEmptyStringValue };
