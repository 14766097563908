import React from "react";
import { Link } from "react-router-dom";
import "../../../../scss/etax/_taxTable.scss";
import moment from "moment";

export const TaxTable = ({ users, checkRequestEtax, permission }) => {

  const checkEtaxWording = (userAction, etaxAction, etaxStatus) => {

    if (userAction === "FULL_NOT_REQUESTABLE" || userAction === "CORR_NOT_CORRECTION") {
      return (
        <>
          <td></td>
        </>
      )
    } else {
      switch (userAction) {
        case "FULL_REQUESTABLE": {
          if ((etaxAction === "REQUESTABLE" || etaxAction === undefined) && etaxStatus === "CREATED") {
            return (
              <>
                <td>
                  {
                    permission.some((res) => res === "create") ? (
                      <Link to={`/etax/detail?external_orderno=${users?.external_orderno}`}>สร้างใบกำกับภาษีเต็มรูป</Link>
                    ) : <span>สร้างใบกำกับภาษีเต็มรูป</span>
                  }
                </td>
              </>
            )
          } else if (etaxAction === "NOT_REQUESTABLE" || etaxStatus === "PENDING" || etaxStatus === "NOT_AVAILABLE" || etaxStatus === "WAITING_FOR_PAYMENT") {
            return (
              <>
              </>
            )
          }
        }
          break;
        case "CORRECTION": {
          if ((etaxAction === "REQUESTABLE" || etaxAction === undefined) && etaxStatus === "CREATED") {
            return (
              <>
                <td>
                  {
                    permission.some((res) => res === "update") ? (
                      <Link to={`/etax/detail?external_orderno=${users?.external_orderno}`}>แก้ไขชื่อ/ที่อยู่</Link>
                    ) : <span>แก้ไขชื่อ/ที่อยู่</span>
                  }
                </td>
              </>
            )
          } else if (etaxAction === "NOT_REQUESTABLE" || etaxStatus === "PENDING" || etaxStatus === "NOT_AVAILABLE" || etaxStatus === "WAITING_FOR_PAYMENT") {
            return (
              <>
              </>
            )
          }
        }
          break;
      }
    }
  }

  return (
    <>
      <div className="table-responsive etax-table">
        <table className="etax-table">
          <thead>
            <tr className="etax-table-title">
              <th>เลขที่คำสั่งซื้อ</th>
              <th>วันที่สั่งซื้อสินค้า</th>
              <th>เลขที่ใบกำกับภาษี</th>
              <th>วันที่ออกใบกำกับภาษี</th>
              <th>ประเภทเอกสาร</th>
              <th>สร้าง/แก้ไข ใบกำกับภาษีเต็มรูป</th>
            </tr>
          </thead>
          <tbody>
            <tr className="etax-table-white">
              <td>
                {users?.external_orderno
                  ? users?.external_orderno
                  : users?.external_orderno == null ? "" : "-"}
              </td>
              <td>
                {users.length != 0 && (users?.order_date
                  ? moment(users?.order_date).add(543, 'year').format("DD/MM/YYYY")
                  : users?.order_date == null ? ("-") : ("")
                )}
              </td>
              <td>
                {users.length != 0 && (users?.url_credit_note_list?.length != 0 && checkRequestEtax?.response_data?.action == "REQUESTABLE" ?
                  (<a href={users?.url_tax_invoice}>{users?.document_number}</a>)
                  : users?.url_credit_note_list?.length != 0 && checkRequestEtax?.response_data?.action == "NOT_REQUESTABLE" ?
                    (<a href={users?.url_credit_note_list[users?.url_credit_note_list?.length - 1].url_credit_note}>{users?.url_credit_note_list[users?.url_credit_note_list?.length - 1].document_number}</a>)
                    : users?.url_tax_invoice != null ?
                      (<a href={users?.url_tax_invoice}>{users?.document_number}</a>)
                      : users?.url_tax_invoice == null ? ("-") : ("")
                )}
              </td>
              <td>
                {users.length != 0 && ((users?.document_type == 1 || users?.document_type == 2 || users?.document_type == 3) && users?.url_credit_note_list?.length != 0 && checkRequestEtax?.response_data?.action == "REQUESTABLE" ?
                  moment(users?.payment_date).add(543, 'year').format("DD/MM/YYYY")
                  : users?.document_type == 5 && users?.url_credit_note_list?.length != 0 && checkRequestEtax?.response_data?.action == "REQUESTABLE" ?
                    moment(users?.created_at).add(543, 'year').format("DD/MM/YYYY")
                    : users?.url_credit_note_list?.length != 0 && checkRequestEtax?.response_data?.action == "NOT_REQUESTABLE" ?
                      moment(users?.url_credit_note_list[users?.url_credit_note_list?.length - 1].created_at).add(543, 'year').format("DD/MM/YYYY")
                      : (users?.action == "FULL_REQUESTABLE" || users?.action == "CORRECTION") && (users?.document_type == 1 || users?.document_type == 2 || users?.document_type == 3) ?
                        moment(users?.payment_date).add(543, 'year').format("DD/MM/YYYY")
                        : users.action == "CORRECTION" && users?.document_type == 5 ?
                          moment(users?.created_at).add(543, 'year').format("DD/MM/YYYY")
                          : users?.payment_date == null ? ("-") : ("")
                )}
              </td>
              <td>
                {users?.document_type == 1
                  ? "ใบเสร็จรับเงิน"
                  : users?.document_type == 2 && checkRequestEtax?.response_data?.action == "NOT_REQUESTABLE"
                    ? "ใบลดหนี้"
                    : users?.document_type == 2
                      ? "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ"
                      : users?.document_type == 3 && checkRequestEtax?.response_data?.action == "NOT_REQUESTABLE"
                        ? "ใบลดหนี้"
                        : users?.document_type == 3
                          ? "ใบเสร็จรับเงิน/ใบกำกับภาษี"
                          : users?.document_type == 5 && checkRequestEtax?.response_data?.action == "NOT_REQUESTABLE"
                            ? "ใบลดหนี้"
                            : users?.document_type == 5
                              ? "ใบเสร็จรับเงิน/ใบกำกับภาษี"
                              : users?.document_type == null
                                ? ("")
                                : ("-")
                }
              </td>
              {
                checkEtaxWording(users?.action, checkRequestEtax?.response_data?.action, users?.status)
              }
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
