import React from 'react'

const TableDetail = (props) => {
    const { orderDetailData } = props;

    return (
        <>
            <div className="card-title" id="customerinfo-card-title">
                <div className="card-body" id="customerinfo-card-body">
                    รายละเอียดออเดอร์
                </div>
            </div>
            <div className="row-1">
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">เลขที่ใบสั่งซื้อ</label>
                    <input className="form-control" value={orderDetailData.order_no} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">Total Amount</label>
                    <input className="form-control" value={orderDetailData.total_amount} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
            </div>
            <div className="row-1">
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">ช่องทางการสั่งซื้อ</label>
                    <input className="form-control" value={orderDetailData.ordersource} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">จำนวนกล่อง</label>
                    <input className="form-control" value={orderDetailData.box_qty} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
            </div>
            <div className="row-1">
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">รหัสสาขาที่ส่ง</label>
                    <input className="form-control" value={orderDetailData.store_id} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">ชื่อสาขาที่ส่ง</label>
                    <input className="form-control" value={orderDetailData.store_name} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
            </div>
            <div className="row-1 store-phone">
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">เบอร์โทรร้าน</label>
                    <input className="form-control" value={orderDetailData.store_telephone_no} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5 '></div>
            </div>
        </>
    );
};

export default TableDetail;
