import React from 'react';

const TableDataPreview = (props) => {
    const { users } = props

    return (
        <>
            <div className="table-preview-data">
                <table className="table-primary show-data">
                    <thead>
                        <tr>
                            <th>ใบสั่งซื้อ</th>
                            <th>ชื่อผู้สั่ง</th>
                            <th>เบอร์โทรผู้สั่ง</th>
                            <th>ชื่อผู้รับ</th>
                            <th>เบอร์โทรผู้รับ</th>
                            <th>ช่องทางสั่งซื้อ</th>
                            <th>สถานะ</th>

                            <th>ใบสั่งซื้อ</th>
                            <th>ชื่อผู้สั่ง</th>
                            <th>เบอร์โทรผู้สั่ง</th>
                            <th>ชื่อผู้รับ</th>
                            <th>เบอร์โทรผู้รับ</th>
                            <th>ช่องทางสั่งซื้อ</th>
                            <th>สถานะ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(val => {
                            return (
                                <>
                                    <tr key={val.id} className={val.id % 2 === 0 ? "light-blue" : ""}>
                                        <td>{val.billNum}</td>
                                        <td>{val.customerName}</td>
                                        <td>{val.phoneNum_customer}</td>
                                        <td>{val.receiveName}</td>
                                        <td>{val.phoneNum_receive}</td>
                                        <td>{val.channelOrder}</td>
                                        <td>{val.statusOrder}</td>

                                        <td>{val.billNum}</td>
                                        <td>{val.customerName}</td>
                                        <td>{val.phoneNum_customer}</td>
                                        <td>{val.receiveName}</td>
                                        <td>{val.phoneNum_receive}</td>
                                        <td>{val.channelOrder}</td>
                                        <td>{val.statusOrder}</td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default TableDataPreview;