import RoleButton from "./RoleButton";
import RoleManageUserTable from "./RoleManageUserTable";
import SearchRoleList from "./SearchRoleList";
import { Link } from "react-router-dom";
import RoleHeader from "./RoleHeader";
import { useEffect, useState } from "react";
import {
  getUserByRoleId,
  getUserByRoleIdAndUsername,
} from "../../../../service/userMaintenance/roleManagement.service";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useLocation } from "react-router-dom";
import Pagination from "../../../common/components/Pagination";
import { paginate } from "../../../../utils/paginate";
import NotFound from "./NotFound";
import RoleList from "./RoleListMain";
import "../../../../scss/userMaintenance/roleManagement/_roleManageUser.scss";

function RoleManageUser() {
  const [isLoad, setIsLoad] = useState(true);
  const [userList, setUserList] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  const roleId = query.get("roleId");
  const roleName = query.get("roleName");
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const paginatedData = paginate(userList, currentPage, pageSize);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  const handleChangePageSize = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const fetchDataByUsername = async (username) => {
    setIsLoad(true);
    const roleFromAPI = await getUserByRoleIdAndUsername(roleId, username);
    const userData = roleFromAPI.data.response_data;
    if (userData.length) setUserList(userData);
    else setUserList([]);
    setIsLoad(false);
  };

  useEffect(() => {
    setIsLoad(true);
    const fetchData = async () => {
      const userFromAPI = await getUserByRoleId(roleId);
      const userData = userFromAPI.data.response_data;
      setUserList(userData);
      setIsLoad(false);
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="RoleManageUser">
        <RoleHeader text={`Role : ${roleName}`} isRoleUser={true} />
        <SearchRoleList
          label="Username"
          searchFunction={fetchDataByUsername}
          maxLength={50}
        />
        <Link
          to={`/user-maintenance/role-add-user?roleId=${roleId}&roleName=${roleName}`}
        >
          <RoleButton text="Add User" />
        </Link>
        {!isLoad && userList.length > 0 && (
          <div>
            <RoleManageUserTable userList={paginatedData} />
            <Pagination
              itemsCount={userList?.length}
              pageSize={pageSize}
              onPageSizeChange={handleChangePageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
        {!isLoad && userList.length == 0 && <NotFound />}

        <LoadingSpinner isLoading={isLoad} />
      </div>
    </div>
  );
}
export default RoleManageUser;
