import { Link } from "react-router-dom";

const EcreditNoteTable = (props) => {
    const data = props.data;

    return (
        <>
            <div className="table-responsive ecn-request-table col-12 col-lg-12">
                <table className="table-primary">
                    <thead>
                        <tr>
                            <th>วันที่นำข้อมูลเข้า</th>
                            <th>เลขที่คำขอ</th>
                            <th>เลขที่คำสั่งซื้อ</th>
                            <th>เลขที่ใบกำกับภาษี</th>
                            <th>เลขที่ใบลดหนี้</th>
                            <th>วันที่ออกใบลดหนี้</th>
                            <th>ยอดเงินคืน(บาท)</th>
                            <th>ยอดคืน AMB Point (บาท)</th>
                            <th>สถานะ</th>
                            <th>รายละเอียด</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((val, index) => {
                                return (
                                    <>
                                        <tr key={index} className={index % 2 !== 0 ? "light-blue" : "bg-white"}>
                                            <td>{val.added_date !== null ? val.added_date : "-"}</td>
                                            <td>{val.request_id !== null ? val.request_id : "-"}</td>
                                            <td>{val.order_no !== null ? val.order_no : "-"}</td>
                                            <td>{val.ref_etax_no !== null ? val.ref_etax_no : "-"}</td>
                                            <td>{val.ecn_no !== null ? val.ecn_no : "-"}</td>
                                            <td>{val.ecn_doc_date !== null ? val.ecn_doc_date : "-"}</td>
                                            <td>{val.ecn_refund_amount !== null ? val.ecn_refund_amount : "-"}</td>
                                            <td>{val.ecn_refund_amb_point !== null ? val.ecn_refund_amb_point : "-"}</td>
                                            <td>{val.status !== null ? val.status : "-"}</td>
                                            <td>
                                                <Link to={{ pathname: `/ecn/detail/${val.ecn_id}` }} >detail</Link>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default EcreditNoteTable;