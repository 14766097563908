import React, { useState, useContext, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "antd";
import moment from "moment";
import MenuContext from "../../../../context/MenuContext";
import { useHistory, useLocation } from "react-router-dom";
import { addOrderOperation } from "./../../../../service/freedelivery/freedelivery.service";
import pathModule from "./../../../../utils/path/pathModule.json";
import { Col, Row } from "react-bootstrap";
import StatusList from "./Dropdown/StatusList";
import CalendarWithValidate from "./CalendarWithValidate";
import CancalModal from "./Modal/CancalModal";
import SaveModal from "./Modal/SaveModal";

function CustomerServiceDetail({ invoiceDetailData: invoiceData = {} }) {
  let history = useHistory();

  const { profileUser, backSearch } = useContext(MenuContext);

  const contractCustomerPath = pathModule.free_delivery.contract_customer.path;
  const returnWarehousePath = pathModule.free_delivery.return_warehouse.path;
  const outOfDeliveryPath = pathModule.free_delivery.out_of_delivery.path;

  let location = useLocation();
  let arrPath = location.pathname.split("/");
  let order_no = `${arrPath[4]}`;
  let API_Path = `/${arrPath[1]}/${arrPath[2]}`;

  const [display, setDisplay] = useState(true);
  const [refundConnect, setRefundConnect] = useState(false);
  const [displayTimeAppoint, setDisplayTimeAppoint] = useState(false);
  const [validate, setValidate] = useState(false);
  const [dateContact, setDateContact] = useState(new Date());
  const [timeContact, setTimeContact] = useState(moment());
  const [statusContact, setStatusContact] = useState(
    invoiceData.contact_customer_status == "ติดต่อได้" ? "Y" : "N"
  );
  const [dateAppoint, setDateAppoint] = useState(null);
  const [refund, setRefund] = useState("N");
  const [timePeriod, setTimePeriod] = useState("Y");
  const [timeAppoint, setTimeAppoint] = useState("08:00-18:00");
  const [detail, setDetail] = useState("");
  const [statusTracking, setStatusTracking] = useState("");
  const [hideRadio, setHideRadio] = useState(true);
  const [checkCheckBox, setCheckCheckBox] = useState(false);
  const [statusListDisplay, setStatusListDisplay] = useState(false);
  const [timeStatus, setTimeStatus] = useState(true);
  const [dateCheck, setDateCheck] = useState(false);
  const format = "HH:mm";
  const today = new Date();

  const date =
    today.getFullYear() +
    543 +
    "/" +
    (today.getMonth() + 1) +
    "/" +
    today.getDate();

  const onChange = (time) => {
    if (time == null) {
      setTimeStatus(false);
    } else {
      setTimeStatus(true);
    }
    setTimeContact(time);
  };

  function toggleButton() {
    if (display) setDisplay(false);
    else setDisplay(true);
  }

  function contactCustomer() {
    setStatusContact("Y");
    setDateAppoint("");
    setCheckCheckBox(false);
    setDetail("");
    setStatusTracking("");
    setRefundConnect(false);
    allTime();
  }
  function discontactCustomer() {
    setStatusContact("N");
    setStatusTracking("");
  }

  function refundCheck() {
    if (!refundConnect) {
      setDateAppoint("");
      setRefundConnect(true);
      setRefund("Y");
      setTimePeriod("");
      setTimeAppoint("");
      setCheckCheckBox(true);

      setDisplayTimeAppoint(false);
      setStatusListDisplay(false);
    } else {
      setRefundConnect(false);
      setRefund("N");
      setTimePeriod("Y");
      setStatusTracking("3");
      setCheckCheckBox(false);
      setStatusListDisplay(true);
    }
  }

  function someTime() {
    setDisplayTimeAppoint(true);
    setTimePeriod("N");
    setTimeAppoint("08:00-10:00");
  }

  function allTime() {
    setDisplayTimeAppoint(false);
    setTimePeriod("Y");
    setTimeAppoint("08:00-18:00");
  }

  function reset() {
    setRefundConnect(false);
    setDisplayTimeAppoint(false);
    setDateContact(new Date());
    setTimeContact(moment());
    setStatusContact(undefined);
    setDateAppoint("");
    setRefund("");
    setTimePeriod(undefined);
    setDetail("");
    setStatusTracking("");
    setValidate(false);

    backPage(backSearch);
  }

  const selectStatus = (e) => {
    setStatusTracking(e.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
  }

  function hendeleAddOperation() {
    let request_data;
    let customer_date;

    typeof dateContact === typeof ""
      ? (customer_date = dateContact)
      : (customer_date = moment(dateContact)
          .add(543, "year")
          .format("DD/MM/YY"));

    let customer_time = moment(timeContact?._d).format("HH:mm");
    let dataCustomer = {
      order_no: order_no,
      invoice_no: invoiceData.invoice_no,
      order_status: statusTracking,
      operator_name: profileUser.name,
      contact_customer: {
        contact_customer_status: statusContact,
        contact_customer_date: customer_date,
        contact_customer_time: customer_time,
      },
      customer_acceptance: {
        customer_acceptance_date: dateAppoint,
        customer_request_refund: refund,
        customer_acceptance_status:
          statusContact === "N" || timePeriod === "" ? null : timePeriod,
        customer_acceptance_time:
          statusContact === "N" || timeAppoint === "" ? null : timeAppoint,
        customer_contacted_detail: detail,
      },
    };

    let dataWhouseAndOutside = {
      order_no: order_no,
      invoice_no: invoiceData.invoice_no,
      order_status: statusTracking,
      operator_name: profileUser.name,
      contact_customer: {
        contact_customer_date: customer_date,
        contact_customer_time: customer_time,
      },
    };

    if (API_Path == contractCustomerPath) {
      request_data = dataCustomer;
    } else {
      request_data = dataWhouseAndOutside;
    }

    addOrderOperation(API_Path, request_data);
    backPage(backSearch);
    reset();
  }
  const backPage = (path) => {
    history.push(path);
  };

  useEffect(() => {
    switch (API_Path) {
      case contractCustomerPath:
        setHideRadio(true);
        break;
      case returnWarehousePath:
        setHideRadio(false);
        break;
      case outOfDeliveryPath:
        setHideRadio(false);
        break;
      default:
        setHideRadio(false);
        break;
    }
  }, [API_Path]);

  useEffect(() => {
    if (
      statusContact === "Y" &&
      refund === "N" &&
      (dateAppoint === "" || dateAppoint === undefined)
    ) {
      setStatusTracking("3");
      setStatusListDisplay(true);
    } else if (statusContact === "Y" && dateAppoint !== "") {
      setStatusTracking("5");
      setStatusListDisplay(true);
    } else {
      setStatusTracking("");
      setStatusListDisplay(false);
    }
  }, [statusContact, dateAppoint]);

  const checkBtnConfirm = (
    statusTracking,
    timePeriod,
    timeAppoint,
    refund,
    dateContact,
    timeContact,
    dateAppoint
  ) => {
    let statusBtn = true;
    if (dateContact === undefined || timeContact === null) {
      statusBtn = true;
    } else if (statusContact === "Y") {
      if (refund === "Y" && dateAppoint === "" && statusTracking !== "") {
        statusBtn = false;
      } else if (statusTracking !== "" && timePeriod === "Y") {
        statusBtn = false;
      } else if (statusTracking !== "" && timePeriod === "N") {
        timeAppoint !== "" ? (statusBtn = false) : (statusBtn = true);
      }
      if (dateAppoint == "" && refund === "N") {
        statusBtn = true;
      } else if(dateCheck&&refund === "N"){
        statusBtn = true;
        }
    } else if (statusContact === "N") {
      statusTracking !== "" ? (statusBtn = false) : (statusBtn = true);
    }

    return statusBtn;
  };

  return (
    <>
      <div className="container-md">
        <div className="background-cs">
          <div className="customer-service-title">
            <button className={display ? "rotate" : ""} onClick={toggleButton}>
              <span className="span-rotate"> &#707; </span>
            </button>
            <h2>Customer Service ดำเนินการ</h2>
            <br />
          </div>
          <form onSubmit={handleSubmit}>
            <div className={display ? "visable" : "disable"}>
              <div className="margin-form">
                <Row>
                  <Col md={5}>
                    <div className="date-input">
                      <label className="date-span">วันที่ติดต่อลูกค้า</label>
                      <CalendarWithValidate
                        setDateCheck={setDateCheck}
                        date={date}
                        dateContact={dateContact}
                        setDateContact={setDateContact}
                        calendarName={"dateContact" + invoiceData?.invoice_no}
                        refundConnect={false}
                      />
                    </div>
                  </Col>
                  <Col md={1} />
                  <Col md={5}>
                    <div className="time-input">
                      <label className="time-span">เวลาที่ติดต่อลูกค้า</label>
                      <TimePicker
                        className={timeStatus ? "" : "error-timepicker"}
                        format={format}
                        placeholder={""}
                        value={timeContact}
                        onChange={onChange}
                        required
                      />
                      {!timeStatus && (
                        <div className="error-customerservice">
                          กรุณากรอกเวลา
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              {hideRadio ? (
                <div className="status-communication">
                  <Row>
                    <Col md={5}>
                      <label>สถานะการติดต่อลูกค้า</label>
                      <div className="displayCheck">
                        <Col md={8}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              onClick={contactCustomer}
                              value={statusContact}
                              checked={statusContact == "Y"}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault1"
                            >
                              ติดต่อได้
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form-check margin">
                            <input
                              className="form-check-input"
                              type="radio"
                              onClick={discontactCustomer}
                              value={statusContact}
                              checked={statusContact == "N"}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                            >
                              ติดต่อไม่ได้
                            </label>
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                </div>
              ) : null}
              <br />
              <div className={statusContact === "Y" ? "" : "disable"}>
                <div className="margin-form">
                  <Row>
                    <div className="status-tracking-connect">
                      <Col md={5} sm={12} xs={12}>
                        <div className="d-block">
                          <label className="status-span">
                            วันที่รับนัดของลูกค้า
                          </label>
                          <CalendarWithValidate
                          setDateCheck={setDateCheck}
                            date={date}
                            dateContact={dateAppoint}
                            setDateContact={setDateAppoint}
                            calendarName={
                              "dateAppoint" + invoiceData?.invoice_no
                            }
                            refundConnect={refundConnect}
                          />
                        </div>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={5} sm={12} xs={12}>
                        <div className="form-check refund">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="refund"
                            value={refund}
                            onClick={refundCheck}
                            checked={checkCheckBox}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            ไม่สะดวกรับของ ขอคืนเงิน
                          </label>
                        </div>
                      </Col>
                    </div>
                  </Row>
                </div>
                <br />
                <div className={refundConnect ? "disable" : "time-appoint"}>
                  <div className="margin-form">
                    <Row>
                      <label>เวลารับนัดของลูกค้า</label>
                      <div className="pick-up-time">
                        <Col md={5}>
                          <Col md={8}>
                            <div className="form-check time-period">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={timePeriod}
                                onChange={allTime}
                                checked={timePeriod === "Y"}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadio1"
                              >
                                ทุกช่วงเวลา (08:00-18:00)
                              </label>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="form-check some-time">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={timePeriod}
                                onChange={someTime}
                                checked={timePeriod == "N"}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadio2"
                              >
                                บางเวลา
                              </label>
                            </div>
                          </Col>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={5}>
                          <div
                            className={
                              displayTimeAppoint ? "time-select" : "disable"
                            }
                          >
                            <label>ช่วงเวลาที่ลูกค้าสะดวกรับนัด</label>
                            <select
                              className="form-select time-appoint"
                              name="timeAppoint"
                              value={timeAppoint}
                              onChange={(e) => setTimeAppoint(e.target.value)}
                            >
                              <option value="08:00-10:00">08:00 - 10:00</option>
                              <option value="10:00-12:00">10:00 - 12:00</option>
                              <option value="12:00-14:00">12:00 - 14:00</option>
                              <option value="14:00-16:00">14:00 - 16:00</option>
                              <option value="16:00-18:00">16:00 - 18:00</option>
                            </select>
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </div>
                </div>
                <div className="detail-input">
                  <label className="status-span">
                    รายละเอียดลูกค้าที่ติดต่อ
                  </label>
                  <textarea
                    type="text"
                    className="form-control customer-form"
                    name="detail"
                    rows={3}
                    onChange={(e) => setDetail(e.target.value)}
                    maxLength="250"
                  />
                </div>
              </div>
              <div className="margin-form">
                <Row>
                  <Col md={5}>
                    <div className="status-tracking" required>
                      <label className="status-span">สถานะ</label>
                      <select
                        className="form-select status"
                        name="statusTracking"
                        value={statusTracking}
                        onChange={selectStatus}
                        disabled={statusListDisplay}
                      >
                        <option value=""></option>
                        <StatusList fromComponent={"form"} />
                      </select>
                    </div>
                  </Col>
                  <Col md={7}></Col>
                </Row>
              </div>
              <div className="button-cancel-save">
                <button
                  className="cancel"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  ยกเลิก
                </button>

                <button
                  className={
                    checkBtnConfirm(
                      statusTracking,
                      timePeriod,
                      timeAppoint,
                      refund,
                      dateContact,
                      timeContact,
                      dateAppoint
                    )
                      ? "disable-save"
                      : "save"
                  }
                  data-bs-toggle="modal"
                  data-bs-target={"#save" + invoiceData.invoice_no}
                  disabled={checkBtnConfirm(
                    statusTracking,
                    timePeriod,
                    timeAppoint,
                    refund,
                    dateContact,
                    timeContact,
                    dateAppoint
                  )}
                >
                  บันทึก
                </button>
              </div>
              <CancalModal reset={reset} />
              <SaveModal
                hendeleAddOperation={hendeleAddOperation}
                invoiceNo={invoiceData.invoice_no}
              />

              <br />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CustomerServiceDetail;
