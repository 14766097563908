import RoleManageUserMain from "../components/roleManagement/RoleManageUserMain";
function RoleManageUser() {
  return (
    <div>
      <RoleManageUserMain />
    </div>
  );
}

export default RoleManageUser;
