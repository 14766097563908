import "../../../../scss/userMaintenance/roleManagement/_roleHeader.scss";
import { useHistory } from "react-router-dom";
function RoleHeader({ text, isRoleUser = false }) {
  let history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const goMain = () => {
    history.push("/user-maintenance/role-management");
  };
  return (
    <div className="roleheader">
      <div className="title-name">
        <button onClick={isRoleUser ? goMain : goBack}>
          <span>&#8592;</span>
        </button>
        <label>{text}</label>
      </div>
    </div>
  );
}

export default RoleHeader;
