import React from 'react';
import '../../../../../../scss/common/utils.scss';

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <div>
            <input className='filter-input' placeholder="ค้นหา" value={filterValue || ''}
                onChange={(e) => setFilter(e.target.value)}
            />
        </div>
    )
}
