import { useState, useEffect, useContext } from "react";
import { getPreRequests1 } from "../../../../service/ecn/pre-request.service";
import MenuContext from "../../../../context/MenuContext";

const SearchPreRequest = (props) => {
  const { permission } = useContext(MenuContext);
  const [displayButton, setDisplayButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = () => {
    props.setIsLoading(true);
    let stringSearch = ''
    if (searchValue.trim().length > 0) {
      stringSearch = searchValue.trim();
      setSearchValue(searchValue.trim())
    }

    getPreRequests1(stringSearch).then((res) => {
      props.setData(res);
      props.setIsLoading(false);
    });
  };
  const handleSearchData = (event) => {
    if (event.target.value == '') {
      setDisplayButton(false)
    } else {
      setDisplayButton(true)
    }
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    if (document.getElementById("search_input").length > 0) {
      setDisplayButton(true)
    }
  }, [])

  return (
    <>
      <div className="prerequest-search-container">
        <div className="row align-items-center">
          <label className="col-12 col-sm-12 col-md-1 col-lg-1 col-xxl-1 prerequest-search-lebel mt-3">ค้นหา:</label>
          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xxl-4 mt-3">
            <input
              type="text"
              className="prerequest-search form-control"
              placeholder="เลขที่คำสั่งซื้อ"
              id="search_input"
              value={searchValue}
              onChange={(e) => handleSearchData(e)}
            />
          </div>
          <div className="col-8 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mt-3 prerequest-search-button-layout">
            <button className="btn btn-primary prerequest-search-button w-100" disabled={!displayButton || !permission.some((res) => res === "search")} onClick={handleSearch}>
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPreRequest;
