import { useState, useContext } from "react";
import "../../../../scss/userMaintenance/roleManagement/_role-search-container.scss";
import MenuContex from "../../../../context/MenuContext.js";

const SearchRoleList = ({ label, searchFunction, maxLength = 50 }) => {
  const [inputText, setInputText] = useState("");
  const { permission } = useContext(MenuContex);
  return (
    <div className="role-search-container mt-3">
      <div className="row align-items-center">
        <label className="col-12 col-sm-12 col-md-2 col-lg-2 role-search-lebel mt-3">
          {label} :
        </label>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3">
          <input
            type="text"
            className="role-search form-control"
            onChange={(e) => setInputText(e.target.value)}
            maxLength={maxLength}
            disabled={!permission.some((res) => res === "search")}
          />
        </div>
        <div className="col-8 col-sm-6 col-md-4 col-lg-2 mt-3 role-search-button-layout">
          <button
            onClick={() => searchFunction(inputText)}
            className="btn btn-primary role-search-button w-100"
            disabled={
              (permission.some((res) => res === "search") ? false : true) ||
              inputText.length == 0
            }
          >
            ค้นหา
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchRoleList;
