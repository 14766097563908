import React  from 'react'


const LoadingSpinner = (props) => {
    const isLoading = props.isLoading;

    return (
        <>
          {isLoading && (
                    <>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" id="spinner" role="status">
                                {/* <span className="sr-only"></span> */}
                            </div>
                        </div>
                    </>
                )
                }
        </>
    )
};

export default LoadingSpinner;