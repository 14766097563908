import React, { useEffect, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";
import CustomerInfo from "../components/common/CustomerInfo";
import InvoiceDetail from "../components/common/InvoiceDetail";
import ContactHistory from "../components/common/ContactHistory";
import MenuContext from "../../../context/MenuContext";
import TableDetail from "../components/common/TableDetail";
import {
  getMenuByMenuId,
  getUserPermission,
} from "../../../service/customer-care.service";
import { getOrderAndHistory } from "../../../service/freedelivery/freedelivery.service";

function OrderDetail(props) {
  let location = useLocation();
  let arrPath = location.pathname.split("/");
  let API_Path = `/${arrPath[1]}/${arrPath[2]}`;

  const {
    menu,
    setMenu,
    userRole,
    setUserRole,
    backSearch,
    profileUser,
    permission,
  } = useContext(MenuContext);
  const orderNo = props.match.params.orderNo ? props.match.params.orderNo : "";

  const [customerInfoData, setCustomerInfoData] = useState({});
  const [invoiceDetailData, setInvoiceDetailData] = useState([]);
  const [orderDetailData, setorderDetailData] = useState({});
  const [contactHistoryData, setcontactHistoryData] = useState([]);

  // const { backPage } = useContext(MenuContext);
  let history = useHistory();
  const backMainPage = (backPath) => {
    history.push(backPath);
  };

  useEffect(() => {
    if (menu.length === 0 || menu.menu_id !== "22") {
      if (profileUser) {
        getMenuByMenuId("22", profileUser.email).then((res) => {
          setMenu(res?.menu_list[0]);
          setUserRole(res?.role[0]);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (orderNo) {
      getOrderAndHistory(API_Path, orderNo).then((res) => {
        const orderInfo = {
          order_no: res?.orderInfo?.response_data?.order_no,
          order_date: res?.orderInfo?.response_data?.order_date,
          order_status: res?.orderInfo?.response_data?.order_status,
          order_customer_name:
            res?.orderInfo?.response_data?.order_customer_name,
          order_telephone_no: res?.orderInfo?.response_data?.order_telephone_no,
          reciever_customer_name:
            res?.orderInfo?.response_data?.reciever_customer_name,
          reciever_telephone_no:
            res?.orderInfo?.response_data?.reciever_telephone_no,
          reciever_address: res?.orderInfo?.response_data?.reciever_address,
          store_id: res?.orderInfo?.response_data?.store_id,
          store_name: res?.orderInfo?.response_data?.store_name,
          store_telephone_no: res?.orderInfo?.response_data?.store_telephone_no,
          box_qty: res?.orderInfo?.response_data?.box_qty,
          total_amount: res?.orderInfo?.response_data?.total_amount,
          ordersource: res?.orderInfo?.response_data?.ordersource,
        };
        setCustomerInfoData(orderInfo);
        setorderDetailData(orderInfo);
        setInvoiceDetailData(res.orderInfo?.response_data?.invoice_data);

        if (res.orderHistory == []) {
          setcontactHistoryData([]);
        } else {
          setcontactHistoryData(res?.orderHistory?.response_data);
        }
      });
    } else {
      console.log("no have orderNo");
    }
  }, []);

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content">
              <Container>
                <div className="container-md">
                  <br />
                  <div className="order-number">
                    <button onClick={() => backMainPage(backSearch)}>
                      <span>&#8592;</span>
                    </button>
                    <h2>ใบสั่งซื้อเลขที่ {orderNo}</h2>
                  </div>
                  <br />
                  <CustomerInfo customerInfoData={customerInfoData} />
                  <br />
                  <TableDetail orderDetailData={orderDetailData} />
                  {invoiceDetailData?.map((invoice) => {
                    return (
                      <>
                        {invoice.order_status !== "O1" &&
                          permission.some((res) => res === "create") ? (
                          <div className="invoice">
                            <InvoiceDetail invoiceDetailData={invoice} />
                            <br />
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                  <ContactHistory contactHistoryData={contactHistoryData} />
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default OrderDetail;
