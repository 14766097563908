import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "./scss/app.scss";
import { DataContextProvider } from "./context/DataContext";
import { MenuContextProvider } from "./context/MenuContext";
import Main from "./modules/freedelivery/pages/Main";
import Menu from "./modules/common/pages/Menu";
import {
  getAccessIdData,
  isAuthenticated,
  getTokenFromStorage,
  logout,
} from "./service/auth.service";
import LoginPortal from "./modules/common/pages/LoginPortal";
import Oauth2Callback from "./modules/common/pages/Oauth2Callback";
import OrderDetail from "./modules/freedelivery/pages/OrderDetail";
import Ecn from "./modules/ecn/pages/Ecn";
import EcnDetail from "./modules/ecn/pages/EcnDetail";
import EtaxMainPage from "./modules/etax/page/EtaxMainPage";
import EtaxFormPage from "./modules/etax/page/EtaxFormPage";
import { getMenuIdByURL } from "./helper/commonHelper";
import { getUserPermission } from "./service/customer-care.service";
import UserMaintenanceMainPage from "./modules/userMaintenance/pages/Main";
import EditFormDetail from "./modules/userMaintenance/pages/EditFormDetail";
import ContactCenterPage from "./modules/grrs/pages/contact-center";
import CustomerService from "./modules/grrs/pages/customer-service";
import Accounting from "./modules/grrs/pages/accounting";
import OrderList from "./modules/grrs/pages/contact-center/order-list";
import OrderDetailPage from "./modules/grrs/pages/contact-center/order-detail";
import AccountingDownloadFile from "./modules/grrs/pages/accounting/download-file";
import AccountingApproveRefund from "./modules/grrs/pages/accounting/approve-refund";
import RequestCancelContactCenter from "./modules/grrs/pages/contact-center/request-cancel";
import RequestCancelViewContactCenter from "./modules/grrs/pages/contact-center/request-cancel-view";
import RequestCancelDetailContactCenter from "./modules/grrs/pages/contact-center/request-cancel-detail";
import RequestCancelCustomerService from "./modules/grrs/pages/customer-service/request-cancel";
import RequestCancelAccountingView from "./modules/grrs/pages/accounting/request-cancel-view";
import RequestCancelAccounting from "./modules/grrs/pages/accounting/request-cancel";
import BlankGRRS from "./modules/grrs/pages/BlankGRRS";

function App() {
  const location = useLocation();
  const [profileUser, setProfileUser] = useState();
  // const { permission, setPermission } = useContext(MenuContext);
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    if (window.location.pathname.includes("logout") === true) {
      logout();
    } else if (isAuthenticated()) {
      const accessIdData = getAccessIdData();
      setProfileUser(accessIdData);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      getTokenFromStorage();
      const accessIdData = getAccessIdData();
      let menuId = getMenuIdByURL(location.pathname);
      if (menuId !== "0" && accessIdData) {
        getUserPermission(menuId, accessIdData.email).then((res) => {
          setPermission(res);
        });
      } else {
        setPermission([]);
      }
    }
  }, [location]);

  return (
    <DataContextProvider>
      <MenuContextProvider profileUser={profileUser} permission={permission}>
        <Switch>
          <Route path="/oauth2callback/:code?" component={Oauth2Callback} />
          <Route path="/login" component={LoginPortal} />
          {isAuthenticated() && [
            <Route path="/ecn/detail/:ecnId?" component={EcnDetail} />,
            <Route path="/ecn:queryString?" component={Ecn} />,
            <Route path="/etax" exact component={EtaxMainPage} />,
            <Route path="/etax/detail:queryString?" component={EtaxFormPage} />,
            <Route
              path="/user-maintenance/user-management/edit-user/:username"
              component={EditFormDetail}
            />,
            <Route
              path="/user-maintenance"
              component={UserMaintenanceMainPage}
            />,
            <Route
              path="/free-delivery/:subMenu/order/:orderNo?"
              component={OrderDetail}
            />,
            <Route path="/free-delivery:queryString?" component={Main} />,
            <Route path="/" exact component={Menu} />,

            <Route path="/grrs" exact component={BlankGRRS} />,
            <Route
              path="/grrs/contact-center/สร้างคำขอแทนลูกค้า/cancellation-view/:orderNo&:ordersource&:customerRequestNo"
              component={RequestCancelViewContactCenter}
            />,
            <Route
              path="/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail/cancellation-detail"
              component={RequestCancelDetailContactCenter}
            />,
            <Route
              path="/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail/initial"
              component={RequestCancelContactCenter}
            />,
            <Route
              path="/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list"
              component={OrderList}
            />,
            <Route
              path="/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail"
              component={OrderDetailPage}
            />,
            <Route
              path="/grrs/contact-center/สร้างคำขอแทนลูกค้า"
              exact
              component={ContactCenterPage}
            />,
            <Route
              path="/grrs/customer-service/พิจารณายกเลิกการสั่งซื้อ/customer-service-request-cancel/:orderNo&:ordersource&:customerRequestNo"
              component={RequestCancelCustomerService}
            />,
            <Route
              path="/grrs/customer-service/พิจารณายกเลิกการสั่งซื้อ/"
              component={CustomerService}
            />,

            <Route
              path="/grrs/accounting-return/create-file"
              exact
              component={Accounting}
            />,
            <Route
              path="/grrs/accounting-return/download-file/โอนคืนเงินสด"
              component={AccountingDownloadFile}
            />,
            <Route
              path="/grrs/accounting-return/download-file/โอนคืนเงินออนไลน์"
              component={AccountingDownloadFile}
            />,
            <Route
              path="/grrs/accounting-return/download-file/All-Point-M-stamp-ชดเชย"
              component={AccountingDownloadFile}
            />,
            <Route
              path="/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด"
              exact
              component={AccountingApproveRefund}
            />,
            <Route
              path="/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินออนไลน์"
              exact
              component={AccountingApproveRefund}
            />,
            <Route
              path="/grrs/accounting-return/create-file/request-cancel-view/:orderNo&:ordersource&:customerRequestNo"
              component={RequestCancelAccountingView}
            />,
            <Route
              path="/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด/request-cancel/:orderNo&:ordersource&:customerRequestNo"
              component={RequestCancelAccounting}
            />,
            <Route
              path="/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินออนไลน์/request-cancel/:orderNo&:ordersource&:customerRequestNo"
              component={RequestCancelAccounting}
            />,
            <Route path="/grrs" component={BlankGRRS} />,
          ]}

          {!isAuthenticated() && (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )}
        </Switch>
      </MenuContextProvider>
    </DataContextProvider>
  );
}

export default App;
