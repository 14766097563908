import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Search from '../components/UserManagement/Search';
import Table from '../components/UserManagement/Table';
import '../../../scss/userMaintenance/_userManagement.scss';
import LoadingSpinner from "../../common/components/LoadingSpinner";
import { paginate } from "../../../utils/paginate";
import Pagination from "../../common/components/Pagination";
import { getAllUsers, getUsers, getRole_id } from '../../../service/userMaintenance/userManagement.service';
import MenuContext from "../../../context/MenuContext";
import PermissionModal from '../../common/components/PermissionModal';

export default function UserManagement() {
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [newUsersArr, setNewUsersArr] = useState([]);
    const { permission } = useContext(MenuContext);

    useEffect(() => {
        setIsLoading(true);
        getAll_Users();
        getRoleId();
        setTimeout(()=>{
            setIsLoading(false);
        }, 1000);
    }, []);

    const getAll_Users = async () => {
        try {
            const res = await getAllUsers();
            setUsers(res?.users);
        } catch (err) {
            console.log(err);
        }
    }

    const getRoleId = async () => {
        try {
            const res = await getRole_id();
            setRoleList(res?.roles);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let roleObject = {};
        roleList.forEach((role) => {
            roleObject[role.role_id] = role;
        });

        const data = users.map((user) => {
            return {
                ...user,
                role_id: user.role_id.map((roleId) => {
                    return roleObject[roleId];
                }),
            };
        });
        setNewUsersArr(data);
    }, [users, roleList]);

    const handleSearchUserRole = async (username, role_id) => {
        let request = {
            "username": username,
            "role_id": role_id
        }
        setIsLoading(true);
        let dataUser = await getUsers(request);

        if (dataUser.response_code == 100) {
            let roleObject = {};
            roleList.forEach((role) => {
                roleObject[role.role_id] = role;
            });

            const data = dataUser.response_data.users.map((user) => {
                return {
                    ...user,
                    role_id: user.role_id.map((roleId) => {
                        return roleObject[roleId];
                    }),
                };
            });
            setNewUsersArr(data);
            setIsLoading(false);
        } else {
            setNewUsersArr([]);
            setIsLoading(false);
        }
    }


    function handlePageChange(page) {
        setCurrentPage(page);
    }

    const handleChangePageSize = (e) => {
        setPageSize(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const paginatedData = paginate(newUsersArr, currentPage, pageSize);

    return (
        <>
            <div className="container">
                <div className="usermanagement-body">
                    <div className='col-md-6'>
                        <strong>
                            User Management
                        </strong>
                    </div>
                    <Search handleSearchUserRole={handleSearchUserRole} roleList={roleList} />
                    <br />
                    {
                        permission.some((res) => res === "create") ? (
                            <Link to="/user-maintenance/user-management/add-new-user">
                                <button type="button" className="button-add-new-user">
                                    <img
                                        className="plus-circle"
                                        src="/assets/userMaintenance/plus.svg"
                                        alt="plus"
                                    />
                                    <span>Add New User</span>
                                </button>
                            </Link>
                        ) : (
                            <button type="button" className="button-add-new-user disable-button" disabled>
                                <img
                                    className="plus-circle"
                                    src="/assets/userMaintenance/plus.svg"
                                    alt="plus"
                                />
                                <span>Add New User</span>
                            </button>
                        )}
                    <br />
                    <div>
                        {newUsersArr?.length !== 0 && !isLoading && permission?.length !== 0 && (
                            <div>
                                <Table
                                    users={paginatedData}
                                />
                                <Pagination
                                    itemsCount={newUsersArr?.length}
                                    pageSize={pageSize}
                                    onPageSizeChange={handleChangePageSize}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        )}
                        {newUsersArr?.length === 0 && !isLoading && (
                            <>
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/pictures/data-not-found.png"}
                                    className="rounded mx-auto d-block"
                                    id="noOrderImg"
                                    alt="..."
                                />
                                <h2 className="text-not-found">ไม่พบข้อมูล</h2>
                            </>
                        )}
                        <LoadingSpinner isLoading={isLoading} />
                    </div>
                </div>
                <PermissionModal
                    permission={permission}
                    backPage={"/user-maintenance"}
                />
            </div>
        </>
    )
}
