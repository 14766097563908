import RoleAddUserForm from "./RoleAddUserForm";
import RoleHeader from "./RoleHeader";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { postUserByRole } from "../../../../service/userMaintenance/roleManagement.service";
import NotFound from "./NotFound";
import "../../../../scss/userMaintenance/roleManagement/_roleAddUser.scss";
import MenuContext from "../../../../context/MenuContext";
import { useContext } from "react";
function RoleAddUser() {
  const { profileUser } = useContext(MenuContext);
  let history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const roleId = query.get("roleId");
  const roleName = query.get("roleName");
  const goMain = () => {
    history.push(
      `/user-maintenance/role-manage-user?roleId=${roleId}&roleName=${roleName}`
    );
  };
  const [isUserError, setIsUserError] = useState(false);
  const handleError = (errorCode) => {
    setErrorCode(errorCode);
    setIsUserError(true);
  };

  const postRoleAPI = async () => {
    const body = {
      request_data: {
        role_id: roleId,
        username: username,
        status: status,
        operation_name: profileUser?.email,
      },
    };
    const response = await postUserByRole(body, goMain, handleError);
  };

  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("Active");
  const [isUsernameIsValid, setIsUsernameIsValid] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [errorCode, setErrorCode] = useState(202);
  const handleClose = () => setIsModal(false);
  const handleShow = () => setIsModal(true);

  let pattern = /^[\w-\.]+@([\w-]+\.)+([\w-]{3}|([\w-]{2}\.[\w-]{2}))$/;
  const setUserNameWithValidate = (_username) => {
    setUsername(_username);
    if (!pattern.test(_username)) setIsUsernameIsValid(false);
    else setIsUsernameIsValid(true);
  };

  return (
    <div className="container">
      <div className="roleAddUser">
        <RoleHeader text="Add User" />
        <RoleAddUserForm
          goMain={goMain}
          username={username}
          setUsername={setUsername}
          status={status}
          setStatus={setStatus}
          postRoleAPI={postRoleAPI}
          isUserError={isUserError}
          setIsUserError={setIsUserError}
          setUserNameWithValidate={setUserNameWithValidate}
          isUsernameIsValid={isUsernameIsValid}
          isModal={isModal}
          handleClose={handleClose}
          handleShow={handleShow}
          setIsModal={setIsModal}
          errorCode={errorCode}
          roleName={roleName}
        />
      </div>
    </div>
  );
}

export default RoleAddUser;
