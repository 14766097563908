import React from "react";
import "./FormRequestCancel.scss";
import { useState } from "react";

function TrackStatus(props) {
  const [isDisplay, setIsDisplay] = useState(true);
  const { tracking_timeline } = props.cancellationInitial;
  return (
    <div className="form-request-cancel">
      <div className="header-request-cancel header-request-cancel-view">
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">ติดตามสถานะ</span>
      </div>
      {isDisplay && (
        <div className="track-status">
          <div className="body-request-cancel">
            <div className="outer">
              <div className="row align-items-center">
                <div className="col-12">
                  {tracking_timeline.map((list, index) => (
                    <div
                      className="row align-items-center timeline-gap"
                      key={index}
                    >
                      <div className="col-4 col-md-4 col-xl-3 text-detials">
                        {list.timeline_date}
                      </div>
                      <div className="col-8 col-md-8 col-xl-9">
                        <div className="row align-items-center">
                          <div className="col-1 text-center">
                            <div
                              className={
                                tracking_timeline.length - 1 === index
                                  ? "vertical-line d-none"
                                  : "vertical-line"
                              }
                            ></div>
                            <div
                              className={
                                index === 0 ? "dot current-status" : "dot"
                              }
                            ></div>
                          </div>
                          <div className="col-11 text-detials">
                            {list.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrackStatus;
