import React from "react";
import "./Popup.scss";

function PopupError({ errorMessage }) {

    const handleClose = () => {
        document.getElementById("request-cancel-result-close-btn").click();
    }

    return (
        <>
            <button type="button" id="request-cancel-result-btn" className="btn btn-primary request-cancel-result-button" data-bs-toggle="modal" data-bs-target="#requestCancelResult" hidden>
                ตกลง
            </button>
            <div className="request-cancel-result-modal">
                <div className="modal fade" id="requestCancelResult" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="requestCancelResultLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="request-cancel-result-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                <div className="row g-0 body-content text-center">
                                    {
                                        errorMessage?.header &&
                                        <div className="col-12 error-header">
                                            {errorMessage.header}
                                        </div>
                                    }
                                    {errorMessage?.body[0] ?
                                        <div className="text-one-line">
                                            {
                                                errorMessage.body.map(message =>
                                                    <div className="col-12">
                                                        {message}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        :
                                        <div className="col-12 text-one-line">
                                            {errorMessage.body[1]}
                                        </div>
                                    }
                                </div>
                                <div className="row justify-content-center btn-section g-0">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn grrs-popup-close-btn grrs-primary-button" onClick={handleClose}>ตกลง</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default PopupError;