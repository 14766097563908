import React, { useState, useEffect } from "react";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import thai from "../../../../utils/Calendar/thai";
import thai_th from "../../../../utils/Calendar/thai_th";

const CalendarWithValidate = ({ date, dateContact, setDateContact, calendarName, refundConnect ,setDateCheck}) => {
    const [isMorethanMinDate, setIsMorethanMinDate] = useState(false);
    const [dateIsInValid, setDateIsInValid] = useState(false);

    const handleSetDateContact = (e) => {
        if (e) {
            setDateContact(e.format());
        } else {
            setDateIsInValid(true);
        setDateCheck(dateIsInValid||isMorethanMinDate)
        }
    }

    useEffect(() => {
        handleSetDateContact2();
        setDateCheck(dateIsInValid||isMorethanMinDate)
    }, [dateContact,dateIsInValid,isMorethanMinDate])

    const errorDateContactClassName = dateIsInValid || isMorethanMinDate ? "form-control rmdp-input is-invalid" : "form-control rmdp-input"
    const handleSetDateContact2 = () => {
        const pattern = /[0-9]{2}\/(0[1-9])|(1[0-2])\/[0-9]{2}/;
        const tempValue = document.getElementById(calendarName).value;
        const monthWithPrefixKom = [1, 3, 5, 7, 8, 10, 12];
        const monthWithPrefixYon = [4, 6, 9, 11];
        const monthWithPrefixPan = [2];

        let tempArray = tempValue.split("/");
        let day = tempArray[0];
        day = parseInt(day, 10);
        let month = tempArray[1];
        month = parseInt(month, 10);
        let currentYear = tempArray[2];
        let year = "25" + currentYear;
        year = parseInt(year, 10) - 543;
        const BuddhistYear = year + 543;

        const minDateArray = date.split("/");
        const minDateDay = parseInt(minDateArray[2], 10);
        const minDateMonth = parseInt(minDateArray[1], 10);
        const minDateYear = parseInt(minDateArray[0], 10);
        if (tempValue.length == 0) {
            setDateIsInValid(false);
        }
        else {
            if (!pattern.exec(tempValue)) {
                setDateIsInValid(true);
            }
            else if (monthWithPrefixKom.find(m => month == m)) {
                if (day > 31) {
                    setDateIsInValid(true);
                }
                else setDateIsInValid(false);
            }
            else if (monthWithPrefixYon.find(m => month == m)) {
                if (day > 30) {
                    setDateIsInValid(true);
                }
                else setDateIsInValid(false);
            }
            else if (monthWithPrefixPan.find(m => month == m)) {
                if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
                    if (day > 29) {
                        setDateIsInValid(true);
                    }
                    else setDateIsInValid(false);
                } else {
                    if (day > 28) {
                        setDateIsInValid(true);
                    }
                    else setDateIsInValid(false);
                }
            }

            if ((BuddhistYear * 365) + (month * 31) + day < (minDateYear * 365) + (minDateMonth * 31) + minDateDay) {
                setIsMorethanMinDate(true);
            } else {
                setIsMorethanMinDate(false);
            }
        }
    }
    return (
        <>
            < DatePicker
                selected={dateContact}
                onChange={handleSetDateContact}
                value={dateContact}
                calendar={thai}
                locale={thai_th}
                format="DD/MM/YY"
                minDate={date}
                render={< InputIcon className={errorDateContactClassName + " customer-service-calendar"} maxlength="8" id={calendarName} disabled={refundConnect}/>}
                required
            />
            {
                (isMorethanMinDate && !dateIsInValid) &&
                <div className="error-customerservice">
                    กรุณาใส่วันที่ให้มากกว่าวันที่ปัจจุบัน
                </div>
            } {
                dateIsInValid &&
                <div className="error-customerservice">
                    กรุณาใส่รูปแบบวันที่ให้ถูกต้อง
                </div>
            }
        </>
    )
}
export default CalendarWithValidate;