import React from "react";
import { Link } from "react-router-dom";
const PreRequestTable = ({ data, permission }) => {
  return (
    <>
      <div className="ecn table-responsive col-12 col-lg-12">
        <table className="ecn table-primary">
          <thead className="ecn table-header">
            <tr>
              <th>วันที่นำข้อมูลเข้า</th>
              <th>เลขที่คำสั่งซื้อ</th>
              <th>เลขที่ใบกำกับภาษี</th>
              <th>เลขที่ใบลดหนี้</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((val, index) => {
              return (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "light-blue" : ""}
                >
                  <td>{val.added_date}</td>
                  <td>{val.order_no}</td>
                  <td>
                    {
                      permission.some((res) => res === "search") ?
                        (
                          <a href={val.ref_etax_pdf} >{val.ref_etax_no}</a>
                        )
                        : <span>{val.ref_etax_no}</span>
                    }
                  </td>
                  <td>
                    {
                      permission.some((res) => res === "create") ?
                        (<Link
                          to={`/ecn/add-ecreditnote?taxNo=${val.order_no}&orderSource=${val.ordersource}`}
                        >
                          สร้างคำขอคืนสินค้า
                        </Link>
                        ) :
                        <span>สร้างคำขอคืนสินค้า</span>
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PreRequestTable;
