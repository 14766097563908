import React from "react";
import "./Pagination.scss";

function Pagination({
    parentPage = "",
    permission = [],
    isNoData,
    setPageSize,
    pageSize,
    rows,
    pageIndex,
    pageOptions,
    canPreviousPage,
    canNextPage,
    previousPage = () => { },
    nextPage = () => { },
    selectedFlatRows = [],
    handleRefundBtn = () => { },
    isCashMode = null
}) {

    const isCanUpdate = permission.some((res) => res === "update");

    return (
        <>
            <div className={"row grrs-pagination-btn-section align-items-center"}>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 grrs-btn-section-left">
                    <h4 className="grrs-left-page-desctiption">
                        <span>
                            แสดงรายการ
                        </span>
                        {
                            rows.length > 20 ?
                                (
                                    <select
                                        className="grrs-page-size"
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                    >
                                        {[20, 40, 60, 80, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                )
                                :
                                (
                                    <select
                                        className="grrs-page-size"
                                        value={rows.length}
                                        disabled
                                    >
                                        <option key={pageSize} value={rows.length}>
                                            {20}
                                        </option>
                                    </select>
                                )
                        }
                        <span>
                            จาก {rows.length} รายการ
                        </span>
                    </h4>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 text-end text-xl-start px-0">
                    <button onClick={() => previousPage()} className={canPreviousPage ? "grrs-primary-button grrs-btn-prePage" : "grrs-btn-prePage faded"}>
                        <img src={process.env.PUBLIC_URL + "/assets/icons/pre-page.svg"} alt="pre" />
                    </button>
                    <button className="span-page">
                        <div className="grrs-span-pagination">
                            <span>{pageIndex + 1} OF {isNoData ? 1 : pageOptions.length}</span>
                        </div>
                    </button>
                    <button onClick={() => nextPage()} className={canNextPage ? "grrs-primary-button grrs-btn-nextPage" : "grrs-btn-nextPage faded"}>
                        <img src={process.env.PUBLIC_URL + "/assets/icons/next-page.svg"} alt="next" />
                    </button>
                </div>
                <div className="grrs-btn-section-right col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 text-xl-end" hidden={parentPage === "createFile" || parentPage === ""}>
                    {
                        isCashMode && parentPage === "approveRefund" && (
                            <div className="row align-items-center" hidden={!isCanUpdate}>
                                <span className="grrs-right-page-description col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5 text-start text-xl-end">จำนวนที่เลือก {selectedFlatRows.length} รายการ</span>
                                <div className="text-end col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 col-xxl-7 px-0">
                                    <button onClick={(e) => handleRefundBtn(e)} className="btn grrs-primary-button accounting-confirm-refund-btn" name="approve">
                                        ยืนยันการคืนเงิน
                                    </button>
                                    <button onClick={(e) => handleRefundBtn(e)} className="btn grrs-white-button accounting-edit-account-id-btn" name="reject">
                                        แก้ไขเลขที่บัญชี
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        !isCashMode && parentPage === "approveRefund" && (
                            <div className="row align-items-center" hidden={!isCanUpdate}>
                                <span className="grrs-right-page-description col-6 col-sm-6 col-md-6 col-lg-6 col-xl-9 col-xxl-9 text-start text-xl-end">จำนวนที่เลือก {selectedFlatRows.length} รายการ</span>
                                <div className="text-end col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 px-0">
                                    <button onClick={(e) => handleRefundBtn(e)} className="btn grrs-primary-button accounting-confirm-refund-btn" name="approve">
                                        ยืนยันการคืนเงิน
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Pagination;