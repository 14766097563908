import "../../../../scss/userMaintenance/roleManagement/_roleButton.scss";
import { useContext } from "react";
import MenuContex from "../../../../context/MenuContext.js";

const RoleButton = ({ text }) => {
  const { permission } = useContext(MenuContex);
  return (
    <button className={permission.some((res) => res === "create") ? "addbutton mt-3" : "disable-button addbutton mt-3"} disabled={!permission.some((res) => res === "create")}>
      <img
        className="plus-icon"
        src="/assets/userMaintenance/plus.svg"
        alt="plus"
      />
      {text}
    </button>
  );
};
export default RoleButton;
