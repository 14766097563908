import React, { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import "react-datepicker/dist/react-datepicker.css";

import thai from "../../../../../utils/Calendar/thai";
import thai_th from "../../../../../utils/Calendar/thai_th";
import "./FormSearch.scss";
import { getCancellationRequestFilter, getCancellationReqRefundFilter, postCancellationRequestList } from "../../../../../service/grrs/master.service";
import { postCancellationRequestRefund, postCancellationReqCashRefund, postCancellationReqOnlineRefund } from "../../../../../service/grrs/accounting.service";
import { handleEmptyStringValue } from "../../../../../helper/commonHelper";

const initialState = {
  reason_code: "",
  refund_channel_code: "",
  request_refund_status_code: "",
  request_type_code: "",
  customer_request_no: "",
  order_date_to: "",
  order_no: "",
  phone_no: "",
  ordersource: "",
  order_date_from: "",
};

const initialStateAccountPage = {
  customer_request_no: "",
  order_no: "",
  ecn_status_code: "",
  request_refund_status_code: "",
  refund_channel_code: "",
  status_urgent_code: "",
  wh_approve_status_code: "",
  request_date_to: "",
  request_date_from: "",
  refund_date_to: "",
  refund_date_from: "",
};

function FormSearch({ parentPage = "", setSearchData, setExportData, setIsLoading = () => { } }) {
  const isAccountPage = parentPage.includes("approveRefund") === true || parentPage === "createFile";
  const [formData, setFormData] = useState(isAccountPage ? initialStateAccountPage : initialState);
  const [formSelectList, setFormSelectList] = useState(isAccountPage ?
    {
      request_refund_status: [],
      refund_channel: [],
      status_urgent: [],
      ecn_status: [],
      wh_approve_status: []
    } : {
      request_type: [],
      request_refund_status: [],
      refund_channel: [],
      reason: [],
      ordersource: []
    }
  );
  const [filterAmount, setFilterAmount] = useState();
  const [orderDateFrom, setOrderDateFrom] = useState("");
  const [requestOrderDateFrom, setRequestOrderDateFrom] = useState("");
  const [refundOrderDateFrom, setRefundOrderDateFrom] = useState("");

  const { refund_channel, request_refund_status } = formSelectList;

  const { status_urgent, wh_approve_status, ecn_status } = formSelectList;

  useEffect(() => {
    isAccountPage ?
      (
        getCancellationReqRefundFilter().then(res => {
          setFormSelectList({
            request_refund_status: checkResponseList(res.request_refund_status),
            refund_channel: checkResponseList(res.refund_channel),
            status_urgent: checkResponseList(res.status_urgent),
            ecn_status: checkResponseList(res.ecn_status),
            wh_approve_status: checkResponseList(res.wh_approve_status)
          });
        })
      )
      : (
        getCancellationRequestFilter().then(res => {
          setFormSelectList({
            request_type: checkResponseList(res.request_type),
            request_refund_status: checkResponseList(res.request_refund_status),
            refund_channel: checkResponseList(res.refund_channel),
            reason: checkResponseList(res.reason),
            ordersource: checkResponseList(res.ordersource)
          });
        })
      )
  }, [])

  const checkResponseList = (responseData) => {
    return responseData === undefined ? [] : responseData;
  }

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleDateData = (e, name) => {
    if (e) {
      if (name.includes("from") === true) {
        const dateTo = name.replace("from", "to");
        setMinDate(e, name);
        e = e.format("DD/MM/YYYY");
        setFormData((prev) => ({
          ...prev,
          [name]: e,
          [dateTo]: e
        }));
      } else {
        setMinDate(e, name);
        e = e.format("DD/MM/YYYY");
        setFormData((prev) => ({
          ...prev,
          [name]: e
        }));
      }
    }
  };

  const setMinDate = (e, name) => {
    switch (name) {
      case "order_date_from": {
        setOrderDateFrom(new DateObject(e))
      }
        break;
      case "request_date_from": {
        setRequestOrderDateFrom(new DateObject(e))
      }
        break;
      case "refund_date_from": {
        setRefundOrderDateFrom(new DateObject(e))
      }
        break;
    }
  }

  const handleReset = () => {
    if (isAccountPage) {
      setFormData(initialStateAccountPage);
      setRequestOrderDateFrom("");
      setRefundOrderDateFrom("");
    } else {
      setFormData(initialState);
      setOrderDateFrom("");
    }
  }

  const handleSubmit = () => {
    setIsLoading(true);
    const postData = isAccountPage ?
      ({
        customer_request_no: handleEmptyStringValue(formData?.customer_request_no),
        order_no: handleEmptyStringValue(formData?.order_no),
        request_refund_status_code: handleEmptyStringValue(formData?.request_refund_status_code),
        refund_channel_code: handleEmptyStringValue(formData?.refund_channel_code),
        status_urgent_code: handleEmptyStringValue(formData?.status_urgent_code),
        ecn_status_code: handleEmptyStringValue(formData?.ecn_status_code),
        wh_approve_status_code: handleEmptyStringValue(formData?.wh_approve_status_code),
        request_date_to: handleEmptyStringValue(formData?.request_date_to),
        request_date_from: handleEmptyStringValue(formData?.request_date_from),
        refund_date_to: handleEmptyStringValue(formData?.refund_date_to),
        refund_date_from: handleEmptyStringValue(formData?.refund_date_from)
      })
      : ({
        reason_code: handleEmptyStringValue(formData?.reason_code),
        refund_channel_code: handleEmptyStringValue(formData?.refund_channel_code),
        request_refund_status_code: handleEmptyStringValue(formData?.request_refund_status_code),
        request_type_code: handleEmptyStringValue(formData?.request_type_code),
        customer_request_no: handleEmptyStringValue(formData?.customer_request_no),
        order_date_to: handleEmptyStringValue(formData?.order_date_to),
        order_no: handleEmptyStringValue(formData?.order_no),
        phone_no: handleEmptyStringValue(formData?.phone_no),
        ordersource: handleEmptyStringValue(formData?.ordersource),
        order_date_from: handleEmptyStringValue(formData?.order_date_from),
      })
    if (isAccountPage) {
      if (parentPage.includes("cash") === true) {
        postCancellationReqCashRefund(postData).then(res => {
          handleResponseData(res, postData);
        });
      }
      else if (parentPage.includes("online") === true) {
        postCancellationReqOnlineRefund(postData).then(res => {
          handleResponseData(res, postData);
        });
      }
      else {
        postCancellationRequestRefund(postData).then(res => {
          handleResponseData(res, postData);
        })
      }
    } else {
      postCancellationRequestList(postData).then(res => {
        handleResponseData(res, postData);
      })
    }

    const closeBtn = document.getElementById("modal-btn-close");
    closeBtn.click();
  }

  const handleResponseData = (res, postData) => {
    if (res?.data === undefined) {
      setSearchData([]);
      setExportData(postData);
      setFilterAmount(countFilter());
    } else {
      setSearchData(res.data);
      setExportData(postData);
      setFilterAmount(res.filter_count);
    }
    setIsLoading(false);
  }

  const countFilter = () => {
    const countFilterAmount = Object.keys(formData).map(key => {
      return formData[key]
    }).reduce((sum, value) => value === "" ? sum : sum + 1, 0);
    return countFilterAmount;
  }

  return (
    <>
      <button type="button" className="btn btn-primary position-relative grrs-filter-button" data-bs-toggle="modal" data-bs-target="#filterModal">
        <img src={process.env.PUBLIC_URL + "/assets/grrs/icons/filter-button-icon.svg"} className="mx-auto filter-button-icon" alt="filter" />
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill">
          {filterAmount}
        </span>
      </button>
      <div className="grrs-form-search">
        <div>
          <div className="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-fullscreen-lg-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title" id="filterModalLabel">
                    <img src={process.env.PUBLIC_URL + "/assets/grrs/icons/filter-text-icon.svg"} className="mx-auto" alt="filter" />
                    <span className="modal-title-text fw-bold">ตัวกรอง</span>
                  </h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="modal-btn-close"></button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    {
                      isAccountPage ? (
                        <>
                          <div className="row align-items-center">
                            <div className="col-1">
                              <span>Customer Request NO : </span>
                            </div>
                            <div className="col-3">
                              <input type="number" className="form-control" name="customer_request_no" value={formData.customer_request_no} onChange={(e) => handleChange(e)} />
                            </div>

                            <div className="col-1">
                              <span>Order_NO : </span>
                            </div>
                            <div className="col-3">
                              <input type="number" className="form-control" name="order_no" value={formData.order_no} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="col-1">
                              <span>Refund Status :</span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="request_refund_status_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.request_refund_status_code}
                                disabled={parentPage.includes("approveRefund") === true ? true : false}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  request_refund_status.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.request_refund_status_code}>{list.request_refund_status_text}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-1">
                              <span>ช่องทางการคืนเงิน : </span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="refund_channel_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.refund_channel_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  refund_channel.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.channel_code}>{list.channel_text}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-1">
                              <span>Status Urgent :</span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="status_urgent_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.status_urgent_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  status_urgent.map((list, index) => {
                                    return (
                                      <>
                                        <option key={index} value={list.status_urgent_code}>{list.status_urgent_text}</option>
                                      </>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-1">
                              <span>Create E-CN status : </span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="ecn_status_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.ecn_status_code}
                                disabled={parentPage.includes("approveRefund") === true ? true : false}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  ecn_status.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.ecn_status_code}>{list.ecn_status_text}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-1">
                              <span>WH Approve Status :</span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="wh_approve_status_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.wh_approve_status_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  wh_approve_status.map((list, index) => {
                                    return (
                                      <>
                                        <option key={index} value={list.wh_approve_status_code}>{list.wh_approve_status_text}</option>
                                      </>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-1">
                              <span className="white-space-right-5 width-mobile">Request Date :</span>
                            </div>
                            <div className="col-3 mobile-view">
                              <DatePicker
                                name="request_date_from"
                                value={formData.request_date_from}
                                onChange={(e) => handleDateData(e, "request_date_from")}
                                calendar={thai}
                                locale={thai_th}
                                format="DD/MM/YYYY"
                                calendarPosition="top"
                                zIndex={9999}
                                portal={true}
                                render={<InputIcon />}
                              ></DatePicker>
                            </div>
                            <div className="col-1">
                              <span className="text-start width-mobile">
                                ถึง
                              </span>
                            </div>
                            <div className="col-3 mobile-view">
                              <DatePicker
                                name="request_date_to"
                                value={formData.request_date_to}
                                onChange={(e) => handleDateData(e, "request_date_to")}
                                calendar={thai}
                                locale={thai_th}
                                format="DD/MM/YYYY"
                                minDate={requestOrderDateFrom}
                                calendarPosition="top"
                                zIndex={9999}
                                portal={true}
                                render={<InputIcon />}
                              ></DatePicker>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-1">
                              <span className="white-space-right-5 width-mobile">Refund Date :</span>
                            </div>
                            <div className={parentPage.includes("approveRefund") === true ? "col-3 mobile-view disabled-date-field" : "col-3 mobile-view"}>
                              <DatePicker
                                name="refund_date_from"
                                value={formData.refund_date_from}
                                onChange={(e) => handleDateData(e, "refund_date_from")}
                                calendar={thai}
                                locale={thai_th}
                                format="DD/MM/YYYY"
                                calendarPosition="top"
                                zIndex={9999}
                                portal={true}
                                readOnly={parentPage.includes("approveRefund") === true ? true : false}
                                render={<InputIcon />}
                              ></DatePicker>
                            </div>
                            <div className="col-1">
                              <span className="text-start width-mobile">
                                ถึง
                              </span>
                            </div>
                            <div className={parentPage.includes("approveRefund") === true ? "col-3 mobile-view disabled-date-field" : "col-3 mobile-view"}>
                              <DatePicker
                                name="refund_date_to"
                                value={formData.refund_date_to}
                                onChange={(e) => handleDateData(e, "refund_date_to")}
                                calendar={thai}
                                locale={thai_th}
                                format="DD/MM/YYYY"
                                minDate={refundOrderDateFrom}
                                calendarPosition="top"
                                zIndex={9999}
                                portal={true}
                                readOnly={parentPage.includes("approveRefund") === true ? true : false}
                                render={<InputIcon />}
                              ></DatePicker>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row align-items-center">
                            <div className="col-1">
                              <span>Request type :</span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="request_type_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.request_type_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  formSelectList.request_type.map((list, index) => {
                                    return (
                                      <>
                                        <option key={index} value={list.request_type_code}>{list.request_type_text}</option>
                                      </>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-1">
                              <span>สาเหตุ : </span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="reason_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.reason_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  formSelectList.reason.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.reason_code}>{list.reason_text}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-1">
                              <span>สถานะการคืนเงิน :</span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="request_refund_status_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.request_refund_status_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  formSelectList.request_refund_status.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.request_refund_status_code}>{list.request_refund_status_text}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-1">
                              <span>ช่องทางการคืนเงิน : </span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="refund_channel_code"
                                onChange={(e) => handleChange(e)}
                                value={formData.refund_channel_code}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  formSelectList.refund_channel.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.channel_code}>{list.channel_text}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-1">
                              <span>Customer Request NO. : </span>
                            </div>
                            <div className="col-3">
                              <input type="number" className="form-control" name="customer_request_no" value={formData.customer_request_no} onChange={(e) => handleChange(e)} />
                            </div>

                            <div className="col-1">
                              <span>Order NO. : </span>
                            </div>
                            <div className="col-3">
                              <input type="number" className="form-control" name="order_no" value={formData.order_no} onChange={(e) => handleChange(e)} />
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-1">
                              <span>Phone NO. : </span>
                            </div>
                            <div className="col-3">
                              <input type="number" className="form-control" name="phone_no" id="phone_no" value={formData.phone_no} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="col-1">
                              <span className="col-4">Order Sort Name : </span>
                            </div>
                            <div className="col-3">
                              <select className="form-select"
                                name="ordersource"
                                onChange={(e) => handleChange(e)}
                                value={formData.ordersource}
                              >
                                <option value="" hidden>กรุณาเลือก</option>
                                {
                                  formSelectList.ordersource.map((list, index) => {
                                    return (
                                      <> <option key={index} value={list.ordersource}>{list.ordersource_name}</option></>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-4">

                            </div>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-1">
                              <span className="white-space-right-5 width-mobile">วันที่สั่งซื้อ :</span>
                            </div>
                            <div className="col-3 mobile-view">
                              <DatePicker
                                name="order_date_from"
                                value={formData.order_date_from}
                                onChange={(e) => handleDateData(e, "order_date_from")}
                                calendar={thai}
                                locale={thai_th}
                                format="DD/MM/YYYY"
                                calendarPosition="top"
                                zIndex={9999}
                                portal={true}
                                render={<InputIcon />}
                              ></DatePicker>
                            </div>
                            <div className="col-1">
                              <span className="text-start width-mobile">
                                ถึง
                              </span>
                            </div>
                            <div className="col-3 mobile-view">
                              <DatePicker
                                name="order_date_to"
                                value={formData.order_date_to}
                                onChange={(e) => handleDateData(e, "order_date_to")}
                                calendar={thai}
                                locale={thai_th}
                                format="DD/MM/YYYY"
                                minDate={orderDateFrom}
                                calendarPosition="top"
                                zIndex={9999}
                                portal={true}
                                render={<InputIcon />}
                              ></DatePicker>
                            </div>
                          </div>
                        </>
                      )}
                    <div className="row justify-content-center">
                      <div className="col-6 text-end">
                        <button type="button" className="btn grrs-white-button" onClick={() => handleReset()}>ล้างข้อมูล</button>
                      </div>
                      <div className="col-6 text-start">
                        <button type="button" className="btn grrs-primary-button" onClick={() => handleSubmit()}>ตกลง</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormSearch;