import React from "react";
import "./FormRequestCancel.scss";
import { useState } from "react";

import TrackingModal from "../TrackingModal";

function CustomerInfo({
  isHaveTrackingModal = false,
  trackingData,
  cancellationInitial,
}) {
  const [isDisplay, setIsDisplay] = useState(true);

  const {
    mainorder_no,
    order_date,
    request_refund_status_text,
    customer_shipto_firstname,
    customer_shipto_lastname,
    customer_shipto_phone,
    payment_channel_name,
    total_price,
    all_member_point_burn,
    point_burn,
    ordersource_name,
    edcm_credit,
    shipto_type,
    shipto_store_no,
    shipto_store_name,
    address1,
    create_date,
    member_id,
  } = cancellationInitial.orders;
  return (
    <div className="form-request-cancel">
      <div className="header-request-cancel header-request-cancel-view">
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">ข้อมูลลูกค้า</span>
      </div>
      {isDisplay && (
        <div className="body-request-cancel ">
          {isHaveTrackingModal && (
            <div className="status-request-cancel">
              <span className="fw-bold">ยกเลิกคำสั่งซื้อแล้ว </span>
              <span className="ml-5">{create_date}</span>
              <div className="float-right">
                <TrackingModal
                  trackingData={trackingData}
                  request_refund_status_text={request_refund_status_text}
                />
              </div>
            </div>
          )}
          <div className="body-form-request-cancel">
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">วันรับเรื่อง</label>
                <input className="form-control" value={order_date} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Order No.</label>
                <input className="form-control" value={mainorder_no} disabled />
              </div>
              <div className="col-md-6">
                <label className="form-label">Order Date.</label>
                <input className="form-control" value={order_date} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Account.</label>
                <input className="form-control" value={member_id} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">ชื่อ - นามสกุล</label>
                <input
                  className="form-control"
                  value={`${customer_shipto_firstname} ${customer_shipto_lastname}`}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Phone No.</label>
                <input
                  className="form-control"
                  value={customer_shipto_phone}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Paymode.</label>
                <input
                  className="form-control"
                  value={payment_channel_name}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Order Value.</label>
                <input className="form-control" value={total_price} disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">MemberPoints.</label>
                <input
                  className="form-control"
                  value={all_member_point_burn}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Order Source Name.</label>
                <input
                  className="form-control"
                  value={ordersource_name}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Point Burn.</label>
                <input className="form-control" value={point_burn} disabled />
              </div>
              <div className="col-md-6">
                <label className="form-label">Edcm Credit.</label>
                <input className="form-control" value={edcm_credit} disabled />
              </div>
            </div>
            {shipto_type === "store" && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">Ship to</label>
                    <input
                      className="form-control"
                      value={shipto_type}
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Store No.</label>
                    <input
                      className="form-control"
                      value={shipto_store_no}
                      disabled
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">Store Name.</label>
                    <input
                      className="form-control"
                      value={shipto_store_name}
                      disabled
                    />
                  </div>
                </div>
              </>
            )}

            {shipto_type !== "store" && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">Ship to</label>
                    <input
                      className="form-control"
                      value={shipto_type}
                      disabled
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">Address</label>
                    <textarea
                      className="form-control"
                      value={address1}
                      disabled
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerInfo;
