import axios from "axios";
import { checkAccessToken } from "../../helper/grrs/grrsHelper";

const GRRS_URL = process.env.REACT_APP_GRRS_API_URL;
const GRRS_AUTH = () =>
  `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
const X_API_KEY = process.env.REACT_APP_GRRS_X_API_KEY;

const getOrderCancellationReqDetApprv = async (customerRequestNo) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/no/${customerRequestNo}/approval`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getCancellationRefundMaster = async (orderNo, ordersource) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/refund/master?mainorder_no=${orderNo}&ordersource=${ordersource}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const putCancellationRequestApprv = async (req) => {
  const data = {
    customer_request_no: req.customer_request_no,
    refund_method: {
      channel_code: req.refund_method.channel_code,
      bank_account_type_code: req.refund_method.bank_account_type_code,
      promtpay_no: req.refund_method.promtpay_no,
      bank_code: req.refund_method.bank_code,
      account_no: req.refund_method.account_no,
      account_name: req.refund_method.account_name,
    },
    cs_approve: req.cs_approve,
  };

  try {
    const response = await axios.put(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/approval`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

export {
  getOrderCancellationReqDetApprv,
  getCancellationRefundMaster,
  putCancellationRequestApprv,
};
