import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Popup.scss";

function Result({ resultIsSuccess, selectedBtnType, pathName, setDisplayResultModal, displayResultModal, approveResponse = {} }) {
    let history = useHistory()

    const refreshPage = () => {
        const splitPathName = pathName.split("/");
        const isFromApprovePage = splitPathName[3].includes("ยืนยันผลการคืนเงิน");
        if (resultIsSuccess) {
            if (isFromApprovePage && splitPathName.length === 5 && resultIsSuccess) {
                window.location.reload(false);
            } else {
                history.push(pathName);
            }
            setDisplayResultModal(false);
        } else {
            if (isFromApprovePage && splitPathName.length === 5) {
                window.location.reload(false);
            } else {
                history.push(pathName);
            }
            setDisplayResultModal(false);
        }
    };

    useEffect(() => {
        if (displayResultModal === true) {
            document.getElementById("result-btn").click();
        }
    }, [displayResultModal]);

    return (
        <>
            <button type="button" id="result-btn" className="btn btn-primary result-modal-button" data-bs-toggle="modal" data-bs-target="#resultPopup" hidden>
                ตกลง
            </button>
            <div className="resultPopupModal">
                <div className="modal fade" id="resultPopup" tabindex="-1" aria-labelledby="resultPopupLabel" aria-hidden="true" onClick={() => refreshPage()}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="result-modal-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                {
                                    resultIsSuccess ? (
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center">
                                                    <img className="icon-modal-success" src="/assets/icons/modal-icon-success.svg"></img>
                                                </div>
                                                <div className="col-12 text-center result-modal-text">
                                                    <span>
                                                        {
                                                            selectedBtnType === "approve" ? "ยืนยันการคืนเงินสำเร็จ" :
                                                                selectedBtnType === "reject" ? "ส่งเรื่องแก้ไขข้อมูลการโอนเงินสำเร็จ" : "อัปโหลดข้อมูลสำเร็จแล้ว"}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center">
                                                    <img className="icon-modal-fail" src="/assets/icons/modal-icon-fail.svg"></img>
                                                </div>
                                                <div className="col-12 text-center result-modal-text">
                                                    {
                                                        approveResponse?.response_data?.message !== undefined ? (
                                                            <span>
                                                                {approveResponse?.response_data?.message}
                                                            </span>
                                                        ) :
                                                            <span>
                                                                {
                                                                    selectedBtnType === "approve" ? "ยืนยันการคืนเงิน" :
                                                                        selectedBtnType === "reject" ? "ส่งเรื่องแก้ไขข้อมูลการโอนเงิน"
                                                                            : "อัปโหลดข้อมูล"}ไม่สำเร็จ กรุณาลองทำรายการอีกครั้ง
                                                            </span>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default Result;