import { useEffect, useState, useContext } from "react";
import { getCreditNoteList, getEcnStatus } from "../../../../service/ecn/e-creditnote.service";
import MenuContext from "../../../../context/MenuContext";

const SearchECN = (props) => {
    const { permission } = useContext(MenuContext);
    const [search, setSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState();
    const [status, setStatus] = useState([]);
    const [data, setData] = useState([]);

    const handleSearch = () => {
        let stringSearch = ''
        if (search.trim().length > 0) {
            stringSearch = search.trim();
            setSearch(search.trim())
        }
        if (stringSearch && statusSearch) {
            props.setIsLoading(true);
            getCreditNoteList(stringSearch, statusSearch).then(res => {
                props.setData(res)
                setData(res)
                props.setIsLoading(false);
            });

        } else {
            props.setIsLoading(true);
            getCreditNoteList(stringSearch, statusSearch).then(res => {
                props.setData(res)
                setData(res)
                props.setIsLoading(false);
            });
        }
    }

    const handleFilterStatus = (e) => {
        setStatusSearch(e.target.value);
    }

    const handleChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        getEcnStatus().then(res => {
            setStatus(res);
        })
    }, []);

    return (
        <>
            <div className="row align-items-center ecn-search-container">
                <div className="col-12 col-sm-12 col-md-2 col-lg-1 col-xxl-1 mt-3 ecn-search">
                    <label className="">ค้นหา :</label>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-4 col-xxl-4 mt-3 ecn-search">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="เลขที่คำสั่งซื้อ, เลขที่ใบลดหนี้" value={search} onChange={(e) => handleChangeSearch(e)} />
                        <span className="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="col-12 col-sm-12 text-start text-lg-end col-md-2 col-lg-1 col-xxl-1 mt-3 ecn-status">
                    <label>สถานะ :</label>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-3 col-xxl-3 mt-3 ecn-status">
                    <select className="form-select" aria-label="Default select example" name="searchStatus" onChange={(e) => handleFilterStatus(e)}>
                        <option defaultValue value=""></option>
                        {
                            status.map((val, index) => {
                                return (
                                    <>
                                        <option key={index} value={val.key}>{val.longname}</option>
                                    </>
                                )
                            })
                        }
                    </select>
                </div>
                <button className="col-8 col-sm-6 col-md-6 col-lg-3 col-xxl-3 mt-3 mx-auto btn ecn-search-button" onClick={handleSearch} disabled={!statusSearch || !permission.some((res) => res === "search")}>ค้นหา</button>
            </div>
        </>
    )
}

export default SearchECN;