import React, { useState } from "react";

const BarcodeNumber = (props) => {
  const { invoiceDetailData } = props;
  const [rotate, setRotate] = useState("");
  const [display, setDisplay] = useState(true);
  const toggleButton = (boxId) => {
    if (display) {
      setDisplay(false);
    }
    else {
      setDisplay(true);
    }
  };

  return (
    <>
      {invoiceDetailData?.map((e, index) => {
        return (
          <>
            <div className="container-md" key={e.id}>
              <div
                className="card-body barcodenumber"
                id="customerinfo-card-body"
              >
                <div className="title-invoicedetail">
                  {/* className={barcodeBoxId== e.boxId && display ? "rotate" : ""} */}
                  <button
                    id={e?.box_id + "_" + index}
                    // className={rotate}
                    className={display ? "rotate" : ""}
                    onClick={() => toggleButton(e?.box_id + "_" + index)}
                  >
                    <span className="button-rotate">&#707;</span>
                  </button>
                  บาร์โค้ดกล่อง : {e?.box_id}
                  <span className="box">
                    กล่องที่ : {`${index + 1} / ${invoiceDetailData.length}`}
                  </span>
                </div>
              </div>
              <div id={e?.box_id + "_" + index} className={display ? "" : "disable"}>
                {e.items?.map((element) => {
                  return (
                    <div className="list-box">
                      <span className="col-3">{element.product_code}</span>
                      <span className="col-6">{element.product_name}</span>
                      <span className="col-1">{element.product_qty}</span>
                      <span className="col-2">฿ {element.product_price}</span>
                    </div>
                  );
                })}
              </div>
              <br />
            </div>
          </>
        );
      })}
    </>
  );
};

export default BarcodeNumber;
