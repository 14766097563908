const NotFound = () => {
  return (
    <div className="d-flex justify-content-center">
      <img
        className="user-icon"
        src="/assets/userMaintenance/notfound.svg"
        alt="notfound"
      />
    </div>
  );
};

export default NotFound;
