import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import CustomerInfo from "../../components/Share/FormRequestCancel/customerInfo";
import SummaryRefundCancel from "../../components/Share/FormRequestCancel/summaryRefundCancel";
import Reason from "../../components/Share/FormRequestCancel/reason";
import ChannelRefund from "../../components/Share/FormRequestCancel/channelRefund";
import TransactionHistory from "../../components/Share/FormRequestCancel/transactionHistory";
import TrackStatus from "../../components/Share/FormRequestCancel/trackStatus";
import DetailCancel from "../../components/Share/FormRequestCancel/detailCancel";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import "./ContactCenter.scss";
import {
  getOrderCancellationReqDetails,
  getCancellationRefundMaster,
  getOrderCancellationRequestTimeline,
} from "../../../../service/grrs/contactCenter.service";
import { getSummaryRefund } from "../../../../helper/grrs/redeemAndEarnPointHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import CheckRefund from "../../components/Accounting/FormRequestCancel/checkRefund.jsx";

const initCancellationRefundMaster = {
  reason_list: [],

  refund_channel: [],

  bank_master: {
    bank_account: [],

    bank_account_type: [],
  },
  cancel_policy_link: "",
};

const initTrackingData = {
  orders: {
    order_tracking_status: [],
    tracking_timeline: [],
  },
};

const initCancellationInitial = {
  tracking_timeline: [],
  orders: {
    total_price: 0,
    order_detail_list: [],
    redeemedSummary: [],
    earnPoints: [],
    request_activity_log: [],
  },
};

const initReason = {
  reason_code: "",
  reason_remark: "",
  is_all_member_point_compensation: 0,
  cc_remark: "",
};

const initChannelRefund = {
  channel_code: "",
  bank_account_type_code: "",
  promtpay_no: "",
  bank_code: "",
  account_no: "",
  account_name: "",
};

const initCheckRefund = {
  customer_request_no: "",
  is_approve: 2,
  remark: "",
};

function RequestCancelView() {
  const { customerRequestNo, orderNo, ordersource } = useParams();
  const [channelRefundForm, setChannelRefundForm] = useState(initChannelRefund);
  const [cancellationRefundMaster, setCancellationRefundMaster] = useState(
    initCancellationRefundMaster
  );
  const [cancellationInitial, setCancellationInitial] = useState(
    initCancellationInitial
  );
  const [reason, setReason] = useState(initReason);
  const [isReasonValid, setIsReasonValid] = useState(false);
  const [isChannelRefundFormValid, setIsChannelRefundFormValid] =
    useState(false);
  const [trackingData, setTrackingData] = useState(initTrackingData);
  const [isCashMode, setIsCashMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [requestStatus, setRequestStatus] = useState("");
  const [isHaveRefundApproved, setIsHaveRefundApproved] = useState();
  const [checkRefund, setCheckRefund] = useState(initCheckRefund);

  const getOrderCancellationReqDetailsFromApi = () => {
    getOrderCancellationReqDetails(customerRequestNo).then((response) => {
      setCancellationInitial(response?.response_data);
      setReason(response?.response_data?.orders?.reason);
      setChannelRefundForm(response?.response_data?.orders?.refund_method);
      setRequestStatus(response?.response_data?.orders?.request_refund_status_code);
      setIsHaveRefundApproved(response?.response_data?.orders?.refund_approve);
      if (response?.response_data?.orders?.refund_approve !== null) {
        setCheckRefund({
          is_approve: response?.response_data?.orders?.refund_approve?.is_approve,
          remark: response?.response_data?.orders?.refund_approve?.remark,
        });
      }
      setIsLoading(false);
    });
  };

  const getOrderCancellationRequestTimelineFromApi = () => {
    getOrderCancellationRequestTimeline(customerRequestNo).then((response) => {
      setTrackingData(response?.response_data);
    });
  };

  const getCancellationRefundMasterFromApi = () => {
    getCancellationRefundMaster(orderNo, ordersource).then((res) => {
      setCancellationRefundMaster(res?.response_data);
      if (res?.response_data?.refund_channel.length === 1) {
        setIsCashMode(false);
      } else {
        setIsCashMode(true);
      }
    });
  };

  const history = useHistory();
  const backpage = () => {
    const location = { pathname: "/grrs/contact-center/สร้างคำขอแทนลูกค้า" };
    history.push(location);
  };
  useEffect(() => {
    setIsLoading(true);
    getOrderCancellationReqDetailsFromApi();
    getOrderCancellationRequestTimelineFromApi();
    getCancellationRefundMasterFromApi();
  }, []);

  const {
    refundAllMemberPoint,
    refundNonAllMemberPoint,
    cancelAllMemberPoint,
    cancelNonAllMemberPoint,
  } = getSummaryRefund(cancellationInitial.orders);

  return (
    <>
      <div className="container">
        <div className="layout">
          <div className="row vh-100">
            <div className="row">
              <ResponsiveSideBar />
              <div className="col-md-9 content">
                <LoadingSpinner isLoading={isLoading} />
                <div className="container">
                  <div className="grrs-row d-flex order-detail margin-left">
                    <button className="back-button" onClick={() => backpage()}>
                      <img src="/assets/icons/backicon.svg" alt="back" />
                    </button>
                    <span className="mt-3">
                      <h1>ทำการยกเลิกสินค้า #{orderNo}</h1>
                    </span>
                  </div>
                  {!isLoading &&
                    (
                      <>
                        <TrackStatus cancellationInitial={cancellationInitial} />
                        <CustomerInfo
                          cancellationInitial={cancellationInitial}
                          isHaveTrackingModal={true}
                          trackingData={trackingData}
                        />
                        <DetailCancel cancellationInitial={cancellationInitial} />
                        <Reason
                          reason={reason}
                          isCanEdit={false}
                          cancellationRefundMaster={cancellationRefundMaster}
                          setIsReasonValid={setIsReasonValid}
                        />
                        <ChannelRefund
                          cancellationInitial={cancellationInitial}
                          cancellationRefundMaster={cancellationRefundMaster}
                          isCashMode={isCashMode}
                          isCanEdit={false}
                          channelRefundForm={channelRefundForm}
                          isContactCenter={false}
                          setIsChannelRefundFormValid={setIsChannelRefundFormValid}
                          IsCanChangeEditMode={false}
                        />
                        {
                          isCashMode &&
                          (requestStatus === "2" || requestStatus === "3" || requestStatus === "4") &&
                          isHaveRefundApproved &&
                          (
                            <CheckRefund
                              isCanEdit={false}
                              checkRefund={checkRefund}
                              setCheckRefund={setCheckRefund}
                              isHasApproveOption={false}
                              isHasWaitingOption={true}
                            />
                          )}
                        <SummaryRefundCancel
                          cancellationInitial={cancellationInitial}
                          refundAllMemberPoint={refundAllMemberPoint}
                          refundNonAllMemberPoint={refundNonAllMemberPoint}
                          cancelAllMemberPoint={cancelAllMemberPoint}
                          cancelNonAllMemberPoint={cancelNonAllMemberPoint}
                        />
                        <TransactionHistory
                          cancellationInitial={cancellationInitial}
                        />
                        <div className="mt-5 mb-5 d-flex justify-content-center">
                          <button
                            className="request-cancel-backButton grrs-primary-button"
                            onClick={() => backpage()}
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestCancelView;
