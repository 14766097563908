import RoleAddUserMain from "../components/roleManagement/RoleAddUserMain";
function RoleAddUser() {
  return (
    <div>
      <RoleAddUserMain />
    </div>
  );
}

export default RoleAddUser;
