import React, { useEffect, useContext } from "react";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";
import { Col, Container, Row } from "react-bootstrap";
import Blank from "../../common/components/Blank";
import MenuContext from "../../../context/MenuContext";
import {
  getMenuByMenuId,
  getUserPermission,
} from "../../../service/customer-care.service";
import RoleManagement from "./RoleManagement";
import RoleAddRole from "./RoleAddRole";
import RoleEditRole from "./RoleEditRole";
import RoleAddUser from "./RoleAddUser";
import RoleManageUser from "./RoleManageUser";
import UserManagement from "./UserManagement";
import UserManagementForm from "./UserManagementForm";

function Main(props) {
  const pathName = props.location.pathname;

  const { menu, setMenu, setUserRole, profileUser, permission } = useContext(MenuContext);

  useEffect(() => {
    if (menu.length === 0 || menu.menu_id !== "51") {
      if (profileUser) {
        getMenuByMenuId("51", profileUser.email).then((res) => {
          setMenu(res?.menu_list[0]);
          setUserRole(res?.role[0]);
        });
      }
    }
  }, []);

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content">
              <div>
                {pathName === "/user-maintenance/role-management" ? (
                  <RoleManagement />
                ) : pathName === "/user-maintenance/role-add-role" ? (
                  <RoleAddRole />
                ) : pathName === "/user-maintenance/role-add-user" ? (
                  <RoleAddUser />
                ) : pathName === "/user-maintenance/role-edit-role" ? (
                  <RoleEditRole />
                ) : pathName === "/user-maintenance/role-manage-user" ? (
                  <RoleManageUser />
                ) : pathName === "/user-maintenance/user-management" ? (
                  <UserManagement />
                ) : pathName ===
                  "/user-maintenance/user-management/add-new-user" ? (
                  <UserManagementForm />
                  // ) : pathName ===
                  //   "/user-maintenance/user-management/edit-user" ? (
                  //   <UserManagementEditForm />
                ) : (
                  <Blank />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default Main;
