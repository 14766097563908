import axios from "axios";
import pathModule from "./../../utils/path/pathModule.json";

const free_delivery_url = process.env.REACT_APP_FREEDELIVERY_API_URL;
const xApiKeyFreedelivery = process.env.REACT_APP_FREEDELIVERY_API_KEY;

const contractCustomerPath = pathModule.free_delivery.contract_customer.path;
const returnWarehousePath = pathModule.free_delivery.return_warehouse.path;
const outOfDeliveryPath = pathModule.free_delivery.out_of_delivery.path;
const xApiKey = { "x-api-key": xApiKeyFreedelivery };

async function getOrderBySource(request) {
  const body = {
    request_data: {
      order_no: request.orderNum === undefined ? null : request.orderNum,
      order_date_start: request.startDateContact,
      order_date_end: request.endDateContact,
      order_backlog_start: request.productStartDate,
      order_backlog_end: request.productEndDate,
      order_telephone_no: request.tel === undefined ? null : request.tel,
      order_status:
        request.status === undefined || request.status === ""
          ? null
          : request.status,
      order_quentity_limit: null,
    },
  };
  try {
    const response = await axios.post(
      free_delivery_url + "/customerservice/freedelivery/customers/order",
      body,
      {headers:xApiKey}
    );
    if (response.data.response_code === 100) {
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function getOrderOutOfDelivery(request) {
  const body = {
    request_data: {
      order_no: request.orderNum === undefined ? null : request.orderNum,
      order_date_start: request.startDateContact,
      order_date_end: request.endDateContact,
      warehouse_id:
        request.warehouseId === undefined ? null : request.warehouseId,
      order_status:
        request.status === undefined || request.status === ""
          ? null
          : request.status,
    },
  };
  try {
    const response = await axios.post(
      free_delivery_url + "/customerservice/freedelivery/outofdelivery/order",
      body,
      {headers:xApiKey}
    );
    if (response.data.response_code === 100) {
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function getOrderReturnWarehouse(request) {
  const body = {
    request_data: {
      order_no: request.orderNum === undefined ? null : request.orderNum,
      order_date_start: request.startDateContact,
      order_date_end: request.endDateContact,
      order_backlog_start: request.productStartDate,
      order_backlog_end: request.productEndDate,
      order_telephone_no: request.tel === undefined ? null : request.tel,
      order_status:
        request.status === undefined || request.status === ""
          ? null
          : request.status,
    },
  };
  try {
    const response = await axios.post(
      free_delivery_url + "/customerservice/freedelivery/return/order",
      body,
      {headers:xApiKey}
    );
    if (response.data.response_code === 100) {
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function getOrderDetailById(orderNo) {
  try {
    const response = await axios.get(
      free_delivery_url +
        "/customerservice/freedelivery/customers/order?order_no=" +
        orderNo,
        {headers:xApiKey}
    );
    if (response.data.response_code === 100) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function getOrderHistoryById(orderNo) {
  const body = {
    request_data: {
      order_no: orderNo,
    },
  };

  const response = await axios.post(
    free_delivery_url +
      "/customerservice/freedelivery/customers/order/operation/history",
    body,
    {headers:xApiKey}
  );

  if (response.data.response_code === 100) {
    return response.data;
  } else {
    return [];
  }
}

async function getOrderOutsideDelivelyDetailById(orderNo) {
  try {
    const response = await axios.get(
      free_delivery_url +
        "/customerservice/freedelivery/outofdelivery/order?order_no=" +
        orderNo,
        {headers:xApiKey}
    );

    if (response.data.response_code === 100) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}
async function getOrderOutsideDelivelyHistoryById(orderNo) {
  const body = {
    request_data: {
      order_no: orderNo,
    },
  };

  const response = await axios.post(
    free_delivery_url +
      "/customerservice/freedelivery/outofdelivery/order/operation/history",
    body,
    {headers:xApiKey}
  );

  if (response.data.response_code === 100) {
    return response.data;
  } else {
    return [];
  }
}

async function getOrderReturnWarehouselyDetailById(orderNo) {
  try {
    const response = await axios.get(
      free_delivery_url +
        "/customerservice/freedelivery/return/order?order_no=" +
        orderNo,
        {headers:xApiKey}
    );

    if (response.data.response_code === 100) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}
async function getOrderReturnWarehouselyHistoryById(orderNo) {
  const body = {
    request_data: {
      order_no: orderNo,
    },
  };

  const response = await axios.post(
    free_delivery_url +
      "/customerservice/freedelivery/return/order/operation/history",
    body,
    {headers:xApiKey}
  );
  if (response.data.response_code === 100) {
    return response.data;
  } else {
    return [];
  }
}

async function getOrderAndHistory(orderType, orderNo) {
  let orderInfo;
  let orderHistory;
  switch (orderType) {
    case contractCustomerPath:
      orderInfo = await getOrderDetailById(orderNo);
      orderHistory = await getOrderHistoryById(orderNo);
      return {
        orderInfo: orderInfo,
        orderHistory: orderHistory,
      };
    case returnWarehousePath:
      orderInfo = await getOrderOutsideDelivelyDetailById(orderNo);
      orderHistory = await getOrderOutsideDelivelyHistoryById(orderNo);
      return {
        orderInfo: orderInfo,
        orderHistory: orderHistory,
      };
    case outOfDeliveryPath:
      orderInfo = await getOrderReturnWarehouselyDetailById(orderNo);
      orderHistory = await getOrderReturnWarehouselyHistoryById(orderNo);
      return {
        orderInfo: orderInfo,
        orderHistory: orderHistory,
      };

    default:
      return {
        orderInfo: {},
        orderHistory: {},
      };
  }
}

async function addOrderContactCustomerOperation(request_data) {
  const body = { request_data };

  try {
    const response = await axios.post(
      free_delivery_url +
        "/customerservice/freedelivery/customers/order/operation",
      body,
      {headers:xApiKey}
    );

    if (response.data.response_code === 100) {
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function addOrderOutOfDelivery(request_data) {
  const body = { request_data };

  try {
    const response = await axios.post(
      free_delivery_url +
        "/customerservice/freedelivery/outofdelivery/order/operation",
      body,
      {headers:xApiKey}
    );

    if (response.data.response_code === 100) {
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}
async function addOrderReturnWarehouse(request_data) {
  const body = { request_data };

  try {
    const response = await axios.post(
      free_delivery_url +
        "/customerservice/freedelivery/return/order/operation",
      body,
      {headers:xApiKey}
    );
    if (response.data.response_code === 100) {
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function addOrderOperation(orderType, request_data) {
  let response;
  switch (orderType) {
    case contractCustomerPath:
      response = addOrderContactCustomerOperation(request_data);
      return response;
    case returnWarehousePath:
      response = addOrderReturnWarehouse(request_data);
      return response;
    case outOfDeliveryPath:
      response = addOrderOutOfDelivery(request_data);
      return response;
    default:
      response = [];
      return response;
  }
}

async function updateOrderDetail() {}

const validateRequest = (request) => {
  let numStartDate = Number(request.productStartDate);
  let numEndDate = Number(request.productEndDate);
  if (numStartDate !== "" || numEndDate !== "") {
    if (numStartDate !== "") {
      if (numEndDate !== "") {
        if (numStartDate <= numEndDate) {
          return {
            error: false,
            message: "",
          };
        } else {
          return {
            error: true,
            message: "ตั้งแต่(วัน) ต้องมากกว่า ถึง",
          };
        }
      } else {
        return {
          error: true,
          message: "กรุณากรอก ถึง",
        };
      }
    } else {
      return {
        error: true,
        message: "กรุณากรอก ตั้งแต่(วัน)",
      };
    }
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

export {
  updateOrderDetail,
  getOrderBySource,
  getOrderOutOfDelivery,
  getOrderReturnWarehouse,
  getOrderDetailById,
  getOrderHistoryById,
  addOrderOperation,
  getOrderAndHistory,
  addOrderContactCustomerOperation,
  addOrderOutOfDelivery,
  addOrderReturnWarehouse,
  validateRequest,
};
