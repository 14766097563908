import React from "react";
import { Link } from "react-router-dom";
import { updateUser } from "../../../../service/userMaintenance/userManagement.service";

const EditModal = ({ operationName, enterusername, checked, active }) => {

    const updateEditUser = (operationName, enterusername, checked, active) => {
        let request =
        {
            "request_data": {
                "role_id": checked,
                "active": active,
                "operation_name": operationName
            }
        }
        updateUser(request, enterusername);
    }

    return (
        <>
            <div
                className="modal fade"
                id="staticBackdrop2"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="header">
                            <h4 className="title" id="staticBackdropLabel">
                                ยืนยันข้อมูล
                            </h4>
                        </div>
                        <div className="body">
                            <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
                        </div>
                        <div className="footer">
                            <Link to="/user-maintenance/user-management">
                                <button
                                    type="submit"
                                    className="resetbutton"
                                    data-bs-dismiss="modal"
                                    onClick={() => updateEditUser(operationName, enterusername, checked, active)}
                                >
                                    บันทึก
                                </button>
                            </Link>
                            <button
                                type="button"
                                className="backbutton"
                                data-bs-dismiss="modal"
                            >
                                แก้ไข
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditModal;