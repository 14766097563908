import React from "react";
import { getStatus } from "../../../../helper/freedeliveryHelper";

const ContactHistory = (props) => {
  const { contactHistoryData } = props;

  const status = (status) => {
    return getStatus(status);
  };

  return (
    <>
      <div className="contact-history">
        <div className="contact-history-title">
          <span>ประวัติการติดต่อลูกค้า</span>
        </div>
        <div className="table-responsive contact-history-height">
          <table className=" table-primary history-table">
            <thead className="table-header">
              <tr className="title-col free-delivery-fix-header">
                <th>วันที่ติดต่อ</th>
                <th>เวลาที่ติดต่อ</th>
                <th>Invoice</th>
                <th>สถานะการติดต่อ</th>
                <th>วันที่รับนัด</th>
                <th>เวลาที่รับนัด</th>
                <th>รายละเอียดลูกค้าที่ติดต่อ</th>
                <th>สถานะ (งาน)</th>
                <th>ผู้ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {contactHistoryData?.map((element, index) => {
                return (
                  <>
                    <tr className="detail-row" key={index}>
                      <td>{element.contact_customer_date}</td>
                      <td>{element.contact_customer_timestamp}</td>
                      <td>{element.invoice_no}</td>
                      <td>
                        {element.contact_customer_status === "Y"
                          ? "ติดต่อได้"
                          : element.contact_customer_status === "N"
                          ? "ติดต่อไม่ได้"
                          : element.contact_customer_status}
                      </td>
                      <td>{element.customer_acceptance_date}</td>
                      <td>{element.customer_acceptance_time}</td>
                      <td>{element.customer_contacted_detail}</td>
                      <td>
                        <span className="break-word">
                          {status(element.order_status)}
                        </span>
                      </td>
                      <td>{element.operator_name}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ContactHistory;
