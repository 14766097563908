import React, { useEffect, useState, useContext } from "react";
import TaxInvoiceForm from "../components/TaxInvoiceForm/TaxInvoiceForm";
import MenuContext from "../../../context/MenuContext";
import { getMenuByMenuId, getUserPermission } from "../../../service/customer-care.service";
import { Container, Row, Col } from "react-bootstrap";
import { getEtaxData } from "../../../service/etax/etax.service";
import { useLocation } from "react-router-dom";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";

const EtaxFormPage = () => {
    const { menu, setMenu, setUserRole, profileUser, setPermission } = useContext(MenuContext);

    const query = new URLSearchParams(useLocation().search);
    const external_orderno = query.get("external_orderno");

    const [etaxData, setEtaxData] = useState({});
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {

        if (menu.length === 0 || menu.menu_id !== "21") {
            if (profileUser) {
                getMenuByMenuId("21", profileUser.email).then((res) => {

                    setMenu(res?.menu_list[0]);
                    setUserRole(res?.role[0])
                });

            }
        }

    }, []);

    useEffect(async () => {
        const data = await getEtaxData(external_orderno)

        if (data) {
            if (data.action === "FULL_REQUESTABLE" || data.action === "CORRECTION") {
                setEtaxData(data)
            } else {
                if ((data.action == "CORR_NOT_REQUESTABLE" && data.status == "PENDING") && modalShow == false) {
                    let modal = document.getElementById("modal_button");
                    setModalShow(true);
                    modal.click();
                }
                if ((data.external_orderno == null || data.ordersource == null) && data.status == "REQUESTABLE" && modalShow == false) {
                    let modal = document.getElementById("modal_button");
                    setModalShow(true);
                    modal.click();
                }
            }
        }
    }, [])

    return (
        <Container>
            <div className="layout">
                <div className="row vh-100">
                    <Row>
                        <ResponsiveSideBar />
                        <Col md={9} className="content">
                            <TaxInvoiceForm
                                data={etaxData}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
};

export default EtaxFormPage;