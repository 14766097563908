import { numberWithCommas } from "../../../../../../helper/commonHelper";

const splitDateTime = (date) => {
  if (date !== undefined) {
    return (
      <>
        <p className="no-space">{date.split(" ")[0]}</p>
        <p className="no-space">{date.split(" ")[1]}</p>
      </>
    );
  }
  return <></>;
};

export const COLUMNS = [
  {
    Header: "ORD_NO",
    accessor: "order_no",
  },
  {
    Header: "ORD_DATE",
    accessor: "order_date",
    Cell: ({ row }) => splitDateTime(row.original.order_date),
  },
  {
    Header: "FIRSTNAME",
    accessor: "firstname",
  },
  {
    Header: "LASTNAME",
    accessor: "lastname",
  },
  {
    Header: "Phone_NO",
    accessor: "phone_no",
  },
  {
    Header: "ยกเลิกเปลี่ยนคืนสินค้า",
    accessor: "request_type",
  },
  {
    Header: "สาเหตุ",
    accessor: "reason",
  },
  {
    Header: "สถานะการโอน",
    accessor: "transfer_status",
  },
  {
    Header: "สถานะรายการ",
    accessor: "request_status",
  },
  {
    Header: "ช่องทางการคืนเงิน",
    accessor: "refund_channel",
  },
  {
    Header: "Summary(Baht)",
    accessor: "refund_total",
    Cell: ({ row }) => numberWithCommas(row.original.refund_total)
  },
  {
    Header: "CustomerRequest NO.",
    accessor: "customer_request_no",
  },
  {
    Header: "ผู้ดำเนินการ",
    accessor: "create_by",
  },
  {
    Header: "Request Date",
    accessor: "create_datetime",
    Cell: ({ row }) => splitDateTime(row.original.create_datetime),
  },
  {
    Header: "Update By",
    accessor: "update_by",
  },
  {
    Header: "Last Update",
    accessor: "update_datetime",
    Cell: ({ row }) => splitDateTime(row.original.update_datetime),
  },
  {
    Header: "Ordersource ID",
    accessor: "ordersource",
  },
  {
    Header: "Ordersource Name",
    accessor: "ordersource_name",
  },
];
