import "../../../../scss/userMaintenance/roleManagement/_table.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import MenuContex from "../../../../context/MenuContext.js";

const renderAuthorize = (authorizeList) => {
  if (authorizeList.length == 0) return "-";
  return authorizeList.reduce((sum, x) => `${sum}/${x}`, "").substring(1);
};

const MenuTd = ({ menuList, isMenu = true, type }) => {
  if (menuList.length == 0 || menuList == undefined) return <p>-</p>;
  return (
    <div>
      {menuList.map((menu) => (
        <div className={type === "authorize" ? "text-center" : "text-start"}>
          <b>
            <p
              style={{
                paddingLeft: 25,
              }}
            >{`${
              isMenu ? menu.menu_name : renderAuthorize(menu.authorize)
            }`}</p>
          </b>
          <SubMenuTd subMenuList={menu.menu_list} isMenu={isMenu} type={type} />
        </div>
      ))}
    </div>
  );
};

const SubMenuTd = ({ subMenuList, isMenu = true, type, level = 1 }) => {
  if (subMenuList.length === 0 || subMenuList === undefined) return <p>-</p>;
  return (
    <div className={type === "authorize" ? "text-center" : "text-start"}>
      {subMenuList.map((subMenu) => (
        <div>
          <p>
            {isMenu ? (
              <div className={`left-lv${level}`}>
                <li>{subMenu.menu_name}</li>
              </div>
            ) : (
              renderAuthorize(subMenu.authorize)
            )}
          </p>
          {subMenu.menu_list.length > 0 && (
            <SubMenuTd
              subMenuList={subMenu.menu_list}
              isMenu={isMenu}
              type={type}
              level={level + 1}
            />
          )}
        </div>
      ))}
    </div>
  );
};

function RoleListTable({ roles }) {
  const { permission } = useContext(MenuContex);

  return (
    <div className="mt-3 role-management-table">
      {roles?.length !== 0 && (
        <table className="role-management-thead">
          <thead>
            <tr>
              <th className="first-col-th">Status</th>
              <th>Role</th>
              <th>Menu </th>
              <th>Authorize</th>
              <th className="pl-5">Edit</th>
              <th className="last-col-th">User</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role, index) => (
              <tr className={`${index % 2 == 1 ? "striped" : ""} `}>
                {role.status == "Active" && (
                  <td className="text-success first-col">{role.status}</td>
                )}
                {role.status != "Active" && (
                  <td className="text-danger first-col">{role.status}</td>
                )}
                <td>{role.role}</td>
                <td>
                  <MenuTd menuList={role.menu_list} isMenu={true} />
                </td>
                <td>
                  <MenuTd
                    menuList={role.menu_list}
                    isMenu={false}
                    type="authorize"
                  />
                </td>
                <td className="pl-5">
                  {permission.some((res) => res === "update") ? (
                    <Link
                      to={`/user-maintenance/role-edit-role?id=${role.role_id}&roleName=${role.role}&status=${role.status}`}
                    >
                      <img
                        className="edit-icon"
                        src="/assets/userMaintenance/edit.svg"
                        alt="edit"
                      />
                    </Link>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="last-col">
                  {permission.some((res) => res === "create") ? (
                    <Link
                      to={`/user-maintenance/role-manage-user?roleId=${role.role_id}&roleName=${role.role}`}
                    >
                      <img
                        className="user-icon"
                        src="/assets/userMaintenance/user.svg"
                        alt="user"
                      />
                    </Link>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {roles?.length === 0 && (
        <>
          <img
            src={process.env.PUBLIC_URL + "/assets/pictures/data-not-found.png"}
            className="rounded mx-auto d-block"
            id="noOrderImg"
            alt="..."
          ></img>
          <h2 className="text-not-found">ไม่พบข้อมูล</h2>
        </>
      )}
    </div>
  );
}

export default RoleListTable;
