import RoleEditRoleMain from "../components/roleManagement/RoleEditRoleMain";
function RoleEditRole() {
  return (
    <div>
      <RoleEditRoleMain />
    </div>
  );
}

export default RoleEditRole;
