import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Container, Image } from "react-bootstrap";
import {
  login,
  isAuthenticated
} from "../../../service/auth.service";

const LoginPortal = () => {
  const history = useHistory();

  if (isAuthenticated()) {
    history.replace("/")
  }



  return (
    <>
      <Container>
        <div className="background">
          <Image
            src={process.env.PUBLIC_URL + "/assets/pictures/background.svg"}
            rounded
            id="bg"
          />
          <Image
            src={
              process.env.PUBLIC_URL +
              "/assets/pictures/undraw_contact_us_15o2.svg"
            }
            rounded
            id="contact"
          />
          <p id="customer">Customer SERVICE</p>
          <p id="Portal">Portal</p>
          <button id="btn-login" onClick={login}>เข้าสู่ระบบ</button>
        </div>
      </Container>
    </>
  );
};

export default LoginPortal;
