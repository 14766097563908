import RoleAddRoleForm from "./RoleAddRoleForm";
import RoleHeader from "./RoleHeader";
import { useEffect, useState } from "react";
import {
  menuToAuthorizeList,
  menuToMenuStatus,
  addAuthorizeFromAPIToAuthorizeList,
  menuAndAuthorizeToMenu,
  menuAndAuthorizeToMenuStatus,
  menuToTreeMenu,
  menuToAdditionHead,
} from "../../../../helper/userMaintenanceHelper";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import {
  getMenu,
  postRole,
  getRoleById,
  putRole,
} from "../../../../service/userMaintenance/roleManagement.service";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import "../../../../scss/userMaintenance/roleManagement/_roleAddRole.scss";
function RoleEditRoleMain() {
  let history = useHistory();
  const goMain = () => {
    history.push("/user-maintenance/role-management");
  };

  const errorCallback = () => {
    setIsRoleIsError(true);
  };

  const query = new URLSearchParams(useLocation().search);
  const roleId = query.get("id");
  const oldRoleName = query.get("roleName");
  const oldStatus = query.get("status");
  const [roleName, setRoleName] = useState(oldRoleName);
  const [status, setStatus] = useState(oldStatus);
  const [menu, setMenu] = useState([]);
  const [menuStatus, setMenuStatus] = useState([]);
  const [authorize, setAuthorize] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const [isRoleIsError, setIsRoleIsError] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const handleClose = () => setIsModal(false);
  const handleShow = () => setIsModal(true);

  const putRoleAPI = async () => {
    const authorizeFilter = authorize.filter((s) => s.authorize.length > 0);
    const menuListWithHead = [
      ...menuToAdditionHead(menu, authorizeFilter),
      ...authorizeFilter,
    ];
    const body = {
      request_data: {
        role_id: roleId,
        role: roleName,
        menu_list: menuListWithHead,
        status: status,
      },
    };
    const response = await putRole(body, goMain, errorCallback);
  };

  useEffect(() => {
    setIsLoad(true);
    const fetchData = async () => {
      const menuFromAPI = await getMenu();
      const menuData = menuFromAPI.data.response_data.menu_list;

      setMenu(menuData);
      setIsLoad(false);
    };
    fetchData();
  }, []);
  useEffect(() => {
    setAuthorize(menuToAuthorizeList(menu));
    const fetchAuthorize = async () => {
      const authorizeFromAPI = await getRoleById(roleId);
      const authorizeData = authorizeFromAPI.data.response_data.menu_list;
      const _menuStatus = menuAndAuthorizeToMenuStatus(menu, authorizeData);
      setMenuStatus(_menuStatus);
      setAuthorize(menuAndAuthorizeToMenu(menu, authorizeData));
    };
    fetchAuthorize();
  }, [menu]);

  useEffect(() => {
    // menuStatus.forEach((e) => {
    //   if (document.getElementById(e.menu_id))
    //     document.getElementById(e.menu_id).checked = e.disable;
    // });
    // setAuthorize(menuAndAuthorizeToMenu(menu, authorize));
  }, [menuStatus]);

  return (
    <div className="container">
      <div className="roleAddRole">
        <RoleHeader text="Edit Role" />
        {!isLoad && (
          <RoleAddRoleForm
            mode={"edit"}
            menu={menu}
            authorize={authorize}
            setAuthorize={setAuthorize}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
            roleName={roleName}
            setRoleName={setRoleName}
            status={status}
            setStatus={setStatus}
            postRoleAPI={putRoleAPI}
            goMain={goMain}
            isRoleIsError={isRoleIsError}
            setIsRoleIsError={setIsRoleIsError}
            isModal={isModal}
            setIsModal={setIsModal}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        )}
        <LoadingSpinner isLoading={isLoad} />
      </div>
    </div>
  );
}

export default RoleEditRoleMain;
