import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const xApiKey = process.env.REACT_APP_USER_MANAGEMENT_API_KEY;
const menuUrl = process.env.REACT_APP_MENU_URL;
const menuAuthorize = "Bearer xxxxxx";

async function postMenuListData(username, userEmail) {
  const body = {
    main_username: username,
    username: userEmail,
  };
  const response = await axios.post(
    `${menuUrl}/customerservice/management/user/main/menulist`,
    body,
    {
      headers: {
        "x-api-key": xApiKey,
        Authorization: menuAuthorize,
      },
    }
  );
  if (response.data.response_code === 100) {
    return response.data;
  } else {
    return [];
  }
}

async function getMenuByMenuId(id, username) {
  const response = await axios.get(
    apiUrl + "/user/" + username + "/menu/" + id,
    {
      headers: {
        "x-api-key": xApiKey,
      },
    }
  );

  if (response.data.response_code === 100) {
    response.data.response_data.role[0] = response.data.response_data.role
      .map((role_name) => `${role_name}`)
      .join(", ");
    return response.data.response_data;
  } else {
    return {};
  }
}

async function getUserPermission(id, username) {
  const response = await axios
    .get(apiUrl + "/user/" + username + "/menu/" + id + "/permission", {
      headers: {
        "x-api-key": xApiKey,
      },
    })
    .catch((e) => {
      console.log("err", e);
      return [];
    });

  if (response.data.response_code === 100) {
    return response.data.response_data.permission;
  } else {
    return [];
  }
}

export { postMenuListData, getMenuByMenuId, getUserPermission };
