import axios from "axios";

const USER_MANAGETMENT_URL = process.env.REACT_APP_API_URL;
const X_API_KEY = process.env.REACT_APP_ROLE_MANAGEMENT_X_API_KEY;

const getAllUsers = async () => {
    const data = await axios
        .get(`${USER_MANAGETMENT_URL}/all-users`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic Y2FsbGNlbnRlcjo6ZFBFWk83SGxVZVVoUyEzckxsIw==",
                "x-api-key": X_API_KEY,
            },
        })
        .then((res) => {
            return res.data.response_data;
        })
        .catch((e) => {
            console.log(e);
            return [];
        });
    return data;
};

const getRole_id = async () => {
    const data = await axios
        .get(`${USER_MANAGETMENT_URL}/all-roles`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic Y2FsbGNlbnRlcjo6ZFBFWk83SGxVZVVoUyEzckxsIw==",
                "x-api-key": X_API_KEY,
            },
        })
        .then((res) => {
            return res.data.response_data;
        })
        .catch((e) => {
            console.log(e);
            return [];
        });
    return data;
};


const getUsers = async (req) => {
    const data = await axios
        .get(`${USER_MANAGETMENT_URL}/users?`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic Y2FsbGNlbnRlcjo6ZFBFWk83SGxVZVVoUyEzckxsIw==",
                "x-api-key": X_API_KEY,
            },
            params: {
                username: req.username,
                role_id: req.role_id
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.log(e);
            return [];
        });
    return data;
};

const getUserByID = async (req) => {
    let url = `${USER_MANAGETMENT_URL}/user?username=${req.username}`

    const data = await axios
        .get(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic Y2FsbGNlbnRlcjo6ZFBFWk83SGxVZVVoUyEzckxsIw==",
                "x-api-key": X_API_KEY,
            },
        })
        .then((res) => {
            return res.data.response_data;
        })
        .catch((e) => {
            console.log(e);
            return [];
        });
    return data;
};

const createUser = async (request) => {
    const body = request;
    try {
        const response = await axios.post(`${USER_MANAGETMENT_URL}/user/save`, body, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic Y2FsbGNlbnRlcjo6ZFBFWk83SGxVZVVoUyEzckxsIw==",
                "x-api-key": X_API_KEY,
            },
        })
        if (response.data.response_code === 100) {
            return response.data;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return [];
    }
};

const updateUser = async (request, username) => {
    const body = request;
    try {
        const response = await axios.put(`${USER_MANAGETMENT_URL}/user/${username}`, body, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic Y2FsbGNlbnRlcjo6ZFBFWk83SGxVZVVoUyEzckxsIw==",
                "x-api-key": X_API_KEY,
            },
        })
        if (response.data.response_code === 100) {
            return response.data;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
        return [];
    }
};

export { getAllUsers, getRole_id, getUsers, getUserByID, createUser, updateUser };
