import React from 'react'

const CustomerInfo = (props) => {
    const { customerInfoData } = props;
    return (
        <>
            <div className="card-title" id="customerinfo-card-title">
                <div className="card-body" id="customerinfo-card-body">
                    ข้อมูลลูกค้า
                </div>
            </div>
            <div className='row-1'>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">ชื่อผู้สั่งซื้อ</label>
                    <input className="form-control" value={customerInfoData.order_customer_name} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">เบอร์โทรผู้สั่ง</label>
                    <input className="form-control" value={customerInfoData.order_telephone_no} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
            </div>
            <div className='row-1'>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">ชื่อผู้รับ</label>
                    <input className="form-control" value={customerInfoData.reciever_customer_name} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">เบอร์โทรผู้รับ</label>
                    <input className="form-control" value={customerInfoData.reciever_telephone_no} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
            </div>
            <div className='row-1'>
                <div className='col-md-5'>
                    <label for="exampleFormControlInput1" className="form-label">ที่อยู่ผู้รับ</label>
                    <input className="form-control address" value={customerInfoData.reciever_address} type="text" aria-label="Disabled input example" disabled readonly />
                </div>
                <div className='col-md-5'>
                </div>
            </div>
        </>
    );
}

export default CustomerInfo;