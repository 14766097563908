import React, { useEffect } from "react";
import queryString from "query-string";
import {
    getToken,
    setAccessToken,
    setIdToken,
    setRefreshToken
} from "../../../service/auth.service"

const Oauth2Callback = async ({ location }) => {
    const { code } = queryString.parse(location.search);
    if (code) {
        const tokenJson = await getToken(code);
        if (tokenJson.error) {
            console.log("error", tokenJson);
        } else {

            setAccessToken(tokenJson);
            setIdToken(tokenJson);
            setRefreshToken(tokenJson);
            window.location.href = "/";
        }
    }
    else if (!code) {
        window.location.href = "/login";
    }
    return (
        <>
        </>
    );
}

export default Oauth2Callback;