import React, { useState, useEffect } from 'react';
import './OtpModal.scss';

const Timer = (props) => {
    const { initialMinute = 0, initialSeconds = 0, setClick } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 1) {
                setClick(true);
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div>
            {minutes === 0 && seconds === 0
                ? null
                : <h3 className='seconds-otp-countdown'>{seconds} วินาที</h3>
            }
        </div>
    )
}

export default Timer;