import { useEffect, useContext } from "react";
import {
  addAuthorizeToAuthorizeListById,
  deleteAllAuthorizeToAuthorzeListById,
  deleteAuthorizeToAuthorizeListById,
  isAuthorizeByMenuId,
  updateMenuStatusByMenuId,
  getMenuStatusById,
  addAuthorizesToAuthorizeListById,
  getMenuStatusAndAuthorizeListWhenDisableHeader,
} from "../../../../helper/userMaintenanceHelper";
import RoleModal from "./RoleModal";
import MenuContex from "../../../../context/MenuContext.js";
const MenuRow = ({
  menu,
  authorize,
  setAuthorize,
  setMenuStatus,
  menuStatus,
}) => {
  const checkBoxOnClick = (e, menuId, label) => {
    if (e.target.checked === true) {
      setAuthorize(addAuthorizeToAuthorizeListById(authorize, menuId, label));
    }
    if (e.target.checked === false) {
      setAuthorize(
        deleteAuthorizeToAuthorizeListById(authorize, menuId, label)
      );
    }
  };
  const disableBoxOnClick = (e, menuId) => {
    if (e.target.checked === true)
      setMenuStatus(
        updateMenuStatusByMenuId(menuStatus, menuId, e.target.checked)
      );

    if (e.target.checked === false) {
      const { _MenuStatus, _Authorize } =
        getMenuStatusAndAuthorizeListWhenDisableHeader(
          menu,
          menuStatus,
          authorize
        );
      setAuthorize(_Authorize);
      setMenuStatus(_MenuStatus);
    }
  };

  useEffect(() => {}, [authorize]);

  if (menu.authorize == undefined) return <div></div>;
  return (
    <div className="row mx-1">
      <div class="form-check-user-management col-md-6">
        <input
          id={menu.menu_id}
          class="form-check-input"
          type="checkbox"
          onClick={(e) => {
            disableBoxOnClick(e, menu.menu_id);
          }}
          checked={
            menuStatus.filter((m) => m.menu_id === menu.menu_id)[0]?.disable
          }
        />
        <label class="form-check-label">{menu.menu_name}</label>
      </div>

      <div class="form-check-user-management col-md-6">
        {menu.authorize.map((label) => (
          <span>
            <input
              class="form-check-input"
              type="checkbox"
              onClick={(e) => {
                checkBoxOnClick(e, menu.menu_id, label);
              }}
              checked={isAuthorizeByMenuId(authorize, menu.menu_id, label)}
            />
            <label class="form-check-label">{label}</label>
          </span>
        ))}
      </div>
    </div>
  );
};

const SubMenuRow = ({
  level = 1,
  subMenu,
  authorize,
  setAuthorize,
  menuStatus,
  setMenuStatus,
}) => {
  const disableBoxOnClick = (e, menuId) => {
    setMenuStatus(
      updateMenuStatusByMenuId(menuStatus, menuId, e.target.checked)
    );

    if (e.target.checked == true) {
      setAuthorize(
        addAuthorizesToAuthorizeListById(authorize, menuId, subMenu.authorize)
      );
    }

    if (e.target.checked == false) {
      const { _MenuStatus, _Authorize } =
        getMenuStatusAndAuthorizeListWhenDisableHeader(
          subMenu,
          menuStatus,
          authorize
        );
      setAuthorize(_Authorize);
      setMenuStatus(_MenuStatus);
    }
  };
  const checkBoxOnClick = (e, menuId, label) => {
    if (e.target.checked == true) {
      setAuthorize(addAuthorizeToAuthorizeListById(authorize, menuId, label));

      setMenuStatus(updateMenuStatusByMenuId(menuStatus, menuId, true));
    }
    if (e.target.checked == false) {
      if (
        deleteAuthorizeToAuthorizeListById(authorize, menuId, label).filter(
          (m) => m.menu_id == menuId
        )[0].authorize?.length == 0
      ) {
        setMenuStatus(updateMenuStatusByMenuId(menuStatus, menuId, false));
      }
      setAuthorize(
        deleteAuthorizeToAuthorizeListById(authorize, menuId, label)
      );
    }
  };

  useEffect(() => {}, [authorize]);

  if (subMenu.authorize == undefined) return <div></div>;
  return (
    <div className="row mx-1">
      <div class="form-check-user-management col-md-6">
        <input
          class={`form-check-input left-lv${level}`}
          type="checkbox"
          id={subMenu.menu_id}
          checked={
            menuStatus.filter((m) => m.menu_id === subMenu.menu_id)[0]?.disable
          }
          onClick={(e) => {
            disableBoxOnClick(e, subMenu.menu_id);
          }}
        />
        <label class="form-check-label">{subMenu.menu_name}</label>
      </div>

      <div class="form-check-user-management col-md-6">
        {subMenu.authorize.map((label) => (
          <span style={{ whiteSpace: "nowrap" }}>
            <input
              class="form-check-input "
              type="checkbox"
              onClick={(e) => {
                checkBoxOnClick(e, subMenu.menu_id, label);
              }}
              checked={isAuthorizeByMenuId(authorize, subMenu.menu_id, label)}
            />

            <label class="form-check-label">{label}</label>
          </span>
        ))}
      </div>
    </div>
  );
};

function RoleAddRoleForm({
  mode,
  menu,
  authorize,
  setAuthorize,
  menuStatus,
  setMenuStatus,
  roleName,
  setRoleName,
  status,
  setStatus,
  postRoleAPI,
  goMain,
  isRoleIsError,
  setIsRoleIsError,
  isModal,
  setIsModal,
  handleClose,
  handleShow,
}) {
  const { permission } = useContext(MenuContex);
  const isAuthorizeIsHasValue = (authorize) => {
    if (authorize === undefined) return true;
    return authorize.reduce((sum, x) => sum || x.authorize?.length > 0, false);
  };

  return (
    <div className="roleAddRole-form w-100 p-5">
      <div class="mb-3">
        <label class="form-label ">Role Name</label>
        <input
          disabled={mode == "edit" ? true : false}
          class="form-control non-border"
          value={roleName}
          maxLength={20}
          onChange={(e) => {
            setRoleName(e.target.value);
          }}
        />
      </div>
      <div className="role-menu-usermanagement">
        <div>
          <label>Menu</label>
        </div>
        <div className="role-label row mx-1">
          <div className="col-md-6">
            <label className="black">Role</label>
          </div>
          <div className="col-md-6">
            <label className="black">Authorize</label>
          </div>
        </div>
        {menu.map((m) => (
          <div>
            <MenuRow
              menu={m}
              authorize={authorize}
              setAuthorize={setAuthorize}
              setMenuStatus={setMenuStatus}
              menuStatus={menuStatus}
            />
            {getMenuStatusById(menuStatus, m.menu_id) &&
              m.menu_list.map((subMenu) => (
                <div>
                  <SubMenuRow
                    subMenu={subMenu}
                    authorize={authorize}
                    setAuthorize={setAuthorize}
                    menuStatus={menuStatus}
                    setMenuStatus={setMenuStatus}
                  />
                  {getMenuStatusById(menuStatus, subMenu.menu_id) &&
                    subMenu.menu_list.map((subMenuLevel2) => (
                      <div>
                        <SubMenuRow
                          level={2}
                          subMenu={subMenuLevel2}
                          authorize={authorize}
                          setAuthorize={setAuthorize}
                          menuStatus={menuStatus}
                          setMenuStatus={setMenuStatus}
                        />

                        {getMenuStatusById(menuStatus, subMenuLevel2.menu_id) &&
                          subMenuLevel2.menu_list.map((subMenuLevel3) => (
                            <div>
                              <SubMenuRow
                                level={3}
                                subMenu={subMenuLevel3}
                                authorize={authorize}
                                setAuthorize={setAuthorize}
                                menuStatus={menuStatus}
                                setMenuStatus={setMenuStatus}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="mb-3 col-md-6">
        <label className="form-label">Status</label>
        <select
          className="form-select "
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      </div>
      <div className="text-center">
        <button onClick={goMain} className="btn btn-danger mx-5">
          ยกเลิก
        </button>
        <button
          className="btn btn-primary mx-5"
          onClick={handleShow}
          disabled={
            roleName.length === 0 ||
            !isAuthorizeIsHasValue(authorize) ||
            (!permission.some((res) => res === "update") && mode === "edit") ||
            (!permission.some((res) => res === "create") && mode === "add")
          }
        >
          บันทึก
        </button>
      </div>
      <RoleModal
        submitFunction={() => postRoleAPI()}
        isModal={isModal}
        setIsModal={setIsModal}
        handleClose={handleClose}
        isRoleIsError={isRoleIsError}
        setIsRoleIsError={setIsRoleIsError}
      />
    </div>
  );
}

export default RoleAddRoleForm;
