import { Col } from "react-bootstrap";
import SideBar from "./SideBar";

const ResponsiveSideBar = () => {
    return (
        <>
            {
                window.innerWidth > 768 ? (
                    <Col md={3} className="sidebar">
                        <SideBar />
                    </Col>
                ) : (
                    <div className="mobile-sidebar">
                        <SideBar />
                    </div>
                )
            }
        </>
    )
}

export default ResponsiveSideBar;