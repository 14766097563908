import PreRequestTable from "../common/PreRequestTable";
import React, { useContext, useEffect, useState } from "react";
import SearchPreRequest from "../common/SearchPreRequest";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import {
  getPreRequests1,
  uploadFile,
} from "../../../../service/ecn/pre-request.service";
import { paginate } from "../../../../utils/paginate";
import Pagination from "../../../common/components/Pagination";
import { isEmpty } from "lodash";
import AlertComponent from "../../../common/components/Alert";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";

const PreRequest = () => {
  const [isShowData, setIsShowData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  // const [modalUpload, setModalUpload] = useState(true);
  const [modalCancel, setModalCancel] = useState(true);
  const [isAlertName, setIsAlertName] = useState("");
  const [responseCode, setResponseCode] = useState();
  const [isHandleAlertClick, setIsHandleAlertClick] = useState(false);
  const { permission } = useContext(MenuContext);
  const [importResponseCode, setImportResponseCode] = useState("");
  const [importResponseData, setImportResponseData] = useState("");

  const handleSearchData = (responseData) => {
    let Data = responseData.response_data;
    setIsLoading(true);
    if (responseData === undefined) {
      setResponseCode(responseData);
      setAlertHandle();
    } else {
      if (responseData.response_code === 100) {
        setData(Data);
        setIsLoading(false);
        setIsShowData(true);
      } else {
        setIsAlertName("error-nodata");
        setResponseCode(responseData.response_code);
        setAlertHandle();
        setIsShowData(false);
      }
    }
  };

  const setAlertHandle = () => {
    setTimeout(() => {
      setIsHandleAlertClick(true);
    }, 5000);
    setIsHandleAlertClick(false);
  };

  const handleAlertClick = () => {
    setIsHandleAlertClick(true);
  };

  useEffect(() => {
    setIsLoading(true);
    getPreRequests1("").then((res) => {
      if (!isEmpty(res)) {
        if (res.response_code === 100) {
          setData(res.response_data);
          setIsLoading(false);
          setIsShowData(true);
        } else {
          setIsAlertName("error-nodata");
          setResponseCode(res.response_code);
          setAlertHandle();
          setIsShowData(false);
          setIsLoading(false);
        }
      } else {
        setIsShowData(false);
        setIsLoading(false);
      }
    });
  }, []);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  const handleChangePageSize = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginatedData = paginate(data, currentPage, pageSize);
  async function handleFileChange(e) {
    // setSelectedFile(e.target.files[0]);
    setIsLoading(true);
    setModalCancel(true);
    let modal = document.getElementById("modal_button");
   await uploadFile(e.target.files[0]).then((res) => {
      if(!isEmpty(res)){
      if (res.response_code === 100) {
        setImportResponseCode(res.response_code);
        setImportResponseData(res.response_data);
        modal.click();
      } else {
        setImportResponseCode(res.response_code);
        setImportResponseData(res.response_data.message);
        modal.click();
      }
      setIsLoading(false);
      // setModalUpload(false);
    }else{
      setImportResponseCode("");
      setImportResponseData("");
      setIsLoading(false);
  }
    });
  }
  const reload = async () => {
    setIsLoading(true);
    await getPreRequests1("").then((res) => {
      if (!isEmpty(res)) {
        if (res.response_code === 100) {
          setData(res.response_data);
          setIsLoading(false);
          setIsShowData(true);
        } else {
          setIsAlertName("error-nodata");
          setResponseCode(res.response_code);
          setAlertHandle();
          setIsShowData(false);
          setIsLoading(false);
        }
      } else {
        setIsShowData(false);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="container">
        <AlertComponent
          alertName={isAlertName}
          responseCode={responseCode}
          isHandleAlertClick={isHandleAlertClick}
          handleAlertClick={handleAlertClick}
        />
        <div className="ecn-prerequest">
          <h1 className="ecn-prerequest-header">Pre-request (auto cancel)</h1>
          <SearchPreRequest
            setData={handleSearchData}
            setIsLoading={setIsLoading}
          />
          <h2 className="mt-4 mb-4">
            <input
              type="file"
              id="import-button"
              onChange={(e) => handleFileChange(e)}
              disabled={!permission.some((res) => res === "create")}
            />
            <label className="import-logo" htmlFor="import-button" disabled={!permission.some((res) => res === "create")}>
              <img src="/assets/ecn/import-icon.svg" />
              <span>นำเข้าข้อมูล</span>
            </label>
          </h2>
          <div className="row">
            <LoadingSpinner isLoading={isLoading} />
            <div className="col mt-4">
              {!isShowData && !isLoading && (
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/pictures/data-not-found.png"
                  }
                  className="rounded mx-auto d-block"
                  id="noOrderImg"
                  alt="..."
                ></img>
              )}
              {isShowData && !isLoading && permission?.length !== 0 &&(
                <>
                  <PreRequestTable
                    data={paginatedData}
                    permission={permission}
                  />
                  <Pagination
                    itemsCount={data.length}
                    pageSize={pageSize}
                    onPageSizeChange={handleChangePageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <button
          id="modal_button"
          className="disable"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        ></button>
        <div className={modalCancel ? "" : "disable"}>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="header">
                  <h4 className="title" id="staticBackdropLabel">
                    อัพโหลด{importResponseCode === 100 ? "สำเร็จ" : "ล้มเหลว"}
                  </h4>
                </div>
                <div className="body">
                  <h5>{importResponseData}</h5>
                </div>
                <div className="footer">
                  <button
                    type="button"
                    className="backbutton"
                    data-bs-dismiss="modal"
                    onClick={reload}
                  >
                    ตกลง
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PermissionModal
          permission={permission}
          backPage={"/ecn"}
        />
      </div>
    </>
  );
};

export default PreRequest;
