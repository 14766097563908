import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createUser } from "../../../../service/userMaintenance/userManagement.service";

const Modal = ({ operationName, enterUsername, checked, active }) => {

  const [successAlert, setSuccessAlert] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);

  const createNewUser = async (operationName, enterUsername, checked, active) => {
    let request =
    {
      "request_data": {
        "username": enterUsername,
        "role_id": checked,
        "active": active,
        "operation_name": operationName
      }
    }
    const req = await createUser(request);

    if (req.response_code === 100) {
      let modal = document.getElementById("success_alert");
      setSuccessAlert(true);
      modal.click();
    }
    if (req.response_code === 202) {
      let modal = document.getElementById("modal_alert_duplicateUser");
      setModalAlert(true);
      modal.click();
    }
    if (req.response_code === 301) {
      let modal = document.getElementById("modal_alert_userNotFound");
      setModalAlert(true);
      modal.click();
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <h4 className="title" id="staticBackdropLabel">
                ยืนยันข้อมูล
              </h4>
            </div>
            <div className="body">
              <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
            </div>
            <div className="footer">
              <button
                type="submit"
                className="resetbutton"
                data-bs-dismiss="modal"
                onClick={() => createNewUser(operationName, enterUsername, checked, active)}
              >
                บันทึก
              </button>
              <button
                type="button"
                className="backbutton"
                data-bs-dismiss="modal"
              >
                แก้ไข
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        id="modal_alert_duplicateUser"
        className="disable"
        data-bs-toggle="modal"
        data-bs-target="#modalAlertDuplicateUser"
      ></button>
      <div
        className="modal fade"
        id="modalAlertDuplicateUser"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <h4 className="title" id="staticBackdropLabel">
                ไม่สามารถ Add User นี้ได้
              </h4>
            </div>
            <div className="body">
              <h5>เนื่องจากในระบบมี email นี้อยู่แล้ว</h5>
              <h5>กรุณากรอกข้อมูลใหม่</h5>
            </div>
            <div className="footer">
              <button
                type="button"
                className="backbutton"
                data-bs-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        id="modal_alert_userNotFound"
        className="disable"
        data-bs-toggle="modal"
        data-bs-target="#modalAlertUserNotFound"
      ></button>
      <div
        className="modal fade"
        id="modalAlertUserNotFound"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="userNotFound"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <h4 className="title" id="userNotFound">
                ไม่สามารถ Add User นี้ได้
              </h4>
            </div>
            <div className="body">
              <h5>เนื่องจากระบบไม่มี User {enterUsername}</h5>
              <h5>กรุณากรอกข้อมูลใหม่</h5>
            </div>
            <div className="footer">
              <button
                type="button"
                className="backbutton"
                data-bs-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        id="success_alert"
        className="disable"
        data-bs-toggle="modal"
        data-bs-target="#sucessAlert"
      ></button>
      <div
        className="modal fade"
        id="sucessAlert"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="BackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <h4 className="title" id="BackdropLabel">
                Add New User สำเร็จ
              </h4>
            </div>
            <div className="body">
              <h5>Add User เข้าสู่ระบบเรียบร้อยแล้ว</h5>
            </div>
            <div className="footer">
              <Link to="/user-maintenance/user-management">
                <button
                  type="button"
                  className="backbutton"
                  data-bs-dismiss="modal"
                >
                  ตกลง
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
