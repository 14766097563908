import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { validateRequest } from "../../../../service/freedelivery/freedelivery.service";
import thai from "../../../../utils/Calendar/thai";
import thai_th from "../../../../utils/Calendar/thai_th";
import { Button, Modal } from "react-bootstrap";
import MenuContext from "../../../../context/MenuContext";
import StatusList from "./Dropdown/StatusList";

const Search = (props) => {
  const { handleSearchData, setSearchDataObj, setIsLoading, backlog, orderPhoneNumber } =
    props;
  const { permission } = useContext(MenuContext);
  const [searchData, setSearchData] = useState({});
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [startDateContact, setStartDateContact] = useState(
    new DateObject().subtract(3, "month")
  );

  const [endDateContact, setEndDateContact] = useState(new DateObject());
  const [defaultSearch, setDefaultSearch] = useState({
    startDateContact: new DateObject()
      .subtract(3, "month")
      .add(543, "year")
      .format("DD/MM/YY"),
    endDateContact: new DateObject().add(543, "year").format("DD/MM/YY"),
  });
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const handleClose = () => setShowAlert(false);
  const handleShow = () => setShowAlert(true);
  const handleDateData = (e, name) => {
    if (e) {
      e = e.format("DD/MM/YY");
      setSearchData((prev) => ({
        ...prev,
        [name]: e
      }));
    }
  };

  const handleChange = (e) => {
    setSearchData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (permission.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 300)
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        handleDateData(startDateContact, "startDateContact");
        handleDateData(endDateContact, "endDateContact");
        setSearchData((prev) => ({
          ...prev,
          productStartDate: "",
          productEndDate: "",
        }));
        handleSearchData(defaultSearch);
      }, 300);
    }
  }, []);

  useEffect(() => {
    setSearchDataObj(searchData);
    if (
      Object.keys(searchData).length > 4 ||
      searchData.productStartDate != "" ||
      searchData.productEndDate != ""
    ) {
      setIsDisableBtn(false);
    } else {
      setIsDisableBtn(true);
    }
    if (!permission.some((res) => res === "search") === true) {
      setIsDisableBtn(true);
    }
  }, [searchData]);

  const validate = (searchData) => {
    let vatedateCheck = validateRequest(searchData);
    if (vatedateCheck.error) {
      setMessageAlert(vatedateCheck.message);
      handleShow();
    } else {
      setMessageAlert("");
      handleSearchData();
    }
  };

  return (
    <div className="search">
      <div className="row align-items-center mt-3 g-1">
        <label className="col-2 white-space-right-5 width-mobile">เลขที่ออเดอร์ :</label>
        <div className="col-2 mobile-view">
          <input
            className="form-control"
            name="orderNum"
            type="text"
            value={searchData.orderNum}
            onChange={(e) => handleChange(e)}
            min="1"
          />
        </div>
        <label className="col-1 white-space-right-5 width-mobile">วันที่สั่งซื้อ :</label>
        <div className="col-3 mobile-view" id="datePicker">
          <DatePicker
            name="startDateContact"
            value={startDateContact}
            onChange={(e) => handleDateData(e, "startDateContact")}
            calendar={thai}
            locale={thai_th}
            format="DD/MM/YY"
            render={<InputIcon />}
          ></DatePicker>
        </div>
        <label className="col-1 text-center width-mobile" id="fromDate">
          ถึง
        </label>
        <div className="col-3 mobile-view" id="datePicker">
          <DatePicker
            name="endDateContact"
            value={endDateContact}
            onChange={(e) => handleDateData(e, "endDateContact")}
            calendar={thai}
            locale={thai_th}
            format="DD/MM/YY"
            minDate={startDateContact}
            render={<InputIcon />}
          ></DatePicker>
        </div>
      </div>

      {backlog && (
        <div className="row align-items-center mt-3 g-1 none-arrow">
          <label className="col-4 white-space-right-5 width-mobile-day">
            จำนวนวันที่สินค้าค้างที่สาขา:
          </label>
          <label className="col-1 white-space-right-5">ตั้งแต่(วัน)</label>
          <div className="col-3 mobile-view">
            <input
              className="form-control"
              name="productStartDate"
              type="number"
              min="1"
              max="9999"
              value={searchData.productStartDate}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <label className="col-1 text-center width-mobile" id="fromDate">
            ถึง
          </label>
          <div className="col-3 mobile-view" id="datePicker">
            <input
              className="form-control"
              name="productEndDate"
              type="number"
              min="1"
              max="9999"
              value={searchData.productEndDate}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      )}

      <div className="row align-items-center mt-3 mb-3 g-1">
        {orderPhoneNumber ? (
          <>
            <label className="col-2 white-space-right-5 width-mobile-day">เบอร์โทรศัพท์ผู้สั่ง :</label>
            <div className="col-2 mobile-view">
              <input
                className="form-control"
                name="tel"
                type="text"
                min="1"
                value={searchData.tel}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </>
        ) : (
          <>
            <label className="col-2 white-space-right-5 width-mobile">รหัสคลัง :</label>
            <div className="col-2 mobile-view">
              <input
                className="form-control"
                name="warehouseId"
                type="text"
                min="1"
                value={searchData.warehouseId}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </>
        )}

        <label className="col-1 white-space-right-5 width-mobile">สถานะ :</label>
        <div className="col-3 mobile-view">
          <select
            className="form-select search-select"
            aria-label="Default select example"
            name="status"
            value={searchData.status}
            onChange={(e) => handleChange(e)}
          >
            <StatusList fromComponent={"search"} />
          </select>
        </div>
        <div className="col-4 text-center button-width" id="search-Button">
          <button
            className="col-10 btn btn-lg"
            onClick={
              orderPhoneNumber ? () => validate(searchData) : handleSearchData
            }
            disabled={isDisableBtn}
          >
            ค้นหา
          </button>
        </div>
      </div>

      <Modal
        show={showAlert}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
      >
        <Modal.Header closeButton>
          <Modal.Title>แจ้งเตือน</Modal.Title>
        </Modal.Header>
        <Modal.Body>{messageAlert}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Search;
