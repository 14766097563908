const splitDateTime = (date) => {
  if (date !== undefined && date !== null) {
    return (
      <>
        <p className="no-space">{date.split(" ")[0]}</p>
        <p className="no-space">{date.split(" ")[1]}</p>
      </>
    );
  }
  return <></>;
};

const handleSorting = (a, b) => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }

  const aSeparateDateTime = a.split(" ");
  const bSeparateDateTime = b.split(" ");
  const aDate = aSeparateDateTime[0].split("/");
  const bDate = bSeparateDateTime[0].split("/");
  const aTime = aSeparateDateTime[1].split(":");
  const bTime = bSeparateDateTime[1].split(":");
  return (
    aDate[2] - bDate[2] ||
    aDate[1] - bDate[1] ||
    aDate[0] - bDate[0] ||
    aTime[0] - bTime[0] ||
    aTime[1] - bTime[1]
  );
};

const checkNullValue = (value) => {
  return value === null ? "-" : value;
};

const checkNullDate = (date) => {
  return date === null ? "-" : splitDateTime(date);
};

export const COLUMNS_CREATE_FILE = [
  {
    id: "customer_request_no",
    Header: "Customer Request No",
    accessor: "customer_request_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.customer_request_no),
  },
  {
    id: "create_datetime",
    Header: "Date/Time",
    accessor: "create_datetime",
    show: true,
    Cell: ({ row }) =>
      row.original?.create_datetime === null
        ? "-"
        : checkNullDate(row.original?.create_datetime),
    sortType: (a, b) =>
      handleSorting(a.values.create_datetime, b.values.create_datetime),
  },
  {
    id: "order_no",
    Header: "ORD_NO",
    accessor: "order_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.order_no),
  },
  {
    id: "order_date",
    Header: "ORD_Date",
    accessor: "order_date",
    show: true,
    Cell: ({ row }) =>
      row.original?.order_date === null
        ? "-"
        : checkNullDate(row.original?.order_date),
    sortType: (a, b) => handleSorting(a.values.order_date, b.values.order_date),
  },
  {
    id: "transfer_status",
    Header: "Status Urgent",
    accessor: "transfer_status",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.transfer_status),
  },
  {
    id: "request_refund_status_text",
    Header: "Refund Status",
    accessor: "request_refund_status_text",
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.request_refund_status_text),
    sortType: (a, b) => {
      if (
        a.values.request_refund_status_text === null ||
        a.values.request_refund_status_text === undefined
      ) {
        return 1;
      }
      if (
        b.values.request_refund_status_text === null ||
        b.values.request_refund_status_text === undefined
      ) {
        return -1;
      }

      const aStatus =
        a.values.request_refund_status_text === "อยู่ระหว่างดำเนินการคืนเงิน"
          ? 4
          : a.values.request_refund_status_text === "รอการแก้ไขข้อมูลจากลูกค้า"
          ? 3
          : a.values.request_refund_status_text ===
            "ดึงข้อมูลทำเรื่องคืนเงินแล้ว"
          ? 2
          : a.values.request_refund_status_text === "คืนเงินสำเร็จ"
          ? 1
          : 0;
      const bStatus =
        b.values.request_refund_status_text === "อยู่ระหว่างดำเนินการคืนเงิน"
          ? 4
          : b.values.request_refund_status_text === "รอการแก้ไขข้อมูลจากลูกค้า"
          ? 3
          : b.values.request_refund_status_text ===
            "ดึงข้อมูลทำเรื่องคืนเงินแล้ว"
          ? 2
          : b.values.request_refund_status_text === "คืนเงินสำเร็จ"
          ? 1
          : 0;
      return aStatus - bStatus;
    },
  },
  {
    id: "request_refund_date",
    Header: "Refund Date",
    accessor: "request_refund_date",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      row.original?.request_refund_date === null
        ? "-"
        : checkNullDate(row.original?.request_refund_date),
  },
  {
    id: "paymode",
    Header: "Paymode",
    accessor: "paymode",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.paymode),
  },
  {
    id: "refund_channel",
    Header: "ช่องทางการคืนเงิน",
    accessor: "refund_channel",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_channel),
  },
  {
    id: "refund_account_no",
    Header: "เลขที่บัญชี",
    accessor: "refund_account_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_account_no),
  },
  {
    id: "refund_total",
    Header: "Summary(Baht)",
    accessor: "refund_total",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      checkNullValue(Number(row.original?.refund_total).toLocaleString()),
  },
  {
    id: "all_member_point_discount",
    Header: "ส่วนลด Allmember",
    accessor: "all_member_point_discount",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      checkNullValue(
        Number(row.original?.all_member_point_discount).toLocaleString()
      ),
  },
  {
    id: "other_point_discount",
    Header: "ส่วนลด M-Stamp , Coin และ/หรือ อื่นๆ",
    accessor: "other_point_discount",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      checkNullValue(
        Number(row.original?.other_point_discount).toLocaleString()
      ),
  },
  {
    id: "refund_remark",
    Header: "หมายเหตุไม่จ่าย",
    accessor: "refund_remark",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_remark),
  },
  {
    id: "ecn_status",
    Header: "Create E-CN status",
    accessor: "ecn_status",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.ecn_status),
  },
  {
    id: "ecn_date",
    Header: "Create E-CN date",
    accessor: "ecn_date",
    show: true,
    Cell: ({ row }) =>
      row.original?.ecn_date === null
        ? "-"
        : checkNullDate(row.original?.ecn_date),
    sortType: (a, b) => handleSorting(a.values.ecn_date, b.values.ecn_date),
  },
  {
    id: "etax_no",
    Header: "เลขที่ใบกำกับภาษี",
    accessor: "etax_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      row.original?.etax_pdf ? (
        <a href={row.original?.etax_pdf} target="_blank">
          {checkNullValue(row.original?.etax_no)}
          <img className="px-2 mb-2" src={process.env.PUBLIC_URL + "/assets/icons/icon-external-window.svg"} />
        </a>
      ) : (
        checkNullValue(row.original?.etax_no)
      ),
  },
  {
    id: "ecn_no",
    Header: "เลขที่ใบลดหนี้",
    accessor: "ecn_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      row.original?.ecn_pdf ? (
        <a href={row.original?.ecn_pdf} target="_blank">
          {checkNullValue(row.original?.ecn_no)}
          <img className="px-2 mb-2" src={process.env.PUBLIC_URL + "/assets/icons/icon-external-window.svg"} />
        </a>
      ) : (
        checkNullValue(row.original?.ecn_no)
      ),
  },
  {
    id: "wh_approve_status",
    Header: "WH Approve Status",
    accessor: "wh_approve_status",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.wh_approve_status),
  },
  {
    id: "wh_approve",
    Header: "WH Approve",
    accessor: "wh_approve",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.wh_approve),
  },
];

export const COLUMNS_APPROVE_REFUND = [
  {
    id: "customer_request_no",
    Header: "Customer Request No",
    accessor: "customer_request_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.customer_request_no),
  },
  {
    id: "create_datetime",
    Header: "Date/Time",
    accessor: "create_datetime",
    show: true,
    Cell: ({ row }) =>
      row.original?.create_datetime === null
        ? "-"
        : checkNullDate(row.original?.create_datetime),
    sortType: (a, b) =>
      handleSorting(a.values.create_datetime, b.values.create_datetime),
  },
  {
    id: "order_no",
    Header: "ORD_NO",
    accessor: "order_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.order_no),
  },
  {
    id: "order_date",
    Header: "ORD_Date",
    accessor: "order_date",
    show: true,
    Cell: ({ row }) =>
      row.original?.order_date === null
        ? "-"
        : checkNullDate(row.original?.order_date),
    sortType: (a, b) => handleSorting(a.values.order_date, b.values.order_date),
  },
  {
    id: "transfer_status",
    Header: "Status Urgent",
    accessor: "transfer_status",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.transfer_status),
  },
  {
    id: "request_refund_status_text",
    Header: "Refund Status",
    accessor: "request_refund_status_text",
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.request_refund_status_text),
    sortType: (a, b) => {
      if (
        a.values.request_refund_status_text === null ||
        a.values.request_refund_status_text === undefined
      ) {
        return 1;
      }
      if (
        b.values.request_refund_status_text === null ||
        b.values.request_refund_status_text === undefined
      ) {
        return -1;
      }

      const aStatus =
        a.values.request_refund_status_text === "อยู่ระหว่างดำเนินการคืนเงิน"
          ? 4
          : a.values.request_refund_status_text === "รอการแก้ไขข้อมูลจากลูกค้า"
          ? 3
          : a.values.request_refund_status_text ===
            "ดึงข้อมูลทำเรื่องคืนเงินแล้ว"
          ? 2
          : a.values.request_refund_status_text === "คืนเงินสำเร็จ"
          ? 1
          : 0;
      const bStatus =
        b.values.request_refund_status_text === "อยู่ระหว่างดำเนินการคืนเงิน"
          ? 4
          : b.values.request_refund_status_text === "รอการแก้ไขข้อมูลจากลูกค้า"
          ? 3
          : b.values.request_refund_status_text ===
            "ดึงข้อมูลทำเรื่องคืนเงินแล้ว"
          ? 2
          : b.values.request_refund_status_text === "คืนเงินสำเร็จ"
          ? 1
          : 0;
      return aStatus - bStatus;
    },
  },
  {
    id: "request_refund_date",
    Header: "Refund Date",
    accessor: "request_refund_date",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      row.original?.request_refund_date === null
        ? "-"
        : checkNullDate(row.original?.request_refund_date),
  },
  {
    id: "paymode",
    Header: "Paymode",
    accessor: "paymode",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.paymode),
  },
  {
    id: "refund_channel",
    Header: "ช่องทางการคืนเงิน",
    accessor: "refund_channel",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_channel),
  },
  {
    id: "refund_account_no",
    Header: "เลขที่บัญชี",
    accessor: "refund_account_no",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_account_no),
  },
  {
    id: "refund_total",
    Header: "Summary(Baht)",
    accessor: "refund_total",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) =>
      checkNullValue(Number(row.original?.refund_total).toLocaleString()),
  },
  {
    id: "refund_remark",
    Header: "หมายเหตุไม่จ่าย",
    accessor: "refund_remark",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_remark),
  },
  {
    id: "ecn_status",
    Header: "Create E-CN status",
    accessor: "ecn_status",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.ecn_status),
  },
  {
    id: "ecn_date",
    Header: "Create E-CN date",
    accessor: "ecn_date",
    show: true,
    Cell: ({ row }) =>
      row.original?.ecn_date === null
        ? "-"
        : checkNullDate(row.original?.ecn_date),
    sortType: (a, b) => handleSorting(a.values.ecn_date, b.values.ecn_date),
  },
  {
    id: "wh_approve_status",
    Header: "WH Approve Status",
    accessor: "wh_approve_status",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.wh_approve_status),
  },
  {
    id: "wh_approve",
    Header: "WH Approve",
    accessor: "wh_approve",
    disableSortBy: true,
    show: true,
    Cell: ({ row }) => checkNullValue(row.original?.wh_approve),
  },
];
