import React, { useMemo, useEffect } from 'react';
import { useTable, useFilters, usePagination } from 'react-table';
import _ from "lodash";
import '../../../../../scss/common/utils.scss';
import { COLUMNS } from './ColumnFilter/columns';
import { ColumnFilter } from './ColumnFilter/ColumnFilter';
import Pagination from '../Pagination';

const Table = ({ data, setIsNoData, isNoData, redirectFunction, isCanSearch }) => {

  const columns = useMemo(() => COLUMNS, [])

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useFilters,
    usePagination
  );

  useEffect(() => {
    if (rows.length === 0) {
      setIsNoData(true);
    } else setIsNoData(false);
  }, [rows])

  return (
    <>
      <div className={isNoData ? "no-data contact-center" : "d-none"}>{isCanSearch ? "ไม่พบข้อมูล" : "คุณไม่มีสิทธิ์เข้าถึงข้อมูล"}</div>
      <div className={isNoData ? "table-common empty-table" : "table-common"}>
        <table className='table-primary' {...getTableProps()}>
          <thead className="table-header">
            {headerGroups.map((headerGroup) => (
              <>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="grrs" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="grrs-filter" {...column.getHeaderProps()}>
                      {column.canFilter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              </>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={index % 2 !== 0 ? "bg-white" : ""}
                >
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()} onClick={() => redirectFunction(row, cell.getCellProps())}>{cell.render("Cell")}</td>
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        isNoData={isNoData}
        parentPage={"contact-center-customer-service"}
        setPageSize={setPageSize}
        pageSize={pageSize}
        rows={rows}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </>
  );
};

export default Table;
