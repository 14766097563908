import "../../../../scss/userMaintenance/roleManagement/_table.scss";

function RoleManageUserTable({ userList }) {
  return (
    <div className="mt-3 mt-3 role-management-table">
      <table className="role-management-thead" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="first-col-th col-md-3 text-start pl-5">
              Status
            </th>
            <th className="last-col-th col-md-9 text-start">Username </th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user, index) => (
            <tr className={`${index % 2 == 1 ? "striped" : ""} `}>
              <td className="first-col col-md-3 text-start pl-5">
                {user.status == "Active" && (
                  <b className="text-success ">{user.status}</b>
                )}
                {user.status != "Active" && (
                  <b className="text-danger">{user.status}</b>
                )}
              </td>
              <td className="last-col col-md-9 text-start">
                <p>{user.username}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RoleManageUserTable;
