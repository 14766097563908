import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../scss/common/_permissionModal.scss";
import {
  getAccessIdData,
} from "../../../service/auth.service";
import { useLocation } from "react-router-dom";
import { getMenuIdByURL } from "../../../helper/commonHelper";
import { getUserPermission } from "../../../service/customer-care.service";
const PermissionModal = ({ permission, backPage }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const accessIdData = getAccessIdData();
    let menuId = getMenuIdByURL(location.pathname);
    if (menuId !== "0" && accessIdData) {
      getUserPermission(menuId, accessIdData.email).then((res) => {
        const checkPermission = permissionCheck(res);
        if (checkPermission === true) {
          setShowModal(true);
        }
      });
    }
  }, [location]);

  const permissionCheck = (subPermission) => {
    switch (backPage) {
      case "/":
        if (
          subPermission?.length === 0 ||
          !subPermission.some((res) => res === "search")
        ) {
          return true;
        } else {
          return false;
        }
      case "/free-delivery": {
        if (subPermission?.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      case "/ecn": {
        if (subPermission?.length === 0) {
          return true;
        } else {
          return false;
        }
      }

      case "/user-maintenance": {
        if (subPermission?.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      case "/grrs": {
        if (subPermission?.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      default:
        return true;
    }
  };

  const back = () => {
    history.push(backPage);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={back}
        backdrop="static"
        keyboard={false}
        className="modalPermission"
      >
        <Modal.Body className="mt-1">
          <h1 className="mt-4">เกิดข้อผิดพลาด</h1>
          <h2 className="mt-4">คุณไม่มีสิทธิ์เข้าดูรายละเอียด</h2>
          <Button className="mx-auto mt-4" variant="primary" onClick={back}>
            <h2 className="text-white">ตกลง</h2>
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PermissionModal;
