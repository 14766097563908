import React, { useEffect } from "react";
import "./TransferMoneyModal.scss";

function TransferMoneyModal({
  data,
  transferMoneyModalState,
  setTransferMoneyModalState,
  handleSubmit = () => {},
}) {
  const {
    channel_code,
    channel_text,
    bank_account_type_code,
    bank_text,
    promtpay_no,
    account_no,
    account_name,
  } = data?.refund_method;
  const { return_amp, return_m_stamp, cancel_amp, cancel_m_stamp, amp } =
    data?.total_refund;
  const { isCashMode, invoice_value, refund_channel_name } = data;

  function handleModalDisplay() {
    const button = document.getElementById("transfer-money-modal-button");
    button.click();
  }

  useEffect(() => {
    if (transferMoneyModalState === true) {
      handleModalDisplay();
      setTransferMoneyModalState(false);
    }
  }, [transferMoneyModalState]);
  return (
    <>
      <button
        type="button"
        className="btn btn-primary transfer-money-modal-button"
        id="transfer-money-modal-button"
        data-bs-toggle="modal"
        data-bs-target="#checkingPaymentMethod"
        hidden
      >
        ตกลง
      </button>
      <div className="transferMoneyModal">
        <div
          className="modal fade"
          id="checkingPaymentMethod"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="checkingPaymentMethodLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="checkingPaymentMethodLabel">
                  กรุณาตรวจสอบช่องทางการโอนเงินคืน
                </h1>
                <button
                  type="button"
                  id="transfer-money-close-btn"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  hidden
                ></button>
              </div>
              <div className="modal-body">
                <div className="border-top"></div>
                <div className="row">
                  <div className="col-4 text-start">
                    <span>
                      {isCashMode === true
                        ? "ประเภทบัญชี"
                        : "ช่องทางการคืนเงิน"}
                    </span>
                  </div>
                  <div className="col-8 text-end">
                    <span>
                      {isCashMode === true ? channel_text : refund_channel_name}
                    </span>
                  </div>
                </div>
                {channel_code === "BSA" || channel_code === "BCA" ? (
                  <>
                    <div className="row">
                      <div className="col-4 text-start">
                        <span>ชื่อบัญชี</span>
                      </div>
                      <div className="col-8 text-end">
                        <span>{account_name}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-start">
                        <span>เลขที่บัญชี</span>
                      </div>
                      <div className="col-8 text-end">
                        <span>{account_no}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-start">
                        <span>ธนาคาร</span>
                      </div>
                      <div className="col-8 text-end">
                        <span>{bank_text}</span>
                      </div>
                    </div>
                  </>
                ) : channel_code === "PP" ? (
                  <>
                    {bank_account_type_code === "1" ? (
                      <>
                        <div className="row">
                          <div className="col-4 text-start">
                            <span>เบอร์โทรศัพท์</span>
                          </div>
                          <div className="col-8 text-end">
                            <span>{promtpay_no}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-4 text-start">
                            <span>เลขบัตรประชาชน</span>
                          </div>
                          <div className="col-8 text-end">
                            <span>{promtpay_no}</span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                {return_amp === 0 &&
                return_m_stamp === 0 &&
                cancel_amp === 0 &&
                cancel_m_stamp === 0 ? (
                  <>
                    <div className="row border-top">
                      <div className="col-8 text-start">
                        <span className="fw-bold">รวมเงินคืนทั้งสิ้น</span>
                      </div>
                      <div className="col-4 text-end text-red">
                        <span>฿ {invoice_value.toLocaleString()}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row border-top">
                      <div className="col-8 text-start">
                        <span className="fw-bold">ยอดคืนเงินทั้งสินค้า</span>
                      </div>
                      <div className="col-4 text-end text-red">
                        <span>฿ {invoice_value.toLocaleString()}</span>
                      </div>
                    </div>
                  </>
                )}
                {return_amp !== 0 && (
                  <div className="row border-top">
                    <div className="col-8 text-start">
                      <span>คืน ALL Point (แต้ม)</span>
                    </div>
                    <div className="col-4 text-end text-red">
                      <span>{return_amp.toLocaleString()}</span>
                    </div>
                  </div>
                )}
                {return_m_stamp !== 0 && (
                  <div className="row border-top">
                    <div className="col-8 text-start">
                      <span>คืน M-stamp/เหรียญ (บาท) </span>
                    </div>
                    <div className="col-4 text-end text-red">
                      <span>฿ {return_m_stamp.toLocaleString()}</span>
                    </div>
                  </div>
                )}
                {cancel_amp !== 0 && (
                  <div className="row border-top">
                    <div className="col-8 text-start">
                      <span>ยกเลิก ALL Point ที่ได้รับ (แต้ม)</span>
                    </div>
                    <div className="col-4 text-end text-red">
                      <span>{cancel_amp.toLocaleString()}</span>
                    </div>
                  </div>
                )}
                {cancel_m_stamp !== 0 && (
                  <div className="row border-top">
                    <div className="col-8 text-start">
                      <span>ยกเลิก M-stamp/เหรียญ ที่ได้รับ (บาท)</span>
                    </div>
                    <div className="col-4 text-end text-red">
                      <span>฿ {cancel_m_stamp.toLocaleString()}</span>
                    </div>
                  </div>
                )}
                {amp !== 0 &&
                  amp !== undefined &&
                  amp !== "0" &&
                  amp !== "" && (
                    <div className="row border-top">
                      <div className="col-8 text-start">
                        <span>
                          การชดเชย ALL Point ให้ลูกค้าด้วย (แต้ม)
                        </span>
                      </div>
                      <div className="col-4 text-end text-red">
                        <span>{Number(amp).toLocaleString()}</span>
                      </div>
                    </div>
                  )}
                <div className="row justify-content-center btn-section">
                  <div className="col-6 text-end">
                    <button
                      type="button"
                      className="btn grrs-white-button transfer-money-edit-btn"
                      data-bs-dismiss="modal"
                    >
                      แก้ไข
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn grrs-primary-button transfer-money-confirm-btn"
                      id="transfer-refund-submit"
                      onClick={() => handleSubmit()}
                    >
                      ยืนยัน
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransferMoneyModal;
