import { useEffect, useState } from "react";
import "../../../../scss/userMaintenance/roleManagement/_roleAddRole.scss";
import RoleAddRoleForm from "./RoleAddRoleForm";
import RoleHeader from "./RoleHeader";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import {
  menuToAuthorizeList,
  menuToMenuStatus,
  menuToAdditionHead,
  menuToTreeMenu,
} from "../../../../helper/userMaintenanceHelper";
import {
  getMenu,
  postRole,
} from "../../../../service/userMaintenance/roleManagement.service";

function RoleAddRoleMain() {
  let history = useHistory();
  const goMain = () => {
    history.push("/user-maintenance/role-management");
  };

  const errorCallback = () => {
    setIsRoleIsError(true);
  };

  const postRoleAPI = async () => {
    const authorizeFilter = authorize.filter((s) => s.authorize.length > 0);
    const menuListWithHead = [
      ...menuToAdditionHead(menu, authorizeFilter),
      ...authorizeFilter,
    ];
    const body = {
      request_data: {
        role: roleName,
        menu_list: menuListWithHead,
        status: status,
      },
    };
    const response = await postRole(body, goMain, errorCallback);
  };

  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("Active");
  const [menu, setMenu] = useState([]);
  const [menuStatus, setMenuStatus] = useState([]);
  const [authorize, setAuthorize] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const [isRoleIsError, setIsRoleIsError] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const handleClose = () => setIsModal(false);
  const handleShow = () => setIsModal(true);

  useEffect(() => {
    setIsLoad(true);
    const fetchData = async () => {
      const menuFromAPI = await getMenu();
      const menuData = menuFromAPI.data.response_data.menu_list;
      setMenu(menuData);
      setIsLoad(false);
    };
    fetchData();
  }, []);
  useEffect(() => {
    setAuthorize(menuToAuthorizeList(menu));
    setMenuStatus(menuToMenuStatus(menu));
  }, [menu]);
  return (
    <div className="container">
      <div className="roleAddRole">
        <RoleHeader text="Add New Role" />
        {!isLoad && (
          <RoleAddRoleForm
            mode={"add"}
            menu={menu}
            authorize={authorize}
            setAuthorize={setAuthorize}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
            roleName={roleName}
            setRoleName={setRoleName}
            status={status}
            setStatus={setStatus}
            postRoleAPI={postRoleAPI}
            goMain={goMain}
            isRoleIsError={isRoleIsError}
            setIsRoleIsError={setIsRoleIsError}
            isModal={isModal}
            setIsModal={setIsModal}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        )}
        <LoadingSpinner isLoading={isLoad} />
      </div>
    </div>
  );
}

export default RoleAddRoleMain;
