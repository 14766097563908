import React, { useEffect, useContext } from "react";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";
import { Col, Container, Row } from "react-bootstrap";
import Blank from "../../common/components/Blank";
import MenuContext from "../../../context/MenuContext";
import {
    getMenuByMenuId,
    getUserPermission,
} from "../../../service/customer-care.service";

import UserManagementEditForm from "./UserManagementEditForm";

function EditFormDetail(props) {
    const pathName = props.location.pathname;

    const { menu, setMenu, setUserRole, profileUser, permission } = useContext(MenuContext);

    useEffect(() => {
        if (menu.length === 0 || menu.menu_id !== "51") {
            if (profileUser) {
                getMenuByMenuId("51", profileUser.email).then((res) => {
                    setMenu(res?.menu_list[0]);
                    setUserRole(res?.role[0]);
                });
            }
        }
    }, []);

    return (
        <Container>
            <div className="layout">
                <div className="row vh-100">
                    <Row>
                        <ResponsiveSideBar />
                        <Col md={9} className="content">
                            <div>
                                <UserManagementEditForm />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
}

export default EditFormDetail;
