import React, { useEffect, useContext } from "react";
import queryString from "query-string";
import ECreditNote from "../components/ecn/EcreditNote";
import PreRequest from "../components/preRequest/PreRequest";
import AddECreditNote from "../components/ecn/AddECreditNote";
import MenuContext from "../../../context/MenuContext";
import { Col, Container, Row } from "react-bootstrap";
import { getMenuByMenuId } from "../../../service/customer-care.service";
import Blank from "./../../common/components/Blank";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";

function Ecn({ location }) {
  const { menu, setMenu, setUserRole, profileUser, setPermission } = useContext(MenuContext);
  const pathName = location.pathname;

  useEffect(() => {
    if (menu.length === 0 || menu.menu_id !== "42") {
      if (profileUser) {
        getMenuByMenuId("42", profileUser.email).then((res) => {
          setMenu(res?.menu_list[0]);
          setUserRole(res?.role[0])
        });

      }
    }
  }, []);

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content">
              <div>
                {pathName === "/ecn/pre-request" ? (
                  <PreRequest />
                ) : pathName === "/ecn/คำขอคืนสินค้า" ? (
                  <ECreditNote />
                ) : pathName === "/ecn/add-ecreditnote" ? (
                  <AddECreditNote />
                ) : <Blank />}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}
export default Ecn;
