const sum = (a, b) => a + b;

const menuToMenuIdList = (menu) => {
  if (menu !== undefined && menu.length !== 0) {
    return menu.reduce(
      (sum, c) => [...sum, c.menu_id, ...menuToMenuIdList(c.menu_list)],
      []
    );
  }
  return [];
};

const menuToTreeMenu = (menu) =>
  menu.map((c) => {
    return { ...c, menu_list: c.menu_list.map((d) => d.menu_id) };
  });
const menuToAuthorizeList = (menu) =>
  menuToMenuIdList(menu).map((m) => {
    return { menu_id: m, authorize: [] };
  });

const menuToMenuStatus = (menu) =>
  menuToMenuIdList(menu).map((m) => {
    return { menu_id: m, disable: false };
  });

const menuAndAuthorizeToMenuStatus = (menu, authorize) => {
  let menuStatus = menuToMenuIdList(menu).map((m) => {
    return { menu_id: m, disable: false };
  });

  const authorizeIdList = authorize.map((c) => c.menu_id);
  menuStatus = menuStatus.map((c) => {
    return authorizeIdList.indexOf(c.menu_id) >= 0
      ? { ...c, disable: true }
      : c;
  });
  return menuStatus;
};

const menuAndAuthorizeToMenu = (menu, authorize) => {
  const authorizeList = menuToAuthorizeList(menu);

  const authorizeListWithAuthorize = authorizeList.map((c) => {
    const temp = authorize.filter((d) => c.menu_id == d.menu_id)[0];
    if (temp) return temp;
    return c;
  });

  return authorizeListWithAuthorize;
  //console.log(authorizeListWithAuthorize, "authorizeListWithAuthorize");
};

const updateMenuStatusByMenuId = (menuStatus, menuId, disable) =>
  menuStatus.map((c) =>
    c.menu_id === menuId ? { ...c, disable: disable } : c
  );

const getMenuStatusById = (menuStatus, menuId) => {
  if (menuStatus == undefined) return false;
  if (menuStatus.filter((m) => m.menu_id === menuId).length == 0) return false;
  return menuStatus.filter((m) => m.menu_id === menuId)[0].disable;
};

const addAuthorizeToAuthorizeListById = (authorizeList, id, authorize) =>
  authorizeList.map((a) =>
    a.menu_id == id ? { ...a, authorize: [...a.authorize, authorize] } : a
  );

const deleteAllAuthorizeToAuthorzeListById = (authorizeList, id) =>
  authorizeList.map((a) => (a.menu_id == id ? { ...a, authorize: [] } : a));

const addAuthorizesToAuthorizeListById = (authorizeList, id, authorizes) =>
  authorizeList.map((a) =>
    a.menu_id == id ? { ...a, authorize: [...authorizes] } : a
  );

const addAuthorizeFromAPIToAuthorizeList = (authorizeList, authorizeFromAPI) =>
  authorizeFromAPI.reduce(
    (sum, a) => addAuthorizesToAuthorizeListById(sum, a.menu_id, a.authorize),
    authorizeList
  );

const deleteAuthorizeToAuthorizeListById = (authorizeList, id, authorize) =>
  authorizeList.map((a) =>
    a.menu_id == id
      ? { ...a, authorize: a.authorize.filter((c) => c != authorize) }
      : a
  );

const isAuthorizeByMenuId = (authorizeList, menuId, authorize) => {
  if (authorizeList.filter((e) => e.menu_id === menuId).length === 0)
    return false;
  return authorizeList
    .filter((e) => e.menu_id === menuId)[0]
    .authorize.filter((e) => e === authorize).length > 0
    ? true
    : false;
};

const treeMenuToAdditionHead = (treeMenu, postAPIMenu) =>
  treeMenu.reduce((sum, c) => {
    if (
      intersection(
        c.menu_list,
        postAPIMenu.map((c) => c.menu_id)
      ).length > 0
    )
      return [...sum, { menu_id: c.menu_id, authorize: [] }];
    return sum;
  }, []);

const menuToAdditionHead = (menu, postAPIMenu) =>
  treeMenuToAdditionHead(menuToTreeMenu(menu), postAPIMenu);

const getChildsId = (menu) => {
  if (menu.menu_list.length === 0) return [menu.menu_id];
  return [
    menu.menu_id,
    ...menu.menu_list
      .map((m) => getChildsId(m))
      .reduce((sum, m) => [...sum, ...m], []),
  ];
};

const getMenuStatusAndAuthorizeListWhenDisableHeader = (
  menu,
  menuStatus,
  authorizeList
) => {
  const childsIdList = getChildsId(menu);

  const _MenuStatus = childsIdList?.reduce(
    (sum, id) => updateMenuStatusByMenuId(sum, id, false),
    menuStatus
  );

  const _Authorize = childsIdList?.reduce(
    (sum, id) => deleteAllAuthorizeToAuthorzeListById(sum, id),
    authorizeList
  );

  return {
    _MenuStatus,
    _Authorize,
  };
};

const intersection = (arrA, arrB) => arrA.filter((x) => arrB.includes(x));
export {
  sum,
  intersection,
  menuToTreeMenu,
  menuToMenuIdList,
  menuToAuthorizeList,
  addAuthorizeToAuthorizeListById,
  deleteAuthorizeToAuthorizeListById,
  isAuthorizeByMenuId,
  menuToMenuStatus,
  menuToAdditionHead,
  updateMenuStatusByMenuId,
  getMenuStatusById,
  addAuthorizesToAuthorizeListById,
  addAuthorizeFromAPIToAuthorizeList,
  menuAndAuthorizeToMenu,
  treeMenuToAdditionHead,
  deleteAllAuthorizeToAuthorzeListById,
  menuAndAuthorizeToMenuStatus,
  getChildsId,
  getMenuStatusAndAuthorizeListWhenDisableHeader,
};
