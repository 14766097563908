import React, { useEffect, useState, useContext } from "react";
import { TaxTable } from "../components/TaxInvoice/TaxTable";
import "../../../scss/etax/_mainPage.scss";
import MenuContext from "../../../context/MenuContext";
import {
  getMenuByMenuId,
  getUserData,
  getUserPermission,
} from "../../../service/customer-care.service";
import { Container, Row, Col } from "react-bootstrap";
import { paginate } from "../../../utils/paginate";
import Pagination from "../../common/components/Pagination";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import SearchEtax from "../../../modules/etax/components/TaxInvoice/SearchEtax";
import { getEtaxData } from "../../../service/etax/etax.service";
import { isEmpty } from "lodash";
import { getFullTaxInvoiceRequestable } from "../../../service/etax/etax.service";
import PermissionModal from "../../common/components/PermissionModal";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";

const EtaxMainPage = () => {
  const { menu, setMenu, setUserRole, profileUser, permission } =
    useContext(MenuContext);
  const [users, setUsers] = useState([]);
  const [checkRequestEtax, setCheckRequestEtax] = useState({});
  const [isUserEmpty, setIsUserEmpty] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [navbarShow, setNavbarShow] = useState(false);
  const [alertNotFound, setAlertNotFound] = useState(false);

  const setSearchEtaxData = (data) => {
    console.log(data);
    if (data.status == "NOT_AVAILABLE" || isEmpty(data)) {
      setUsers([]);
      setAlertNotFound(true);
    } else {
      setUsers(data);
      setAlertNotFound(false);
    }
  };

  const handleSearchData = async (external_orderno) => {
    setIsLoading(true);
    let data = await getEtaxData(external_orderno);

    if (data.url_credit_note_list?.length != 0) {
      let checkRequestEtax = await getFullTaxInvoiceRequestable(
        external_orderno,
        data.ordersource
      );
      setCheckRequestEtax(checkRequestEtax);
    } else {
      setCheckRequestEtax({});
    }

    if (data.status == "NOT_AVAILABLE" || isEmpty(data)) {
      setSearchEtaxData({});
      setAlertNotFound(true);
    } else {
      setSearchEtaxData(data);
      setAlertNotFound(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (menu.length === 0 || menu.menu_id !== "21") {
      if (profileUser) {
        getMenuByMenuId("21", profileUser.email).then((res) => {
          setMenu(res?.menu_list[0]);
          setUserRole(res?.role[0]);
        });
      }
    }
  }, []);

  const toggleNavbar = () => {
    setNavbarShow(!navbarShow);
  };

  return (
    <Container>
      <div className="layout etax-scrollbar">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content">
              <div className="etax-title">
                <p>ใบกำกับภาษีอิเล็กทรอนิกส์</p>
              </div>
              <SearchEtax
                handleSearchData={handleSearchData}
                setIsLoading={setIsLoading}
                alertNotFound={alertNotFound}
              />
              <LoadingSpinner isLoading={isLoading} />
              {!isUserEmpty && !isLoading && permission?.length !== 0 && (
                <div className="etax-marginleft">
                  <TaxTable
                    users={users}
                    checkRequestEtax={checkRequestEtax}
                    permission={permission}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <PermissionModal permission={permission} backPage={"/"} />
    </Container>
  );
};

export default EtaxMainPage;
