import React, { useState } from "react";
import "./FormRequestCancel.scss";
import moment from "moment";
const diffDate = (d) => {
  const today = moment().locale("th").add(543, "year");
  const m = moment(d, "DD-MM-YYYY");

  return today.diff(m, "days");
};

function TransactionHistory({ cancellationInitial }) {
  const [isDisplay, setIsDisplay] = useState(true);
  const { request_activity_log } = cancellationInitial.orders;
  return (
    <div className="form-request-cancel">
      <div className="header-request-cancel header-request-cancel-view">
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">ประวัติการทำรายการ</span>
      </div>
      {isDisplay && (
        <div className="body-request-cancel body-trainsectoin">
          <div>
            {request_activity_log.map((request) => (
              <div className="row">
                <div className="col-sm-1 text-center">
                  <img
                    className=""
                    src="/assets/grrs/people-icon.svg"
                    alt="user"
                  />
                </div>
                <div className="col-sm-11">
                  <div>
                    {`${request.name} `}
                    <span className="red">{`${request.activity} `}</span>
                    {`${request.activity_date} ${request.description}`}
                  </div>
                  <div className="sub-text">{`${diffDate(
                    request.activity_date
                  )} day ago`}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionHistory;
