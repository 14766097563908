import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
const RoleModal = ({
  submitFunction,
  isModal,
  setIsModal,
  handleClose,
  setIsRoleIsError,
  isRoleIsError = false,
}) => {
  if (isRoleIsError) {
    return (
      <Modal show={isModal} onHide={handleClose}>
        <div className="">
          <div className="">
            <div className="header">
              <h4 className="title" id="staticBackdropLabel">
                ไม่สามารถ Add Role นี้ได้
              </h4>
            </div>
            <div className="body">
              <h5>เนื่องจากมี Role นี้อยู่แล้ว</h5>
            </div>
            <div className="footer">
              <button
                type="submit"
                className="resetbutton"
                data-bs-dismiss="modal"
                onClick={() => {
                  setIsRoleIsError(false);
                  setIsModal(false);
                }}
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal show={isModal} onHide={handleClose}>
      <div className="">
        <div className="">
          <div className="header">
            <h4 className="title" id="staticBackdropLabel">
              ยืนยันข้อมูล
            </h4>
          </div>
          <div className="body">
            <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
          </div>
          <div className="footer">
            <button className="resetbutton" onClick={submitFunction}>
              บันทึก
            </button>
            <button type="button" className="backbutton" onClick={handleClose}>
              แก้ไข
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RoleModal;
