import RoleList from "../components/roleManagement/RoleListMain";

function RoleManagement() {
  return (
    <div>
      <RoleList />
    </div>
  );
}

export default RoleManagement;
