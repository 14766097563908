import { useState } from "react"


const useInput = (validationMethod) => {
    const [enterValue, setEnterValue] = useState("");
    const [enterValueBlurIsValid, setEnterValueBlurIsValid] = useState(false);

    const enterValueIsValid = validationMethod(enterValue)

    const inputValueError = !enterValueIsValid && enterValueBlurIsValid

    function onValueChangeHandle(e) {
        setEnterValue(e.target.value);
        setEnterValueBlurIsValid(true);
    }

    function onValueBlurHandle() {
        setEnterValueBlurIsValid(true);
    }

    function resetValue() {
        setEnterValue("");
        setEnterValueBlurIsValid(false);
    }
    return {
        enterValue,
        setEnterValue,
        enterValueBlurIsValid,
        setEnterValueBlurIsValid,
        enterValueIsValid,
        inputValueError,
        onValueChangeHandle,
        onValueBlurHandle,
        resetValue
    }
}

export default useInput