import React from 'react'
import "./CustomerInfo.scss";

export const CustomerInfoContactCenter = (props) => {
    const { customerDetail, isDisplay, setIsDisplay } = props;

    return (
        <>
            <div className="form-request-cancel">
                <div className="header-request-cancel header-request-cancel-view">
                    <span className="hide-icon">
                        <img
                            className=""
                            src={
                                isDisplay
                                    ? "/assets/grrs/hide-icon.svg"
                                    : "/assets/grrs/unhide-icon.svg"
                            }
                            alt="hide"
                            onClick={() => setIsDisplay(!isDisplay)}
                        />
                    </span>
                    <span className="fw-bold">ข้อมูลลูกค้า</span>
                </div>
                {isDisplay && (
                    <div className="body-request-cancel padding-left">
                        <div className="row">
                            <div className="col-md-6 col-orderlist">
                                <label className="form-label">ชื่อผู้รับ</label>
                                <input className="form-control" value={`${customerDetail?.orders?.customer_shipto_firstname}  ${customerDetail?.orders?.customer_shipto_lastname}`} disabled />
                            </div>
                            <div className="col-md-6 col-orderlist">
                                <label className="form-label">เบอร์ผู้รับ</label>
                                <input className="form-control" value={customerDetail?.orders?.customer_shipto_phone} disabled />
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-orderlist">
                                    <label className="form-label address-label">ที่อยู่จัดส่ง</label>
                                    <input className="form-control address-grrs" value={customerDetail?.orders?.address1} disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-orderlist">
                                    <label className="form-label">วิธีการชำระเงิน</label>
                                    <input className="form-control" value={customerDetail?.orders?.payment_channel_name} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
