import React, { useEffect, useState, useMemo, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./Accounting.scss";
import { getCancellationReqRefundFiles } from "../../../../service/grrs/accounting.service";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import Table from "../../components/Accounting/Table";
import { COLUMNS } from "../../components/Accounting/DownloadFile/ColumnFilter/columns";
import { ColumnFilter } from "../../components/Accounting/DownloadFile/ColumnFilter/ColumnFilter";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";

function AccountingDownloadFile() {
    const { permission } = useContext(MenuContext);
    const [rawData, setRawData] = useState([]);
    const [isNoData, setIsNoData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const pathName = location.pathname;

    const columns = useMemo(() => COLUMNS, []);
    const defaultColumn = useMemo(() => {
        return {
            Filter: ColumnFilter
        }
    }, []);

    const isPathName = () => {
        return pathName.includes("โอนคืนเงินสด") === true ? "cash" :
            pathName.includes("โอนคืนเงินออนไลน์") === true ? "online" :
                pathName.includes("All-Point-M-stamp-ชดเชย") === true ? "point" : ""
    }

    const handleFileNo = (rawData) => {
        const tempData = rawData.map((list, index) => {
            return {
                ...list,
                file_no: index += 1
            }
        })
        return tempData;
    }

    useEffect(() => {
        setIsLoading(true);
        const retundTypes = isPathName();
        getCancellationReqRefundFiles(retundTypes).then(res => {
            if (res?.refund_file === undefined) {
                setIsNoData(true);
            } else {
                setRawData(handleFileNo(res?.refund_file));
                setIsNoData(false);
            }
            setIsLoading(false);
        })
    }, [isPathName()])

    return (
        <Container>
            <div className="layout">
                <div className="row vh-100">
                    <Row>
                        <ResponsiveSideBar />
                        <Col md={9} className="grrs-accounting-content">
                            <div className="grrs-accounting-container space-bottom">
                                <LoadingSpinner isLoading={isLoading} />
                                <h1 className="grrs-accounting-title">{isPathName() === "cash" ? "ดาวน์โหลดข้อมูลโอนคืนเงินสด" : isPathName() === "online" ? "ดาวน์โหลดข้อมูลโอนคืนเงินออนไลน์" : "ดาวน์โหลดข้อมูลโอนคืน ALL Point, M-stamp และ ชดเชย"}</h1>
                                {
                                    !isLoading &&
                                    permission?.length !== 0 &&
                                    (
                                        <div className="row align-items-center">
                                            <Table
                                                data={rawData}
                                                columns={columns}
                                                defaultColumn={defaultColumn}
                                                setIsNoData={setIsNoData}
                                                isNoData={isNoData}
                                                parentPage={"downloadFile"}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <PermissionModal permission={permission} backPage={"/grrs"} />
        </Container>
    );
}

export default AccountingDownloadFile;