import axios from "axios";
import { checkAccessToken } from "../../helper/grrs/grrsHelper";

const GRRS_URL = process.env.REACT_APP_GRRS_API_URL;
const GRRS_AUTH = () =>
  `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
const X_API_KEY = process.env.REACT_APP_GRRS_X_API_KEY;

const getCancellationRequestFilter = async () => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/filter`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postCancellationRequestList = async (searchData) => {
  const body = searchData;
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postCancellationRequestApproval = async (searchData) => {
  const body = searchData;
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/approval`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getCancellationReqRefundFilter = async () => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/refund/filter`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

export {
  getCancellationRequestFilter,
  postCancellationRequestList,
  getCancellationReqRefundFilter,
  postCancellationRequestApproval,
};
