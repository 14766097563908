import "../../../../pages/accounting/Accounting.scss";
import { downloadCancelReqRefundFile } from "../../../../../../service/grrs/accounting.service";

const FileDownload = require("js-file-download");

const initial = "initial";
const inProgress = "in_progress" || "in progress";
const completed = "completed";

const downloadFile = (fileId, fileName) => {
  downloadCancelReqRefundFile(fileId, fileName).then((res) => {
    const contentDisposition = res?.headers["content-disposition"];
    if (res !== null && contentDisposition !== undefined) {
      const filename = contentDisposition.split("filename*=utf-8''")[1].trim();
      FileDownload(res.data, decodeURI(filename));
    }
  });
};

const splitDateTime = (date) => {
  if (date !== undefined) {
    return (
      <>
        <p className="no-space">{date.split(" ")[0]}</p>
        <p className="no-space">{date.split(" ")[1]}</p>
      </>
    );
  }
  return <></>;
};

const checkNullValue = (value) => {
  return value === null ? "-" : value;
};

const checkNullDate = (date) => {
  return date === null ? "-" : splitDateTime(date);
};

export const COLUMNS = [
  {
    Header: <p className="grrs-download-file-no">ลำดับ</p>,
    accessor: "file_no",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => (
      <p className="grrs-download-file-no">{row.original?.file_no}</p>
    ),
  },
  {
    Header: "Filename",
    accessor: "file_name",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.file_name),
  },
  {
    Header: "Created",
    accessor: "file_create_datetime",
    disableSortBy: true,
    Cell: ({ row }) => checkNullDate(row.original?.file_create_datetime),
  },
  {
    Header: "ผู้ดำเนินการ",
    accessor: "file_create_by",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.file_create_by),
  },
  {
    Header: "ดาวน์โหลด",
    accessor: "file_url",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => (
      <p
        className={
          row.original?.file_status.toLowerCase() === initial ||
          row.original?.file_status.toLowerCase() === inProgress
            ? "grrs-download-in-progress"
            : row.original?.file_status.toLowerCase() === completed
            ? "grrs-download-link"
            : "grrs-download-error"
        }
        onClick={() =>
          downloadFile(row.original?.file_id, row.original?.file_name)
        }
      >
        {row.original?.file_status.toLowerCase() === initial ||
        row.original?.file_status.toLowerCase() === inProgress
          ? "กำลังสร้างไฟล์"
          : row.original?.file_status.toLowerCase() === completed
          ? "ดาวน์โหลด"
          : "สร้างไฟล์ไม่สำเร็จ"}
      </p>
    ),
  },
];
