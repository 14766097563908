import RoleAddRoleMain from "../components/roleManagement/RoleAddRoleMain";
function RoleAddRole() {
  return (
    <div>
      <RoleAddRoleMain />
    </div>
  );
}

export default RoleAddRole;
