const SPECIAL_REDEEM_KEY = "specialRedeemedSummary";
const REDEEM_KEY = "redeemedSummary";
const EARNPOINT_KEY = "earnPoints";
const ALL_MEMBER_CODE = "allmemberPoint";
const M_STAMP_CODE = "mstamp";
const UNIT_POINT = "แต้ม";
const UNIT_BATH = "บาท";

const getAllMemberPointFromList = (pointList) => {
  if (pointList) {
    return pointList
      .filter((c) => c.type === ALL_MEMBER_CODE)
      .filter((c) => c.unit === UNIT_POINT)
      .reduce((sum, c) => sum + Number(c.points), 0);
  }else{
    return 0;
  }
};

const getNonAllMemberPointFromList = (pointList) => {
  if (pointList) {
    return pointList
      .filter((c) => c.type !== ALL_MEMBER_CODE)
      .filter((c) => c.unit === UNIT_BATH)
      .reduce((sum, c) => sum + Number(c.points), 0);
  }else{
    return 0;
  }
};

const getSummaryRefund = (orders) => {
  return {
    refundAllMemberPoint:
      getAllMemberPointFromList(orders[REDEEM_KEY]) +
      getAllMemberPointFromList(orders[SPECIAL_REDEEM_KEY]),
    refundNonAllMemberPoint:
      getNonAllMemberPointFromList(orders[REDEEM_KEY]) +
      getNonAllMemberPointFromList(orders[SPECIAL_REDEEM_KEY]),
    cancelAllMemberPoint: getAllMemberPointFromList(orders[EARNPOINT_KEY]),
    cancelNonAllMemberPoint: getNonAllMemberPointFromList(
      orders[EARNPOINT_KEY]
    ),
  };
};
export { getSummaryRefund };
