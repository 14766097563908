import { useState, useContext } from "react";
import MenuContex from "../../../../context/MenuContext.js";
import Modal from "../UserManagement/Modal";
import ModalWithError from "../UserManagement/ModalWithError";
function RoleAddUserForm({
  username,
  setUsername,
  status,
  setStatus,
  postRoleAPI,
  isUserError,
  setIsUserError,
  setUserNameWithValidate,
  isUsernameIsValid,
  isModal,
  setIsModal,
  handleClose,
  handleShow,
  goMain,
  errorCode,
  roleName,
}) {
  const { permission } = useContext(MenuContex);
  return (
    <div className="roleAddUser-form w-100 p-5">
      <div class="mb-3">
        <label class="form-label">Email</label>
        <input
          class="form-control"
          value={username}
          onChange={(e) => setUserNameWithValidate(e.target.value)}
          maxLength={50}
        />
        <br />
        {!isUsernameIsValid && (
          <div className="error-usermanagement">
            <b>กรุณากรอก Email ให้ถูกต้องตามรูปแบบ</b>
          </div>
        )}
      </div>

      <div className="mb-3 col-md-6">
        <label className="form-label">Status</label>
        <select
          className="form-select"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="InActive">Inactive</option>
        </select>
      </div>
      <div className="text-center">
        <button className="btn btn-danger mx-5" onClick={goMain}>
          ยกเลิก
        </button>
        <button
          className="btn btn-primary mx-5"
          onClick={() => setIsModal(true)}
          disabled={
            !username ||
            !status ||
            !isUsernameIsValid ||
            !permission.some((res) => res === "create")
          }
        >
          บันทึก
        </button>
      </div>
      <ModalWithError
        handleClose={handleClose}
        submitFunction={postRoleAPI}
        isUserError={isUserError}
        setIsUserError={setIsUserError}
        username={username}
        isModal={isModal}
        setIsModal={setIsModal}
        errorCode={errorCode}
        roleName={roleName}
      />
    </div>
  );
}

export default RoleAddUserForm;
