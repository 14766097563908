import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({ itemsCount, pageSize, onPageSizeChange, currentPage, onPageChange,setCurrentPage }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  const itemsEachPage = _.range(pageSize, itemsCount + pageSize, pageSize);
  useEffect(() => {
    setCurrentPage(1);
  }, [itemsCount]);
  function goToNextPage() {

    if (!(currentPage === pagesCount)) {
      if (isNaN(currentPage)) {
        onPageChange(1);
      } else {
        onPageChange(currentPage + 1);
      }
    }
  }

  function goToPreviousPage() {
    if (!(currentPage <= 1)) {
      if (isNaN(currentPage)) {
        onPageChange(1);
      } else {
        onPageChange(currentPage - 1);
      }
    }
  }

  function goToPageByNumber(e) {
    let value;
    value = e.target.value > pagesCount ? pagesCount : e.target.value;
    onPageChange(parseInt(value));

  }

  return (
    <div className="total-page">
      <span className="index-page col-12 col-sm-6">
        <div className="col-3 col-sm-3 col-md-2">
          <select
            className="form-select search-select"
            aria-label="Default select example"
            name="status"
            onChange={(event) => {
              onPageSizeChange(event);
            }}
          >
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
          </select>
        </div>
        {currentPage === 1 ? <h4>รายการทั้งหมด 1-{itemsEachPage[currentPage - 1]} จากทั้งหมด {itemsCount} รายการ</h4> :
          (currentPage === pagesCount ? <h4>รายการทั้งหมด {itemsEachPage[currentPage - 2] + 1} - {itemsCount} จากทั้งหมด {itemsCount} รายการ</h4> : <h4>รายการทั้งหมด {itemsEachPage[currentPage - 2] + 1} - {itemsEachPage[currentPage - 1]} จากทั้งหมด {itemsCount} รายการ</h4>)
        }
      </span>
      <span className="button-indexPage">
        <button onClick={() => goToPreviousPage()} className={currentPage !== 1 ? 'btn-prePage' : 'btn-prePage faded'}> &#60; </button>
        <div className="card" id="pagination-card">
          <div className="count-page">
            <div className="input-group">
            <input
              className="form-control"
              name="tel"
              type="number"
              min="1"
              value={currentPage}
              onChange={(event) => {
                goToPageByNumber(event);
              }}
            />  
            <span className="input-group-text">OF {pagesCount}</span>
          </div>
          </div>
        </div>
        <button onClick={() => goToNextPage()} className={currentPage !== pagesCount ? 'btn-nextPage' : 'btn-nextPage faded'}> &#62; </button>
      </span>
      <br />
    </div>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
