import React, { useEffect, useRef, forwardRef } from "react";
import '../../../../../../scss/common/utils.scss';

const Checkbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input className="common-checkbox form-check-input" type="checkbox" ref={resolvedRef} {...rest}/>
            </>
        )
    }
)

export default Checkbox;