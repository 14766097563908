import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import CustomerInfo from "../../components/Share/FormRequestCancel/customerInfo";
import DetailCancel from "../../components/Share/FormRequestCancel/detailCancel";
import Reason from "../../components/Share/FormRequestCancel/reason";
import ChannelRefund from "../../components/Share/FormRequestCancel/channelRefund";
import SummaryRefundCancel from "../../components/Share/FormRequestCancel/summaryRefundCancel";
import TrackStatus from "../../components/Share/FormRequestCancel/trackStatus";
import TransactionHistory from "../../components/Share/FormRequestCancel/transactionHistory";
import TransferMoneyModal from "../../components/Share/TransferMoneyModal";
import ApproveOrderCancel from "../../components/CustomerService/FormRequestCancel/approveOrderCancel";
import CheckRefund from "../../components/Accounting/FormRequestCancel/checkRefund.jsx";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import "./Accounting.scss";
import { getOrderCancellationReqDetApprv, getCancellationRefundMaster } from "../../../../service/grrs/customer.service";
import { putRefundCancellationReqApprv } from "../../../../service/grrs/accounting.service";
import { getOrderCancellationRequestTimeline } from "../../../../service/grrs/contactCenter.service";
import { getSummaryRefund } from "../../../../helper/grrs/redeemAndEarnPointHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PopupWaitingForCustomerEdit from "../../components/Share/PopupWaitingForCustomerEdit";

const initCancellationRefundMaster = {
  reason_list: [],

  refund_channel: [],

  bank_master: {
    bank_account: [],

    bank_account_type: [],
  },
  cancel_policy_link: "",
};

const initCancellationInitial = {
  tracking_timeline: [],
  orders: {
    is_can_update_refund_method: 1,
    cs_approve: null,
    total_price: 0,
    redeemedSummary: [],
    earnPoints: [],
    order_detail_list: [],
    invoice_detail_list: [],
    request_activity_log: [],
    invoice_value: 0,
    refund_channel_name: "",
  },
};

const initReason = {
  reason_code: "",
  reason_remark: "",
  is_all_member_point_compensation: 0,
  cc_remark: "",
};

const initChannelRefund = {
  channel_code: "",
  bank_account_type_code: "",
  promtpay_no: "",
  bank_code: "",
  account_no: "",
  account_name: "",
};

const initApproveOrderCancel = {
  is_approve: 1,
  cs_reason_code: "",
  cs_remark: "",
  all_member_point_compensation: 0,
  is_status_urgent: 0,
};
const initTrackingData = {
  orders: {
    order_tracking_status: [],
    tracking_timeline: [],
  },
};

const initCheckRefund = {
  customer_request_no: "",
  is_approve: "",
  remark: "",
};

const initWaitingForCustomerEdit = {
  result: false,
  backUrlPath: "",
  openModal: false
}

function RequestCancel() {
  let { customerRequestNo, orderNo, ordersource } = useParams();
  customerRequestNo = parseInt(customerRequestNo);
  orderNo = parseInt(orderNo);

  const { permission } = useContext(MenuContext);
  const [isCashMode, setIsCashMode] = useState(false);
  const [isHavePoint, setIsHavePoint] = useState(false);
  const [trackingData, setTrackingData] = useState(initTrackingData);
  const [requestStatus, setRequestStatus] = useState("");
  const [isCanEditChannelRefund, setIsCanEditChanngelRefund] =
    useState(false);
  const [channelRefundForm, setChannelRefundForm] = useState(initChannelRefund);

  const [cancellationRefundMaster, setCancellationRefundMaster] = useState(
    initCancellationRefundMaster
  );

  const [cancellationInitial, setCancellationInitial] = useState(
    initCancellationInitial
  );

  const [approveOrderCancel, setApproveOrderCancel] = useState(
    initApproveOrderCancel
  );

  const [checkRefund, setCheckRefund] = useState(initCheckRefund);

  const [reason, setReason] = useState(initReason);

  const [transferMoneyModalState, setTransferMoneyModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedBtnType, setSelectedBtnType] = useState("");

  const [waitingForCustomerEdit, setWaitingForCustomerEdit] = useState(initWaitingForCustomerEdit);

  const getOrderCancellationReqDetApprvFromApi = () => {
    getOrderCancellationReqDetApprv(customerRequestNo).then((response) => {
      if (response?.response_data?.orders !== undefined) {
        setCancellationInitial(response?.response_data);
        setReason(response?.response_data?.orders?.reason);
        setChannelRefundForm(response?.response_data?.orders?.refund_method);
        setRequestStatus(response?.response_data?.orders?.request_refund_status_code);
        if (response?.response_data?.orders?.cs_approve != null) {
          setApproveOrderCancel(response?.response_data?.orders?.cs_approve);
        }
        if (response?.response_data?.orders?.reason.is_all_member_point_compensation === 1) {
          setIsHavePoint(true);
        }
      }
      setIsLoading(false);
    });
  };

  const getOrderCancellationRequestTimelineFromApi = () => {
    getOrderCancellationRequestTimeline(customerRequestNo).then((response) => {
      setTrackingData(response?.response_data);
    });
  };

  const getCancellationRefundMasterFromApi = () => {
    getCancellationRefundMaster(orderNo, ordersource).then((res) => {
      if (res?.response_data !== undefined) {
        setCancellationRefundMaster(res?.response_data);
        if (res?.response_data?.refund_channel.length === 1) {
          setIsCashMode(false);
        } else {
          setIsCashMode(true);
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderCancellationReqDetApprvFromApi();
    getCancellationRefundMasterFromApi();
    getOrderCancellationRequestTimelineFromApi();
  }, []);

  const handleConfirm = () => {
    if (checkRefund.is_approve === 1 || checkRefund.is_approve === "1") {
      setTransferMoneyModalState(true);
    } else {
      document.getElementById("approve-refund-request-cancel-confirm").disabled = true;
      putRefundCancellationReqApprv(generatePutData()).then((res) => {
        if (res?.response_code !== undefined && res?.response_code === 100) {
          setWaitingForCustomerEdit({
            result: true,
            backUrlPath: "/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด",
            openModal: true
          });
        } else {
          setWaitingForCustomerEdit({
            result: false,
            backUrlPath: "/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด",
            openModal: true
          });
        }
      });
    }
  };

  const generatePutData = () => {
    return {
      customer_request_no: customerRequestNo,
      is_approve: checkRefund.is_approve,
      remark: checkRefund.remark,
    };
  };

  const handleSubmit = () => {
    const URL_PATH = isCashMode ? "/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด" : "/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินออนไลน์";
    document.getElementById("transfer-refund-submit").disabled = true;
    putRefundCancellationReqApprv(generatePutData()).then((res) => {
      history.push({ pathname: URL_PATH, state: { response: res, selectedBtnType: selectedBtnType } });
    });

    const closeButton = document.getElementById("transfer-money-close-btn");
    closeButton.click();
  }

  const disabledCheckRefund = (checkRefund) => {
    if (checkRefund.is_approve !== "") {
      return false;
    }
    return true;
  };

  let history = useHistory();
  const goBack = () => {
    if (isCashMode) {
      history.push("/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด");
    } else {
      history.push("/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินออนไลน์");
    }
  };

  const {
    refundAllMemberPoint,
    refundNonAllMemberPoint,
    cancelAllMemberPoint,
    cancelNonAllMemberPoint,
  } = getSummaryRefund(cancellationInitial.orders);

  const getBankText = () => {
    if (document.getElementById("bank-text") !== null) {
      const bankIndex = document.getElementById("bank-text").selectedIndex;
      const bankText =
        document.getElementById("bank-text").options[bankIndex].text;
      return bankText;
    }
    return "";
  };

  const getChannelText = () => {
    if (
      document.getElementById("channel-text") !== null &&
      document.getElementById("channel-text") !== undefined
    ) {
      const channelIndex =
        document.getElementById("channel-text").selectedIndex;
      if (
        document.getElementById("channel-text").options[channelIndex] !==
        undefined
      ) {
        const channelText =
          document.getElementById("channel-text").options[channelIndex].text;
        return channelText;
      }
    }
    return "";
  };

  useEffect(() => {
    if (checkRefund?.is_approve !== undefined) {
      if (checkRefund?.is_approve === 1) {
        setSelectedBtnType("approve");
      } else setSelectedBtnType("reject");
    }
  }, [checkRefund?.is_approve])

  const data = {
    isCashMode: isCashMode,
    payment_channel_name: cancellationInitial.orders.payment_channel_name,
    invoice_value: cancellationInitial.orders.invoice_value,
    refund_channel_name: cancellationInitial.orders.refund_channel_name,
    refund_method: {
      bank_text: getBankText(),
      channel_text: getChannelText(),
      channel_code: channelRefundForm.channel_code,
      bank_account_type_code: channelRefundForm.bank_account_type_code,
      promtpay_no: channelRefundForm.promtpay_no,
      bank_code: channelRefundForm.bank_code,
      account_no: channelRefundForm.account_no,
      account_name: channelRefundForm.account_name,
    },

    total_refund: {
      total_refund: cancellationInitial.orders.total_price,
      return_amp: refundAllMemberPoint,
      return_m_stamp: refundNonAllMemberPoint,
      cancel_amp: cancelAllMemberPoint,
      cancel_m_stamp: cancelNonAllMemberPoint,
      amp: approveOrderCancel.all_member_point_compensation,
    },
  };

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content pb-5">
              <LoadingSpinner isLoading={isLoading} />
              <div className="container accounting-request-cancel-container">
                <span>
                  <img
                    src="/assets/icons/backicon.svg"
                    alt="back"
                    className="back-icon"
                    onClick={goBack}
                  />
                </span>
                <span className="h1-header">
                  ทำการยกเลิกสินค้า #{orderNo}
                </span>
                {!isLoading && (
                  <>
                    <TrackStatus cancellationInitial={cancellationInitial} />
                    <CustomerInfo
                      cancellationInitial={cancellationInitial}
                      isHaveTrackingModal={true}
                      trackingData={trackingData}
                    />
                    <DetailCancel cancellationInitial={cancellationInitial} />
                    <Reason
                      reason={reason}
                      setReason={setReason}
                      isCanEdit={false}
                      cancellationRefundMaster={cancellationRefundMaster}
                    />
                    <ChannelRefund
                      cancellationInitial={cancellationInitial}
                      isCashMode={isCashMode}
                      cancellationRefundMaster={cancellationRefundMaster}
                      isCanEdit={isCanEditChannelRefund}
                      setIsCanEdit={setIsCanEditChanngelRefund}
                      channelRefundForm={channelRefundForm}
                      setChannelRefundForm={setChannelRefundForm}
                      IsCanChangeEditMode={false}
                    />
                    <SummaryRefundCancel
                      cancellationInitial={cancellationInitial}
                      refundAllMemberPoint={refundAllMemberPoint}
                      refundNonAllMemberPoint={refundNonAllMemberPoint}
                      cancelAllMemberPoint={cancelAllMemberPoint}
                      cancelNonAllMemberPoint={cancelNonAllMemberPoint}
                    />
                    {isCashMode === true && (
                      <ApproveOrderCancel
                        isCanEdit={false}
                        isFullForm={true}
                        isHasApproveOption={true}
                        isHasWaitingOption={false}
                        cancellationRefundMaster={cancellationRefundMaster}
                        approveOrderCancel={approveOrderCancel}
                        setApproveOrderCancel={setApproveOrderCancel}
                        isCashMode={isCashMode}
                      />
                    )}
                    {(isCashMode === false && isHavePoint) && (
                      <ApproveOrderCancel
                        isCanEdit={false}
                        isFullForm={false}
                        isHasApproveOption={true}
                        isHasWaitingOption={false}
                        cancellationRefundMaster={cancellationRefundMaster}
                        approveOrderCancel={approveOrderCancel}
                        setApproveOrderCancel={setApproveOrderCancel}
                        isCashMode={isCashMode}
                      />
                    )}
                    <TransactionHistory
                      cancellationInitial={cancellationInitial}
                    />
                    {isCashMode && requestStatus === "4" && permission.some((res) => res === "update") && (
                      <CheckRefund
                        isCanEdit={true}
                        checkRefund={checkRefund}
                        setCheckRefund={setCheckRefund}
                        isHasApproveOption={true}
                        isHasWaitingOption={true}
                      />
                    )}
                    {!isCashMode && requestStatus === "4" && permission.some((res) => res === "update") && (
                      <CheckRefund
                        isCanEdit={true}
                        checkRefund={checkRefund}
                        setCheckRefund={setCheckRefund}
                        isHasApproveOption={true}
                        isHasWaitingOption={false}
                      />
                    )}
                    <div className="text-center footer-section">
                      {requestStatus !== "4" && (
                        <button
                          className="btn btn accounting request-cancel grrs-primary-button"
                          onClick={goBack}
                        >
                          ย้อนกลับ
                        </button>
                      )}
                      {requestStatus === "4" && (
                        <>
                          <button
                            className="btn accounting request-cancel grrs-white-button"
                            onClick={goBack}
                          >
                            ย้อนกลับ
                          </button>
                          <button
                            className="btn accounting request-cancel grrs-primary-button"
                            id="approve-refund-request-cancel-confirm"
                            onClick={handleConfirm}
                            disabled={disabledCheckRefund(checkRefund)}
                            hidden={!permission.some((res) => res === "update")}
                          >
                            ยืนยัน
                          </button>
                        </>
                      )
                      }
                    </div>
                    <TransferMoneyModal
                      data={data}
                      transferMoneyModalState={transferMoneyModalState}
                      setTransferMoneyModalState={setTransferMoneyModalState}
                      handleSubmit={handleSubmit}
                    />
                    <PopupWaitingForCustomerEdit waitingForCustomerEdit={waitingForCustomerEdit} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default RequestCancel;
