import React, { useEffect, useContext } from "react";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";
import { Col, Container, Row } from "react-bootstrap";

import Blank from "../../common/components/Blank";

function BlankGRRS(props) {
  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content">
              <Blank />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default BlankGRRS;
