const getStatus = (status) => {
  switch (status) {
    case "O1":
      return "จัดส่งสำเร็จแล้ว";
    case "O2":
      return "ยกเลิกการจัดส่ง";
    case "O3":
      return "จัดส่งใหม่อีกครั้งกรณีติดต่อลูกค้าได้แล้ว";
    case "O4":
      return "เปลี่ยนเป็นรับที่ร้าน";
    case "N1":
      return "ติดต่อลูกค้าไม่ได้";
    case "N2":
      return "ร้านปิดให้บริการ";
    case "N3":
      return "อยู่นอกพื้นที่จัดส่ง";
    case "C1":
      return "CS กำลังติดต่อ";
    case "C2":
      return "CS กำลังทำเรื่องเก็บกลับคืนคลัง";
    case "C3":
      return "CS ติดต่อไม่สำเร็จ";
    case "C4":
      return "CS นัดวันรับสำเร็จ";
    case "C5":
      return "ดำเนินการเรียกเก็บคืนคลังแล้ว";
    case "C6":
      return "ดำเนินการเรียบร้อยแล้ว (คลังส่งของแล้ว)";
    case "C7":
      return "ลูกค้ายกเลิก";
    case "C8":
      return "อยู่ระหว่างดำเนินการ (คืนคลัง)";
    case "C9":
      return "อยู่ระหว่างดำเนินการ (นอกพื้นที่จัดส่ง)";
    case "L1":
      return "อยู่ในกระบวนการจัดส่ง";
    case "L2":
      return "สถานะ parcel อื่นๆ นอกจาก scheduled";
    default:
      return "-";
  }
};

export { getStatus };
