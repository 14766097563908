import React, { useState, useEffect } from "react";
import CustomerInfo from "../../components/Share/FormRequestCancel/customerInfo";
import DetailCancel from "../../components/Share/FormRequestCancel/detailCancel";
import Reason from "../../components/Share/FormRequestCancel/reason";
import ChannelRefund from "../../components/Share/FormRequestCancel/channelRefund";
import SummaryRefundCancel from "../../components/Share/FormRequestCancel/summaryRefundCancel";
import TrackStatus from "../../components/Share/FormRequestCancel/trackStatus";
import TransactionHistory from "../../components/Share/FormRequestCancel/transactionHistory";
import TransferMoneyModal from "../../components/Share/TransferMoneyModal";
import ApproveOrderCancel from "../../components/CustomerService/FormRequestCancel/approveOrderCancel";
import {
  getCancellationRefundMaster,
  getOrderCancellationReqDetApprv,
  putCancellationRequestApprv,
} from "../../../../service/grrs/customer.service";

import { Col, Container, Row } from "react-bootstrap";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { getOrderCancellationRequestTimeline } from "../../../../service/grrs/contactCenter.service";

import { getSummaryRefund } from "../../../../helper/grrs/redeemAndEarnPointHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import { useContext } from "react";
import PopupWaitingForCustomerEdit from "../../components/Share/PopupWaitingForCustomerEdit";
import CheckRefund from "../../components/Accounting/FormRequestCancel/checkRefund.jsx";

const initCancellationRefundMaster = {
  reason_list: [],

  refund_channel: [],

  bank_master: {
    bank_account: [],

    bank_account_type: [],
  },
  cancel_policy_link: "",
};

const initCancellationInitial = {
  tracking_timeline: [],
  orders: {
    is_can_update_refund_method: 1,
    cs_approve: null,
    total_price: 0,
    redeemedSummary: [],
    earnPoints: [],
    order_detail_list: [],
    invoice_detail_list: [],
    request_activity_log: [],
    invoice_value: 0,
    refund_channel_name: "",
  },
};

const initReason = {
  reason_code: "",
  reason_remark: "",
  is_all_member_point_compensation: false,
  cc_remark: "",
};

const initChannelRefund = {
  channel_code: "",
  bank_account_type_code: "",
  promtpay_no: "",
  bank_code: "",
  account_no: "",
  account_name: "",
};

const initApproveOrderCancel = {
  is_approve: 1,
  cs_reason_code: "",
  cs_remark: "",
  all_member_point_compensation: 0,
  is_status_urgent: false,
};

const initTrackingData = {
  orders: {
    order_tracking_status: [],
    tracking_timeline: [],
  },
};

const initWaitingForCustomerEdit = {
  result: false,
  backUrlPath: "",
  openModal: false
}

const initCheckRefund = {
  customer_request_no: "",
  is_approve: 2,
  remark: "",
};

const URL_PATH = "/grrs/customer-service/พิจารณายกเลิกการสั่งซื้อ";

function RequestCancel() {
  const { permission } = useContext(MenuContext);
  const isHasUpdatePermission = () => {
    if (permission === null || permission === undefined) return false;
    return permission.filter((x) => x === "update").length > 0;
  };
  const { customerRequestNo, orderNo, ordersource } = useParams();

  const isContactCenter = false;

  const [isCashMode, setIsCashMode] = useState(true);

  const [isCanEditChannelRefund, setIsCanEditChanngelRefund] =
    useState(isContactCenter);
  const [channelRefundForm, setChannelRefundForm] = useState(initChannelRefund);

  const [cancellationRefundMaster, setCancellationRefundMaster] = useState(
    initCancellationRefundMaster
  );

  const [cancellationInitial, setCancellationInitial] = useState(
    initCancellationInitial
  );

  const [approveOrderCancel, setApproveOrderCancel] = useState(
    initApproveOrderCancel
  );

  const [reason, setReason] = useState(initReason);

  const [trackingData, setTrackingData] = useState(initTrackingData);
  const isCSApproveIsNull =
    cancellationInitial?.orders?.cs_approve == null ? true : false;
  const isCanUpdateRefundMethod =
    cancellationInitial?.orders?.is_can_update_refund_method === 1 ? true : false;

  const [transferMoneyModalState, setTransferMoneyModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hasBeenClick, setHasBeenClick] = useState(false);

  const [waitingForCustomerEdit, setWaitingForCustomerEdit] = useState(initWaitingForCustomerEdit);

  const [requestStatus, setRequestStatus] = useState("");
  const [isHaveRefundApproved, setIsHaveRefundApproved] = useState();
  const [checkRefund, setCheckRefund] = useState(initCheckRefund);

  const getOrderCancellationReqDetApprvFromApi = () => {
    getOrderCancellationReqDetApprv(customerRequestNo).then((response) => {
      setCancellationInitial(response?.response_data);
      setReason(response?.response_data?.orders?.reason);
      setChannelRefundForm(response?.response_data?.orders?.refund_method);
      setRequestStatus(response?.response_data?.orders?.request_refund_status_code);
      setIsHaveRefundApproved(response?.response_data?.orders?.refund_approve);
      if (response?.response_data?.orders?.cs_approve !== null) {
        setApproveOrderCancel(response?.response_data?.orders?.cs_approve);
      }
      if (response?.response_data?.orders?.refund_approve !== null) {
        setCheckRefund({
          is_approve: response?.response_data?.orders?.refund_approve?.is_approve,
          remark: response?.response_data?.orders?.refund_approve?.remark,
        });
      }
      setIsLoading(false);
    });
  };

  const getOrderCancellationRequestTimelineFromApi = () => {
    getOrderCancellationRequestTimeline(customerRequestNo).then((response) => {
      setTrackingData(response?.response_data);
    });
  };

  const getCancellationRefundMasterFromApi = () => {
    getCancellationRefundMaster(orderNo, ordersource).then((res) => {
      setCancellationRefundMaster(res?.response_data);
      if (res?.response_data?.refund_channel.length === 1) {
        setIsCashMode(false);
      } else {
        setIsCashMode(true);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderCancellationReqDetApprvFromApi();
    getCancellationRefundMasterFromApi();
    getOrderCancellationRequestTimelineFromApi();
  }, []);

  const {
    refundAllMemberPoint,
    refundNonAllMemberPoint,
    cancelAllMemberPoint,
    cancelNonAllMemberPoint,
  } = getSummaryRefund(cancellationInitial?.orders);

  const getBankText = () => {
    if (document.getElementById("bank-text") !== null) {
      const bankIndex = document.getElementById("bank-text").selectedIndex;
      const bankText =
        document.getElementById("bank-text").options[bankIndex].text;
      return bankText;
    }
    return "";
  };

  const getChannelText = () => {
    if (document.getElementById("channel-text")) {
      const channelIndex =
        document.getElementById("channel-text").selectedIndex;
      if (document.getElementById("channel-text").options[channelIndex]) {
        const channelText =
          document.getElementById("channel-text").options[channelIndex].text;

        return channelText;
      }
    }
    return "";
  };

  const data = {
    isCashMode: isCashMode,
    payment_channel_name: cancellationInitial.orders.payment_channel_name,
    invoice_value: cancellationInitial.orders.invoice_value,
    refund_channel_name: cancellationInitial.orders.refund_channel_name,
    refund_method: {
      bank_text: getBankText(),
      channel_text: getChannelText(),
      channel_code: channelRefundForm.channel_code,
      bank_account_type_code: channelRefundForm.bank_account_type_code,
      promtpay_no: channelRefundForm.promtpay_no,
      bank_code: channelRefundForm.bank_code,
      account_no: channelRefundForm.account_no,
      account_name: channelRefundForm.account_name,
    },

    total_refund: {
      total_refund: cancellationInitial.orders.total_price,
      return_amp: refundAllMemberPoint,
      return_m_stamp: refundNonAllMemberPoint,
      cancel_amp: cancelAllMemberPoint,
      cancel_m_stamp: cancelNonAllMemberPoint,
      amp: approveOrderCancel.all_member_point_compensation,
    },
  };

  const handleConfirm = () => {
    if (approveOrderCancel?.is_approve === 1 || approveOrderCancel?.is_approve === "1" || requestStatus === "3") {
      setTransferMoneyModalState(true);
    } else {
      putCancellationRequestApprv(generatePutData()).then((res) => {
        if (res?.response_code !== undefined && res?.response_code === 100) {
          setWaitingForCustomerEdit({
            result: true,
            backUrlPath: URL_PATH,
            openModal: true
          });
        } else {
          setWaitingForCustomerEdit({
            result: false,
            backUrlPath: URL_PATH,
            openModal: true
          });
        }
      });
    }
  };

  const generateRefundMethod = () => {
    if (
      channelRefundForm.channel_code === "BSA" ||
      channelRefundForm.channel_code === "BCA"
    )
      return {
        channel_code: channelRefundForm.channel_code,
        bank_account_type_code: null,
        promtpay_no: null,
        bank_code: channelRefundForm.bank_code,
        account_no: channelRefundForm.account_no,
        account_name: channelRefundForm.account_name,
      };

    if (channelRefundForm.channel_code === "PP")
      return {
        channel_code: "PP",
        bank_account_type_code: channelRefundForm.bank_account_type_code,
        promtpay_no: channelRefundForm.promtpay_no,
        bank_code: null,
        account_no: null,
        account_name: null,
      };

    return {
      channel_code: channelRefundForm.channel_code,
      bank_account_type_code: null,
      promtpay_no: null,
      bank_code: null,
      account_no: null,
      account_name: null,
    };
  };

  const generatePutData = () => {
    if (isCSApproveIsNull === false) {
      return {
        customer_request_no: customerRequestNo,
        refund_method: generateRefundMethod(),
        cs_approve: null,
      };
    }

    return {
      customer_request_no: customerRequestNo,
      refund_method: generateRefundMethod(),
      cs_approve: {
        is_approve: approveOrderCancel.is_approve ? "1" : "0",
        cs_reason_code: approveOrderCancel.cs_reason_code,
        cs_remark: approveOrderCancel.cs_remark,
        all_member_point_compensation:
          approveOrderCancel.all_member_point_compensation,
        is_status_urgent: approveOrderCancel.is_status_urgent ? "1" : "0",
      },
    };
  };

  const handleSubmit = () => {
    document.getElementById("transfer-refund-submit").disabled = true;
    putCancellationRequestApprv(generatePutData()).then((c) => {
      history.push(URL_PATH);
    });

    const closeButton = document.getElementById("transfer-money-close-btn");
    closeButton.click();
  };

  let history = useHistory();
  const goMain = () => {
    history.push("/grrs/customer-service/พิจารณายกเลิกการสั่งซื้อ");
  };

  const validateChannelRefundForm = (channelRefundForm, isCashMode) => {
    if (!isCashMode) return true;
    if (channelRefundForm.channel_code === "") {
      return false;
    }
    if (
      channelRefundForm.channel_code === "BSA" ||
      channelRefundForm.channel_code === "BCA"
    ) {
      if (channelRefundForm.bank_code === "") return false;
      if (channelRefundForm.account_no === "") return false;
      if (channelRefundForm.account_name === "") return false;

      return true;
    }
    if (channelRefundForm.channel_code === "PP") {
      if (channelRefundForm.bank_account_type_code === "") return false;
      if (channelRefundForm.promtpay_n === "") return false;
      return true;
    }

    return false;
  };

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content pb-5">
              <LoadingSpinner isLoading={isLoading} />
              <div className="container customer-service-container">
                <span>
                  <img
                    src="/assets/icons/backicon.svg"
                    alt="back"
                    className="back-icon"
                    onClick={goMain}
                  />
                </span>
                <span className="h1-header">ทำการยกเลิกสินค้า #{orderNo}</span>
                {!isLoading && (
                  <>
                    <TrackStatus cancellationInitial={cancellationInitial} />
                    <CustomerInfo
                      cancellationInitial={cancellationInitial}
                      isHaveTrackingModal={true}
                      trackingData={trackingData}
                    />
                    <DetailCancel cancellationInitial={cancellationInitial} />
                    <Reason
                      reason={reason}
                      setReason={setReason}
                      isCanEdit={false}
                      cancellationRefundMaster={cancellationRefundMaster}
                    />

                    <ChannelRefund
                      setHasBeenClick={setHasBeenClick}
                      cancellationInitial={cancellationInitial}
                      isCashMode={isCashMode}
                      cancellationRefundMaster={cancellationRefundMaster}
                      isCanEdit={isCanEditChannelRefund}
                      setIsCanEdit={setIsCanEditChanngelRefund}
                      channelRefundForm={channelRefundForm}
                      setChannelRefundForm={setChannelRefundForm}
                      IsCanChangeEditMode={
                        isCanUpdateRefundMethod && isHasUpdatePermission()
                      }
                    />

                    {
                      isCashMode &&
                      (requestStatus === "2" || requestStatus === "3" || requestStatus === "4") &&
                      isHaveRefundApproved &&
                      (
                        <CheckRefund
                          isCanEdit={false}
                          checkRefund={checkRefund}
                          setCheckRefund={setCheckRefund}
                          isHasApproveOption={false}
                          isHasWaitingOption={true}
                        />
                      )}

                    <SummaryRefundCancel
                      cancellationInitial={cancellationInitial}
                      refundAllMemberPoint={refundAllMemberPoint}
                      refundNonAllMemberPoint={refundNonAllMemberPoint}
                      cancelAllMemberPoint={cancelAllMemberPoint}
                      cancelNonAllMemberPoint={cancelNonAllMemberPoint}
                    />
                    <TransactionHistory
                      cancellationInitial={cancellationInitial}
                    />
                    {isCSApproveIsNull === true &&
                      isCashMode === true &&
                      isCanUpdateRefundMethod === true &&
                      isHasUpdatePermission() && (
                        <ApproveOrderCancel
                          isCanEdit={true}
                          cancellationRefundMaster={cancellationRefundMaster}
                          approveOrderCancel={approveOrderCancel}
                          setApproveOrderCancel={setApproveOrderCancel}
                          isCash={isCashMode}
                        />
                      )}
                    {isCSApproveIsNull === true &&
                      isCashMode === false &&
                      isCanUpdateRefundMethod === true &&
                      isHasUpdatePermission() && (
                        <ApproveOrderCancel
                          isCanEdit={true}
                          isHasApproveOption={true}
                          isHasWaitingOption={false}
                          cancellationRefundMaster={cancellationRefundMaster}
                          approveOrderCancel={approveOrderCancel}
                          setApproveOrderCancel={setApproveOrderCancel}
                          isCash={isCashMode}
                        />
                      )}
                    {isCSApproveIsNull === false &&
                      isCanUpdateRefundMethod === true &&
                      isHasUpdatePermission() && (
                        <ApproveOrderCancel
                          isCanEdit={false}
                          isHasApproveOption={false}
                          isHasWaitingOption={true}
                          cancellationRefundMaster={cancellationRefundMaster}
                          approveOrderCancel={approveOrderCancel}
                          setApproveOrderCancel={setApproveOrderCancel}
                          isCash={isCashMode}
                        />
                      )}
                    {isCanUpdateRefundMethod === true && (
                      <div className="text-center footer-section ">
                        <button
                          className="btn btn-cancel grrs-white-button "
                          onClick={goMain}
                        >
                          ย้อนกลับ
                        </button>
                        {isCSApproveIsNull === true &&
                          isHasUpdatePermission() && (
                            <button
                              className="btn btn-confirm grrs-primary-button"
                              onClick={handleConfirm}
                              disabled={
                                !(
                                  validateChannelRefundForm(
                                    channelRefundForm,
                                    isCashMode
                                  ) && approveOrderCancel.cs_reason_code !== ""
                                )
                              }
                            >
                              ยืนยัน
                            </button>
                          )}

                        {isCSApproveIsNull === false &&
                          isHasUpdatePermission() && (
                            <button
                              className="btn btn-confirm grrs-primary-button"
                              onClick={handleConfirm}
                              disabled={
                                !(
                                  validateChannelRefundForm(
                                    channelRefundForm,
                                    isCashMode
                                  ) &&
                                  approveOrderCancel.cs_reason_code !== "" &&
                                  hasBeenClick
                                )
                              }
                            >
                              ยืนยัน
                            </button>
                          )}
                      </div>
                    )}
                    {isCanUpdateRefundMethod === false && (
                      <div className="text-center footer-section ">
                        <button
                          className="btn btn-confirm grrs-primary-button"
                          onClick={goMain}
                        >
                          ย้อนกลับ
                        </button>
                      </div>
                    )}
                    <TransferMoneyModal
                      data={data}
                      transferMoneyModalState={transferMoneyModalState}
                      setTransferMoneyModalState={setTransferMoneyModalState}
                      handleSubmit={handleSubmit}
                      urlPath={URL_PATH}
                    />
                    <PopupWaitingForCustomerEdit waitingForCustomerEdit={waitingForCustomerEdit} />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default RequestCancel;
