import React, { useContext, useState, useEffect } from "react";
import BarcodeNumber from "./BarcodeNumber";
import CustomerServiceDetail from "./CustomerServiceDetail";
import MenuContext from "../../../../context/MenuContext";
import { getStatus } from "../../../../helper/freedeliveryHelper";

const InvoiceDetail = ({ invoiceDetailData = {} }) => {
  const { permission } = useContext(MenuContext);

  const [display, setDisplay] = useState(true);
  const [contactStartDate, setContactStartDate] = useState("");
  const [contactStartTime, setContactStartTime] = useState("");
  const [shipToStoreTimestampDate, setShipToStoreTimestmapDate] = useState("");
  const [shipToStoreTimestampTime, setShipToStoreTimestmapTime] = useState("");

  useEffect(() => {
    const arrDateTime =
      invoiceDetailData?.contact_customer_timestamp.split(" ");
    setContactStartDate(arrDateTime[0]);

    const arrinvoiceDetailTime = arrDateTime[1].split(":");
    setContactStartTime(
      `${arrinvoiceDetailTime[0]}:${arrinvoiceDetailTime[1]}`
    );

    const shipToStoreTimestamp =
      invoiceDetailData?.ship_to_store_timestamp.split(" ");
    setShipToStoreTimestmapDate(shipToStoreTimestamp[0]);

    const arrStoreTime = shipToStoreTimestamp[1].split(":");
    setShipToStoreTimestmapTime(`${arrStoreTime[0]}:${arrStoreTime[1]}`);
  }, []);

  function toggleButton() {
    if (display) setDisplay(false);
    else setDisplay(true);
  }

  const status = (status) => {
    return getStatus(status);
  };

  return (
    <>
      <div className="card-body invoicedetail" id="customerinfo-card-body">
        <div className="title-invoicedetail">
          <button className={display ? "rotate" : ""} onClick={toggleButton}>
            <span className="button-rotate">&#707;</span>
          </button>
          <span>Invoice : {invoiceDetailData?.invoice_no}</span>
        </div>
      </div>
      <div className={display ? "visable-invoice-form" : "disable"}>
        <div className="row-1">
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              วัน-เวลาที่สินค้าส่งถึงร้าน
            </label>
            <input
              className="form-control"
              value={`${shipToStoreTimestampDate} ${shipToStoreTimestampTime}`}
              type="text"
              disabled
              readonly
            />
          </div>
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              จำนวนวันที่อยู่ที่ร้าน
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.date_at_branch}
              type="text"
              disabled
              readonly
            />
          </div>
        </div>
        <div className="row-1">
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              วันที่เริ่มติดต่อลูกค้า
            </label>
            <input
              className="form-control"
              value={contactStartDate}
              type="text"
              disabled
              readonly
            />
          </div>
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              เวลาที่เริ่มติดต่อลูกค้า
            </label>
            <input
              className="form-control"
              value={contactStartTime}
              type="text"
              disabled
              readonly
            />
          </div>
        </div>
        <div className="row-1">
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              สถานะการติดต่อลูกค้า
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.contact_customer_status}
              type="text"
              disabled
              readonly
            />
          </div>
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              ลูกค้าปักสถานที่ผิด
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.contact_customer_detail}
              type="text"
              disabled
              readonly
            />
          </div>
        </div>
        <div className="row-1">
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              พนักงานที่จัดส่งสินค้า
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.deliver_staff_name}
              type="text"
              disabled
              readonly
            />
          </div>
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              สายส่ง
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.route_id}
              type="text"
              disabled
              readonly
            />
          </div>
        </div>
        <div className="row-1">
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              รหัสคลัง
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.warehouse_id}
              type="text"
              disabled
              readonly
            />
          </div>
          <div className="col-md-5">
            <label for="exampleFormControlInput1" className="form-label">
              ชื่อคลัง
            </label>
            <input
              className="form-control"
              value={invoiceDetailData?.warehouse_name}
              type="text"
              disabled
              readonly
            />
          </div>
        </div>
        <BarcodeNumber invoiceDetailData={invoiceDetailData?.box_data} />
        {
          (invoiceDetailData.order_status === "C4" || invoiceDetailData.order_status === "L1") ? null:
            permission.some((res) => res === "create") ? (
            <CustomerServiceDetail invoiceDetailData={invoiceDetailData} />
            ) : null
        }
        <br />
      </div>
    </>
  );
};

export default InvoiceDetail;
