import axios from "axios";
const ROLE_MANAGETMENT_URL = process.env.REACT_APP_API_URL;

const X_API_KEY_ROLE = process.env.REACT_APP_ROLE_MANAGEMENT_X_API_KEY;

const getMenu = async () => {
  // console.log(ROLE_MANAGETMENT_URL, "path");
  const data = await axios
    .get(`${ROLE_MANAGETMENT_URL}/role/master/menu`, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return [];
    });
  return data;
};

const getRole = async (roleName) => {
  const data = await axios
    .get(`${ROLE_MANAGETMENT_URL}/role`, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
      params: {
        roleName: roleName,
      },
    })
    .then((res) => {
      if (res.data.response_code == 301)
        return { data: { response_data: { role_list: [] } } };
      return res;
    })
    .catch((e) => {
      return { data: { response_data: { role_list: [] } } };
    });
  return data;
};

const getRoleById = async (roleId) => {
  const data = await axios
    .get(`${ROLE_MANAGETMENT_URL}/role/detail?roleId=${roleId}`, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return [];
    });
  return data;
};

const getUserByRoleId = async (roleId) => {
  const data = await axios
    .get(`${ROLE_MANAGETMENT_URL}/role/user?roleId=${roleId}&username=`, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return [];
    });
  return data;
};

const getUserByRoleIdAndUsername = async (roleId, username) => {
  const data = await axios
    .get(`${ROLE_MANAGETMENT_URL}/role/user`, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
      params: {
        roleId: roleId,
        username: username,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return [];
    });
  return data;
};

const postRole = async (request, callback, errorCallback) => {
  const body = request;
  try {
    const response = await axios.post(`${ROLE_MANAGETMENT_URL}/role`, body, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
    });
    if (response.data.response_code === 100) {
      callback();
      return response.data.response_data;
    } else {
      errorCallback();
      return [];
    }
  } catch (err) {
    errorCallback();

    return [];
  }
};

const putRole = async (request, callback) => {
  const body = request;
  try {
    const response = await axios.put(`${ROLE_MANAGETMENT_URL}/role`, body, {
      headers: {
        "x-api-key": X_API_KEY_ROLE,
      },
    });
    if (response.data.response_code === 100) {
      callback();
      return response.data.response_data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

const postUserByRole = async (request, callback, error) => {
  const body = request;
  try {
    const response = await axios.post(
      `${ROLE_MANAGETMENT_URL}/role/user`,
      body,
      {
        headers: {
          "x-api-key": X_API_KEY_ROLE,
        },
      }
    );
    if (response.data.response_code === 100) {
      callback();

      return response.data.response_data;
    } else if (response.data.response_code === 202) {
      error(202);
      return [];
    } else if (response.data.response_code == 301) {
      error(301);
      return [];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export {
  getMenu,
  getRole,
  getUserByRoleId,
  postRole,
  getUserByRoleIdAndUsername,
  getRoleById,
  postUserByRole,
  putRole,
};
