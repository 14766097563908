import React from "react";
import { postImportRefundCancelReqValid } from "../../../../../service/grrs/accounting.service";
import "./Popup.scss";

function Upload({ uploadFileResponse, setCurrentUploadFile, currentUploadFile }) {

    const handleClose = () => {
        document.getElementById("upload-modal-close-btn").click();
    }

    const handleImportFile = (e) => {
        setCurrentUploadFile(e.target.files[0]);
    }

    const handleClickUploadBtn = () => {
        document.getElementById("grrs-approve-refund-import").value = null;
        document.getElementById("grrs-approve-refund-import").click();
    }

    const handleUploadFile = () => {
        document.getElementById("grrs-approve-refund-upload").disabled = true;
        postImportRefundCancelReqValid(currentUploadFile).then((res) => {
            uploadFileResponse(res);
        });
    }

    return (
        <>
            <button type="button" id="upload-file-btn" className="btn btn-primary upload-file-modal-button" data-bs-toggle="modal" data-bs-target="#uploadFile" hidden>
                ตกลง
            </button>
            <div className="uploadFileModal">
                <div className="modal fade" id="uploadFile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="uploadFileLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="checkingPaymentMethodLabel">อัปโหลดข้อมูล</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="upload-modal-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                <div className="border-top"></div>
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                        <div class="input-group approve-refund-upload-file">
                                            <input class="form-control upload-file-name" type="text" value={currentUploadFile?.name === undefined ? "" : currentUploadFile?.name} readOnly />
                                            <button class="btn grrs-primary-button upload-file-button" onClick={handleClickUploadBtn}>อัปโหลดไฟล์</button>
                                            <input class="form-control" type="file" id="grrs-approve-refund-import" onChange={(e) => handleImportFile(e)} hidden />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center btn-section">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn grrs-white-button upload-cancel-btn" onClick={handleClose}>ยกเลิก</button>
                                        <button type="button" className="btn grrs-primary-button upload-confirm-btn" id="grrs-approve-refund-upload" onClick={handleUploadFile} disabled={currentUploadFile?.name === undefined}>ยืนยัน</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default Upload;