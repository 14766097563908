import React from "react";
import "./LoadingSpinner.scss";

function LoadingSpinner({ isLoading }) {
    return (
        <>
            {
                isLoading && (
                    <>
                        <div className="d-flex justify-content-center" id="grrs-spinner-div">
                            <div className="spinner-border text-primary" id="grrs-spinner" role="status">
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
};

export default LoadingSpinner;