import React, { useEffect, useState, useContext } from "react";
import { getAccessIdData, logout } from "../../../service/auth.service";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { Image } from "react-bootstrap";
import MenuContext from "../../../context/MenuContext";

const User = ({ userRole }) => {
  const { profileUser } = useContext(MenuContext);

  return (
    <div className="auth">
      <Image id="user-icon-menu"
        src={
          process.env.PUBLIC_URL +
          "/assets/icons/user-icon.svg"
        }
        rounded
      />
      <div className="user" id="menu-user-data">
        <div>
          <div className="d-flex">
          <h4 id="data-user-text">{profileUser?.name}</h4>
          <Dropdown as={ButtonGroup} id="menu-dropdown" className="user-dropdown" >
          <Dropdown.Toggle split variant="default" id="menu-dropdown-tab"  />
          <Dropdown.Menu >
            <Dropdown.Item id="dropdown-option" onClick={logout}>Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>
          <h4 id="data-menu-small-text" >{userRole}</h4>
        </div>
      
      </div>
    </div>
  );
};

export default User;
