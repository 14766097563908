import React, { useState, useEffect } from "react";
import MenuContext from "../../../context/MenuContext";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import UserMobile from "../../common/components/UserMobile";
import User from "../../common/components/User";
import BackMain from "../../common/components/BackMain";
import { getAccessIdData, logout } from "../../../service/auth.service";
import { getMenuByMenuId } from "../../../service/customer-care.service";
import { getSubMenuIdByURL } from "../../../helper/commonHelper";
import Accordion from "react-bootstrap/Accordion";

const SideBar = () => {
  const { menu, setActiveId, setBackSearch } = useContext(MenuContext);
  const [sidebarBackdrop, setSidebarBackdrop] = useState(false);
  const [menulists, setMenulists] = useState([]);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const location = useLocation();
  let buttonClose = document.querySelector(".btn-close");
  let arrPath = location.pathname.split("/");
  let newPath;
  let backPath;

  if (arrPath[1] === "etax") {
    newPath = `/${arrPath[1]}`;
  } else if (arrPath.length <= 3) {
    newPath = `/${arrPath[1]}/${arrPath[2]}`;
  } else if (arrPath.length <= 4) {
    newPath = `/${arrPath[1]}/${arrPath[2]}/${arrPath[3]}`;
  } else {
    newPath = `/${arrPath[1]}/${arrPath[2]}/${arrPath[3]}/${arrPath[4]}`;
  }

  if (arrPath[1] === "etax") {
    backPath = `/${arrPath[1]}`;
  } else {
    backPath = `/${arrPath[1]}/${arrPath[2]}`;
  }
  setBackSearch(backPath);

  const handleOpen = () => {
    setSidebarBackdrop(true);
  };

  const handleClose = () => {
    setSidebarBackdrop(false);
    buttonClose.click();
  };

  const handleClickSubMenu = (menu_id) => {
    setActiveId(menu_id);
    setSidebarBackdrop(false);
    buttonClose.click();
  };

  useEffect(() => {
    const accessIdData = getAccessIdData();
    setUserName(accessIdData.name);
    let menuId = getSubMenuIdByURL(location.pathname);
    if (accessIdData.email && menuId !== "0") {
      getMenuByMenuId(menuId, accessIdData?.email).then((result) => {
        setMenulists(result?.menu_list[0]);
        setUserRole(result?.role[0]);
      });
    } else {
      setMenulists([]);
      setUserRole("");
    }
  }, []);

  return (
    <>
      <div className="large-sidebar">
        <BackMain />
        <User userRole={userRole} />
        <div className="menu">
          <div className="d-flex flex-column flex-shrink-0 select-menu">
            <ul className="nav nav-pills flex-column mb-auto">
              {menulists?.menu_list?.map((m, index) =>
                m.menu_list.length === 0 ? (
                  <li key={index}>
                    <Link
                      role="button"
                      to={m.menu_link}
                      onClick={() => setActiveId(m.menu_id)}
                      className={
                        newPath === m.menu_link
                          ? "btn-primary text-decoration-none items active"
                          : "btn-primary text-decoration-none items"
                      }
                    >
                      {m.menu_name}
                    </Link>
                  </li>
                ) : (
                  <>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{m.menu_name}</Accordion.Header>
                        {m.menu_list.map((e, index) => (
                          <Accordion.Body>
                            {e.menu_list.length === 0 ? (
                              <Link
                                to={e.menu_link}
                                onClick={() => setActiveId(e.menu_id)}
                                className={
                                  newPath.includes(e.menu_link)
                                    ? "btn-primary text-decoration-none items fw-normal active"
                                    : "btn-primary text-decoration-none items fw-normal"
                                }
                              >
                                {e.menu_name}
                              </Link>
                            ) : (
                              <Accordion defaultActiveKey="0" id="childSubMenu">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header id="childSubMenuHeader">
                                    {e.menu_name}
                                  </Accordion.Header>
                                  {e.menu_list.map((eChild, index) => (
                                    <Accordion.Body
                                      id={
                                        index === e.menu_list.length - 1
                                          ? "lastChildSubMenuBody"
                                          : ""
                                      }
                                    >
                                      <Link
                                        to={eChild.menu_link}
                                        onClick={() =>
                                          setActiveId(eChild.menu_id)
                                        }
                                        className={
                                          newPath.includes(eChild.menu_link)
                                            ? "btn-primary text-decoration-none items fw-normal active"
                                            : "btn-primary text-decoration-none items fw-normal"
                                        }
                                      >
                                        {eChild.menu_name}
                                      </Link>
                                    </Accordion.Body>
                                  ))}
                                </Accordion.Item>
                              </Accordion>
                            )}
                          </Accordion.Body>
                        ))}
                      </Accordion.Item>
                    </Accordion>
                  </>
                )
              )}
            </ul>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-dark bg-dark mobile-navbar">
        <div className="container">
          <button
            className="btn btn-dark mobile-navbar-btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebar-menu"
            aria-controls="sidebar-menu"
            data-backdrop="static"
            onClick={() => handleOpen()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand text-white" href="/">
            <img src="/assets/logo/CPALL_logo.svg"></img>
          </a>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start small-sidebar"
        tabindex="-1"
        id="sidebar-menu"
        aria-labelledby="sidebar-menuLabel"
      >
        <div className="offcanvas-header">
          <div className="offcanvas-title" id="sidebar-menuLabel">
            <UserMobile userRole={userRole} />
          </div>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => handleClose()}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="menu">
            <div className="d-flex flex-column flex-shrink-0 select-menu">
              <ul className="nav nav-pills flex-column mb-auto">
                {menulists?.menu_list?.map((m, index) => (
                  <li key={index}>
                    <Link
                      role="button"
                      to={m.menu_link}
                      onClick={() => handleClickSubMenu(m.menu_id)}
                      className={
                        newPath === m.menu_link
                          ? "btn-primary text-decoration-none items active"
                          : "btn-primary text-decoration-none items"
                      }
                    >
                      {m.menu_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="sidebar-footer">
          <button
            className="btn btn-dark mobile-logout-button"
            type="button"
            onClick={logout}
          >
            Log out
          </button>
        </div>
      </div>
      <div
        className={sidebarBackdrop === true ? "sidebar-backdrop fade show" : ""}
        onClick={() => handleClose()}
      ></div>
    </>
  );
};

export default SideBar;
