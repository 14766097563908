import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
const ModalWithError = ({
  submitFunction,
  isUserError = false,
  setIsUserError,
  username,
  setIsModal,
  isModal,
  handleClose,
  errorCode,
  roleName,
}) => {
  if (isUserError) {
    return (
      <Modal show={isModal} onHide={handleClose}>
        <div className="">
          <div className="">
            <div className="header">
              <h4 className="title" id="staticBackdropLabel">
                ไม่สามารถ Add User นี้ได้
              </h4>
            </div>
            {errorCode === 202 && (
              <div className="body">
                <h5>เนื่องจากในระบบมี email นี้อยู่แล้ว</h5>

                <h5>กรุณากรอกข้อมูลใหม่</h5>
              </div>
            )}

            {errorCode === 301 && (
              <div className="body">
                <h5>เนื่องจากระบบไม่มี User {username}</h5>
                <h5>กรุณาเพิ่ม User ที่เมนู User Management</h5>
              </div>
            )}
            <div className="footer">
              <button
                type="submit"
                className="resetbutton"
                data-bs-dismiss="modal"
                onClick={() => {
                  setIsUserError(false);
                  setIsModal(false);
                }}
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <Modal show={isModal} onHide={handleClose}>
      <div className="">
        <div className="">
          <div className="header">
            <h4 className="title" id="staticBackdropLabel">
              ยืนยันข้อมูล
            </h4>
          </div>
          <div className="body">
            <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
          </div>
          <div className="footer">
            <button
              className="resetbutton"
              onClick={() => {
                submitFunction();
              }}
            >
              บันทึก
            </button>
            <button type="button" className="backbutton" onClick={handleClose}>
              แก้ไข
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWithError;
