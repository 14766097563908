import React from "react";
import { useHistory } from "react-router-dom";



const BackMain = () => {
  let history = useHistory()

  const back = () => {
    history.push("/")
  }
  return (
    <>
      <div className="btn-back">
        <button onClick={back}>
          <span><img src="/assets/icons/backiconwhite.svg" alt="back" /></span>
        </button>
        <p>กลับสู่เมนูหลัก</p>
      </div>
    </>
  );
};

export default BackMain;
