import provinceData from '../../utils/address/provinceId.json';
import axios from 'axios';
const etaxApiUrl = process.env.REACT_APP_ETAX_API_URL;
const xApiKey = process.env.REACT_APP_ETAX_API_KEY;

export const listProvince = async () => {
    return provinceData;
}

export const listAmphure = async (id) => {
    const config = {
        method: 'get',
        url: `${etaxApiUrl}/mobile/province/getById/?provinceId=${id}`,
        headers: {
            'Authorization': 'Basic b3JjaGlkOjU0WHJ+RnJ6bA==',
            'Content-Type': 'application/json',
            'x-api-key': xApiKey
        }
    };
    const res = await axios(config);
    return res.data.children;
}


export const listTumbon = async (id) => {
    const config = {
        method: 'get',
        url: `${etaxApiUrl}/mobile/province/getById/?districtId=${id}`,
        headers: {
            'Authorization': 'Basic b3JjaGlkOjU0WHJ+RnJ6bA==',
            'Content-Type': 'application/json',
            'x-api-key': xApiKey
        }
    };
    const res = await axios(config);
    return res.data.children;
}
