import React, { useEffect, useContext } from "react";
import Detail from "../components/ecn/DetailECreditNote";
import MenuContext from "../../../context/MenuContext";
import { Col, Container, Row } from "react-bootstrap";
import {
    getMenuByMenuId, getUserPermission,
} from "../../../service/customer-care.service";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";


function EcnDetail(props) {
    const { menu, setMenu, setUserRole, profileUser, setPermission } = useContext(MenuContext);
    // const ecn_id = props.location.values.ecn_id;
    // const { ecn_id } = useParams();

    useEffect(() => {
        if (menu.length === 0 || menu.menu_id !== "42") {
            if (profileUser) {
                getMenuByMenuId("42", profileUser.email).then((res) => {
                    setMenu(res?.menu_list[0]);
                    setUserRole(res?.role[0])
                });

            }
        }
    }, []);
    return (
        <Container>
            <div className="layout">
                <div className="row overflow-auto vh-100">
                    <Row>
                        <ResponsiveSideBar />
                        <Col md={9} className="content">
                            <Detail />
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
}
export default EcnDetail;