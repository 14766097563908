import { useEffect, useState, useContext } from "react";
import {
  getOrderDetailsByOrderNo,
  getOrderSourceList,
} from "../../../../service/ecn/e-creditnote.service";
import { useLocation } from "react-router-dom";
import MenuContext from "../../../../context/MenuContext";

const SearchOrder = (props) => {
  const { permission } = useContext(MenuContext);
  const [searchData, setSearchData] = useState("");
  const [searchKeyData, setSearchKeyData] = useState('');
  const [sourceList, setSourceList] = useState();
  const incomeValue = props.searchValue;
  const incomeSourceValue = props.orderSource;
  const isNoData = props.isNoData;
  const query = new URLSearchParams(useLocation().search);
  const searchOrderByOrderNo = () => {
    let stringSearch = ''
    if (searchData.trim().length > 0) {
      stringSearch = searchData.trim();
      setSearchData(searchData.trim())
    }
    props.setIsLoading(true);
    getOrderDetailsByOrderNo(stringSearch, searchKeyData)
      .then((res) => {
        props.setData(res);
        props.setIsLoading(false);
      })
  };

  const inputHandle = () => {
    const value = document.getElementById("search_order").value;
    if (value === "") {
      setSearchData("");
    } else {
      setSearchData(value);
    }
  };
  const sourceHandle = (e) => {
    setSearchKeyData(e.target.value);
  };
  async function setIncomeValue() {
    setSearchData(incomeValue);
    setSearchKeyData(incomeSourceValue)
  }
  useEffect(async () => {
    await getOrderSourceList().then(res => {
      setSourceList(res.response_data);
    })


    if (incomeValue === "" || incomeValue === null) {
      setSearchData("");
    } else {
      await setIncomeValue();
      props.setIsLoading(true);
      await getOrderDetailsByOrderNo(query.get("taxNo"), query.get("orderSource"))
        .then((res) => {
          props.setData(res);
          props.setIsLoading(false);
        });

    }
  }, []);

  return (
    <>
      <div className="row addEcn-search align-items-center">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xxl-4 mt-3">
          <div className="row align-items-center g-0">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xxl-2 mt-3">
            <label className="addEcn-search-label">ค้นหา : </label>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xxl-8 mt-3">
            <div className="has-validation">
              <input
                type="text"
                // className={isNoData ? "form-control is-invalid" : "form-control"}
                className="form-control"
                id="search_order"
                onInput={() => inputHandle()}
                placeholder="เลขที่คำสั่งซื้อ"
              />
              {/* {isNoData && <div class="invalid-feedback">
                ไม่พบข้อมูลที่ค้นหา
              </div>} */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xxl-5 mt-3">
          <div className="row align-items-center g-0">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xxl-3 addEcn-status mt-3">
              <label className="addEcn-search-label">order source :</label>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xxl-9 addEcn-status mt-3">
              <select
                className="form-select"
                aria-label="Default select example"
                id="search_order_source"
                value={searchKeyData}
                onChange={(e) => sourceHandle(e)}
              >
                <option defaultValue value=""></option>
                {
                  sourceList?.map((val) => {
                    return (
                      <>
                        <option key={val.key} value={val.key}>{val.longname} ({val.key})</option>
                      </>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xxl-3 mt-3">
          <div className="row align-items-center g-0">
            <div className="col-8 col-sm-8 col-md-12 col-lg-12 col-xxl-12 mx-auto mt-3">
              <button
                className="btn addEcn-search-button"
                onClick={() => searchOrderByOrderNo()}
                disabled={!searchData || !permission.some((res) => res === "search")}
              >
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchOrder;
