import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Reason from "../../components/Share/FormRequestCancel/reason";
import ChannelRefund from "../../components/Share/FormRequestCancel/channelRefund";
import TrackingModal from "../../components/Share/TrackingModal/index";
import SummaryRefundCancelView from "../../components/ContactCenter/FormRequestCancel/SummaryRefundCancelView";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import "./ContactCenter.scss";
import { CustomerInfoContactCenter } from "../../components/ContactCenter/CustomerInfo";
import {
  getOrderCancellationInfoDetails,
  getCancellationRefundMaster,
  getOrderCancellationRequestTimeline,
} from "../../../../service/grrs/contactCenter.service";
import { getSummaryRefund } from "../../../../helper/grrs/redeemAndEarnPointHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";

const initCancellationRefundMaster = {
  reason_list: [],

  refund_channel: [],

  bank_master: {
    bank_account: [],

    bank_account_type: [],
  },
  cancel_policy_link: "",
};

const initTrackingData = {
  orders: {
    order_tracking_status: [],
    tracking_timeline: [],
  },
};

const initCancellationInitial = {
  orders: {
    total_price: 0,
    order_detail_list: [],
    redeemedSummary: [],
    earnPoints: [],
  },
  request_activity_log: [],
};

const initReason = {
  reason_code: "",
  reason_remark: "",
  is_all_member_point_compensation: 0,
  cc_remark: "",
};

const initChannelRefund = {
  channel_code: "",
  bank_account_type_code: "",
  promtpay_no: "",
  bank_code: "",
  account_no: "",
  account_name: "",
};

function RequestCancelDetail(props) {
  const [channelRefundForm, setChannelRefundForm] = useState(initChannelRefund);
  const [cancellationRefundMaster, setCancellationRefundMaster] = useState(
    initCancellationRefundMaster
  );
  const [cancellationInitial, setCancellationInitial] = useState(
    initCancellationInitial
  );
  const [reason, setReason] = useState(initReason);
  const [isChannelRefundFormValid, setIsChannelRefundFormValid] =
    useState(false);
  const [customerDetail, setcustomerDetail] = useState({});
  const [isDisplay, setIsDisplay] = useState(true);
  const [isCashMode, setIsCashMode] = useState(false);
  const [trackingData, setTrackingData] = useState(initTrackingData);
  const history = useHistory();
  const { mainOrderNo, ordersource } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    refundAllMemberPoint,
    refundNonAllMemberPoint,
    cancelAllMemberPoint,
    cancelNonAllMemberPoint,
  } = getSummaryRefund(cancellationInitial?.orders);

  const getOrderCancellationInfoDetailsFromApi = () => {
    getOrderCancellationInfoDetails(mainOrderNo, ordersource).then((res) => {
      getOrderCancellationRequestTimeline(
        res?.response_data?.orders?.customer_request_no
      ).then((response) => {
        if (response?.response_data !== undefined)
          setTrackingData(response?.response_data);
          setIsLoading(false);
      });
      if (res.response_data !== undefined) {
        setCancellationInitial(res?.response_data);
        setcustomerDetail(res?.response_data);
        setReason(res?.response_data?.orders?.reason);
        setChannelRefundForm(res?.response_data?.orders?.refund_method);
      }
    });
  };

  const getCancellationRefundMasterFromApi = () => {
    getCancellationRefundMaster(mainOrderNo, ordersource).then((res) => {
      if (res?.response_data !== undefined)
        setCancellationRefundMaster(res?.response_data);
      if (res?.response_data?.refund_channel.length === 1) {
        setIsCashMode(false);
      } else {
        setIsCashMode(true);
      }
    });
  };

  const backpage = () => {
    const location = { pathname: "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list" };
    history.push(location, { previous: "request-cancel" });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderCancellationInfoDetailsFromApi();
    getCancellationRefundMasterFromApi();
  }, []);

  return (
    <>
      <div className="container">
        <div className="layout">
          <div className="row vh-100">
            <div className="row">
              <ResponsiveSideBar />
              <div className="col-md-9 content">
                <LoadingSpinner isLoading={isLoading} />
                <div className="container">
                  <div className="grrs-row d-flex order-detail margin-left">
                    <button className="back-button" onClick={() => backpage()}>
                      <img src="/assets/icons/backicon.svg" alt="back" />
                    </button>
                    <span className="mt-3">
                      <h1>ทำการยกเลิกสินค้า #{mainOrderNo}</h1>
                    </span>
                  </div>
                  {!isLoading &&
                    (
                      <>
                        <div className="status-request-cancel-detail d-flex mt-5 mb-5">
                          <div className="d-flex">
                            <p className="status-cancel">ยกเลิกคำสั่งซื้อแล้ว</p>
                            <p className="date-cancel">{trackingData.orders.create_date}</p>
                          </div>
                          <div>
                            <TrackingModal trackingData={trackingData}
                              request_refund_status_text={cancellationInitial.orders.request_refund_status_text}
                            />
                          </div>
                        </div>
                        <Reason
                          reason={reason}
                          isCanEdit={false}
                          cancellationRefundMaster={cancellationRefundMaster}
                        />
                        <ChannelRefund
                          cancellationInitial={cancellationInitial}
                          cancellationRefundMaster={cancellationRefundMaster}
                          isCashMode={isCashMode}
                          isCanEdit={false}
                          channelRefundForm={channelRefundForm}
                          isContactCenter={false}
                          setIsChannelRefundFormValid={setIsChannelRefundFormValid}
                          IsCanChangeEditMode={false}
                        />
                        <CustomerInfoContactCenter
                          customerDetail={customerDetail}
                          isDisplay={isDisplay}
                          setIsDisplay={setIsDisplay}
                        />
                        <SummaryRefundCancelView
                          cancellationInitial={cancellationInitial}
                          refundAllMemberPoint={refundAllMemberPoint}
                          refundNonAllMemberPoint={refundNonAllMemberPoint}
                          cancelAllMemberPoint={cancelAllMemberPoint}
                          cancelNonAllMemberPoint={cancelNonAllMemberPoint}
                        />
                        <div className="mt-5 mb-5 d-flex justify-content-center">
                          <button
                            className="request-cancel-backButton grrs-primary-button"
                            onClick={() => backpage()}
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestCancelDetail;
