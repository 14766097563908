import React, { useState, useContext, useEffect } from 'react'
import EditModal from '../components/UserManagement/EditFormModal';
import "../../../scss/userMaintenance/_userManagementForm.scss";
import MenuContext from "../../../context/MenuContext";
import { useHistory, useParams, Link } from "react-router-dom";
import { getRole_id, getUserByID } from "../../../service/userMaintenance/userManagement.service";

const UserManagementEditForm = () => {
    const { backSearch, profileUser, permission } = useContext(MenuContext);

    let { username } = useParams();
    const [enterUsername, setEnterUsername] = useState("");
    const [operationName, setOperationName] = useState("");
    const [checked, setChecked] = useState([]);
    const [active, setActive] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [userRoleList, setUserRoleList] = useState([]);

    useEffect(() => {
        getUsersDetail();
    }, []);

    const getUsersDetail = async () => {
        let request = {
            "username": username,
        }
        const res = await getUserByID(request);
        setOperationName(profileUser?.email);
        setEnterUsername(username);
        setActive(res?.user[0].active);
        getRoleId(res?.user[0].role_id);
        formatRoleIdListUser(res.user[0].role_id)
    }

    const getRoleId = async (roleIDList) => {
        try {
            const res = await getRole_id();
            let tempData = res?.roles.map((item) => {
                if (roleIDList.includes(item.role_id) === true) {
                    return {
                        ...item,
                        checked: true
                    };
                } else {
                    return {
                        ...item,
                        checked: false
                    };
                }
            });
            setRoleList(tempData);
        } catch (err) {
            console.log(err);
        }
    }

    const formatRoleIdListUser = (roleIDList) => {
        const roleIdListUser = {};
        for (const roleID of roleIDList) {
            roleIdListUser[roleID] = roleID;
        }
        setUserRoleList(roleIdListUser);
    }

    function handleCheck(event) {
        const index = event.target.name;
        const isChecked = event.target.checked;
        const tempData = [...roleList];
        tempData[index].checked = isChecked;
        setRoleList(tempData);
    }

    useEffect(() => {
        let tempArray = roleList.filter((item) => item.checked === true).map((role) => role.role_id)
        setChecked(tempArray);
    }, [roleList]);

    let history = useHistory();
    const backMainPage = (backPath) => {
        history.push(backPath);
    };

    return (
        <>
            <div className="container-md user-management">
                <div className="title-name">
                    <button onClick={() => backMainPage(backSearch)}>
                        <span>&#8592;</span>
                    </button>
                    <label>
                        Edit User
                    </label>
                </div>
                <div className='form-bg'>
                    <div className='username-input'>
                        <label>Email</label>
                        <input
                            type="text"
                            className="form-control"
                            value={enterUsername}
                            disabled
                        />
                        <br />
                    </div>
                    <div className='menu-usermanagement mt-2'>
                        <div>
                            <label>Menu</label>
                        </div>
                        <div className='role-label'>
                            <label className='black'>Role</label>
                        </div>
                        <div className='role-scroll-bar'>
                            {roleList.map((item, index) => {
                                if (item.active === "Y" || (userRoleList[item.role_id])) {
                                    return (
                                        <div key={index} className="form-check-user-management">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name={index}
                                                id={item.role_id}
                                                value={item.role_id}
                                                onChange={handleCheck}
                                                checked={item.checked}
                                                disabled={item.active === "N"}
                                            />
                                            <label class="form-check-label" >
                                                {item.role_name}
                                            </label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <br />
                    <div className='user-status'>
                        <div>
                            <label>Status</label>
                        </div>
                        <div>
                            <select
                                className="role-select"
                                onChange={(e) => setActive(e.target.value)}
                                value={active}
                            >
                                <option key="1" value="Y">Active</option>
                                <option key="2" value="N">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div className="button-save-cancle">
                        <Link to="/user-maintenance/user-management">
                            <button className="button-cancle">ยกเลิก</button>
                        </Link>
                        <button
                            className={!permission.some((res) => res === "update") ? "button-save disable-button" : "button-save"}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop2"
                            disabled={!permission.some((res) => res === "update")}
                        >
                            บันทึก
                        </button>
                    </div>
                    <EditModal
                        operationName={operationName}
                        enterusername={enterUsername}
                        checked={checked}
                        active={active}
                    />
                </div>
            </div>
        </>
    )
}

export default UserManagementEditForm;