import React, { useEffect, useContext } from "react";
import ResponsiveSideBar from "../../common/components/ResponsiveSideBar";
import { Col, Container, Row } from "react-bootstrap";
import DataExport from "../components/DataExport";
import ServiceArea from "../components/ServiceArea";
import OrderList from "../components/OrderList";
import ReturnWareHouse from "../components/ReturnWareHouse";
import Blank from "../../common/components/Blank";
import MenuContext from "../../../context/MenuContext";
import { getMenuByMenuId, getUserPermission } from "../../../service/customer-care.service";
import pathModule from "./../../../utils/path/pathModule.json";

function Main(props) {
  const pathName = props.location.pathname;

  const contractCustomerPath = pathModule.free_delivery.contract_customer.path;
  const returnWarehousePath = pathModule.free_delivery.return_warehouse.path;
  const outOfDeliveryPath = pathModule.free_delivery.out_of_delivery.path;

  const { menu, setMenu, setUserRole, profileUser, setPermission } = useContext(MenuContext);

  useEffect(() => {
    if (menu?.length === 0 || menu?.menu_id !== "22") {
      if (profileUser) {
        getMenuByMenuId("22", profileUser.email).then((res) => {
          setMenu(res?.menu_list[0]);
          setUserRole(res?.role[0]);
        });

      }
    }
  }, []);

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content">
              <div>
                {pathName === "export-data" ? (
                  <DataExport />
                ) : pathName === outOfDeliveryPath ? (
                  <ServiceArea />
                ) : pathName === contractCustomerPath ? (
                  <OrderList />
                ) : pathName === returnWarehousePath ? (
                  <ReturnWareHouse />
                ) : (
                  <Blank />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default Main;
