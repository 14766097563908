import RoleListTable from "./RoleListTable";
import "../../../../scss/userMaintenance/roleManagement/_roleList.scss";
import SearchRoleList from "./SearchRoleList";
import RoleButton from "./RoleButton";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { getRole } from "../../../../service/userMaintenance/roleManagement.service";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import Pagination from "../../../common/components/Pagination";
import { paginate } from "../../../../utils/paginate";
import NotFound from "./NotFound";
import MenuContex from "../../../../context/MenuContext.js";
import PermissionModal from "../../../common/components/PermissionModal";

function RoleList() {
  const [isLoad, setIsLoad] = useState(false);
  const [roles, setRoles] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const { permission } = useContext(MenuContex);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  const handleChangePageSize = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginatedData = paginate(roles, currentPage, pageSize);

  const fetchDataByRole = async (role) => {
    setIsLoad(true);
    const roleFromAPI = await getRole(role);
    const roleData = roleFromAPI.data.response_data.role_list;
    setRoles(roleData);
    setIsLoad(false);
  };

  useEffect(() => {
    setIsLoad(true);
    const fetchData = async () => {
      const roleFromAPI = await getRole("");
      const roleData = roleFromAPI.data.response_data.role_list;
      setRoles(roleData);
      setIsLoad(false);
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="roleList">
        <h1 className="roleList-header">Role Management</h1>
        <SearchRoleList
          label="Role"
          searchFunction={fetchDataByRole}
          maxLength={20}
        />
        <Link to="/user-maintenance/role-add-role">
          <RoleButton text="Add New Role" />
        </Link>
        {!isLoad && permission?.length !== 0 && (
          <RoleListTable roles={paginatedData} />
        )}

        <LoadingSpinner isLoading={isLoad} />
        {paginatedData && roles.length > 0 && (
          <Pagination
            itemsCount={roles?.length}
            pageSize={pageSize}
            onPageSizeChange={handleChangePageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      <PermissionModal permission={permission} backPage={"/user-maintenance"} />
    </div>
  );
}

export default RoleList;
