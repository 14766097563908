import React from "react";
import "./Popup.scss";

function Generate({ generateFile }) {

    const handleClose = () => {
        if (generateFile?.response_code === undefined) {
            document.getElementById("generate-modal-close-btn").click();
        } else {
            document.getElementById("generate-modal-close-btn").click();
            window.location.reload(false);
        }
    }

    return (
        <>
            <button type="button" id="generate-file-btn" className="btn btn-primary generate-file-modal-button" data-bs-toggle="modal" data-bs-target="#generateFile" hidden>
                ตกลง
            </button>
            <div className="generateFileModal">
                <div className="modal fade" id="generateFile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="generateFileLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="generate-modal-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                {
                                    generateFile?.response_code === undefined ? (
                                        <>
                                            <div className="row space-bottom">
                                                <div className="col-12 text-center">
                                                    <div className="">กรุณาเลือกข้อมูลที่ต้องการก่อนกดปุ่มสร้างไฟล์</div>
                                                </div>
                                            </div>
                                        </>
                                    ) : generateFile?.response_code === 100 ? (
                                        <>
                                            <div className="row space-bottom">
                                                <div className="col-12 text-center">
                                                    <div className="fw-bold gen-file-title">generate ไฟล์สำเร็จ</div>
                                                </div>
                                            </div>
                                            {
                                                generateFile?.response_data.refund_file.map(file =>
                                                    <div className="row g-0">
                                                        <div className="col-12 text-center gen-file-name-section">
                                                            <ul className="gen-file-name">
                                                                <li className="list-gen-file-name">{file}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    ) : generateFile?.response_code === 401 ? (
                                        <>
                                            <div className="row space-bottom">
                                                <div className="col-12 text-center">
                                                    <div className="fw-bold gen-file-title">generate ไฟล์ไม่สำเร็จ</div>
                                                </div>
                                            </div>
                                            <div className="row g-0">
                                                <div className="col-12 text-center gen-file-name-section">
                                                    <div className="gen-file-name">
                                                        <div className="list-gen-file-name">
                                                            {generateFile?.response_data?.message}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) :
                                        (
                                            <>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <div className="fw-bold">generate ไฟล์ไม่สำเร็จ</div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                }
                                <div className="row justify-content-center btn-section">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn grrs-popup-close-btn grrs-primary-button" onClick={handleClose}>ปิด</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default Generate;