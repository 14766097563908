import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { ExportExcel } from '../../../utils/exportExcel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import PreviewData from './PreviewData';
import { Fade } from 'react-bootstrap';

const ExportData = () => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const [open, setOpen] = useState(false);

    const [data, setData] = useState([]);
    const fileName = "export_data";


    useEffect(() => {
        const fetchData = () => {
            axios.get('https://6182523f84c2020017d89dcf.mockapi.io/customercare/users').then(r => setData(r.data));
        }
        fetchData();
    }, [])

    return (
        <>
            <div className="container">
                <div className="export-input">
                    <div className="export-input-elements">
                        <span className="export-elements-span">วันที่เริ่มต้น :</span>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                        />
                        <span className="calendar-icon">
                            <FontAwesomeIcon icon={faCalendar} />
                        </span>
                    </div>
                    <div className="export-input-elements">
                        <span className="export-elements-span">วันที่สิ้นสุด :</span>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                        />
                        <span className="calendar-icon">
                            <FontAwesomeIcon icon={faCalendar} />
                        </span>
                    </div>
                    <div className="export-input-elements">
                        <span className="export-elements-span">ประเภท :</span>
                        <select className="form-select status dropdown-text" aria-label="Default select example">
                            <option value="อยู่ระหว่างดำเนินการ">นอกพื้นที่จัดส่ง</option>
                            <option value="เรียบร้อยแล้ว">ติดต่อไม่ได้</option>
                        </select>
                    </div>
                    <div className="export-input-elements">
                        <span className="export-elements-span">สถานะ :</span>
                        <select className="form-select status dropdown-text" aria-label="Default select example">
                            <option value="อยู่ระหว่างดำเนินการ">อยู่ระหว่างดำเนินการ</option>
                            <option value="เรียบร้อยแล้ว">เรียบร้อยแล้ว</option>
                        </select>
                    </div>
                </div>

            </div>

            <div className="preview-data">
                <button className="preview-data-button"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-fade-text"
                    aria-expanded={open}
                >
                    ดูตัวอย่างข้อมูล
                </button>
                <Fade in={open}>
                    <div id="example-fade-text">
                        <div className="outline-preview-data-table">
                            <PreviewData />
                        </div>
                        <container className="cancle-export">
                            <button className="cancle-button">ยกเลิก</button>
                            <button className="export-button">{<ExportExcel apiData={data} fileName={fileName} />}</button>
                        </container>
                    </div>
                </Fade>
            </div>
        </>
    );
}

export default ExportData;