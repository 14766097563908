import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportExcel = (dataList, fileName, setIsAlertName, setAlertHandle, returnFromModal, setModalAlertHandle) => {

const checkIsConfirm = () => {
  setModalAlertHandle();
}

  if (dataList.length === 0) {
    setIsAlertName("warning-export"); 
    setAlertHandle();
  }
  else if (dataList.length !== 0) {
    checkIsConfirm();
    let isConfirm = returnFromModal;
    if (isConfirm === true) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataList);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
};