import React, { useState, useContext, useEffect } from "react";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./ContactCenter.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import { postGetOrderHistoryByMemberId } from "../../../../service/grrs/contactCenter.service";
import { numberWithCommas } from "../../../../helper/commonHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";

function OrderList() {
  const { setDisplayOtp } = useContext(DataContext);
  const [searchInput, setSearchInput] = useState("");
  const [isDisplay, setIsDisplay] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(JSON.parse(localStorage.getItem("grrs-load-more-btn")));
  const [isNotFound, setIsNotFound] = useState(true);
  const [isSearchInput, setIsSearchInput] = useState(false);
  const [isCancelSearch, setIsCancelSearch] = useState(false);
  const [loadMoreNotFound, setLoadMoreNotFound] = useState(false);
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [searchOrderNo, setSearchOrderNo] = useState("");
  let [newObj, setNewObj] = useState({ orders: [] });
  const [initialOrderList, setInitialOrderList] = useState({ orders: [] });
  const orderStorage = JSON.parse(localStorage.getItem("grrs-order"));
  const ordersource = JSON.parse(localStorage.getItem("grrs-ordersource"));
  const isOrderHistoryCompleted = JSON.parse(localStorage.getItem("grrs-isOrderCancelCompleted"));
  const [memberId, setMemberId] = useState(orderStorage.member_id);
  const [firstName, setFirstName] = useState(orderStorage.firstname);
  const [lastName, setLastName] = useState(orderStorage.lastname);
  const [phoneNo, setPhoneNo] = useState(orderStorage.phone_no);
  let history = useHistory();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const isEmptyArr = newObj?.orders?.length;
  const isApprove = (list) =>
    list.some((e) => e.order_detail_status_group !== "OTHER_CANCELLED" && e.order_detail_status === "ยกเลิกรายการสั่งซื้อ");

  const union = (array1, array2, key) => [...new Map([...array1, ...array2].map(o => [o[key], o])).values()];

  if (state === undefined) {
    history.push("/grrs/contact-center/สร้างคำขอแทนลูกค้า");
  }

  const getOrderHistoryByMemberIdFromApi = (memberId, phoneNo, ordersource, isOrderHistoryCompleted) => {
    setIsLoading(true);
    let request = {
      memberId,
      phoneNo,
      ordersource,
      isOrderHistoryCompleted,
    };
    postGetOrderHistoryByMemberId(request).then((res) => {
      if (res.response_code === 100 && res?.response_data?.orders !== null) {
        localStorage.setItem("grrs-load-more-btn", JSON.stringify(false));
        setNewObj({ orders: union(newObj.orders, res.response_data.orders, "mainorder_no") });
        setIsLoadMore(false);
        setIsNotFound(false);
      } else {
        localStorage.setItem("grrs-load-more-btn", JSON.stringify(false));
        setIsLoadMore(false);
        setIsNotFound(false);
        setLoadMoreNotFound(true);
      }
      setIsLoading(false);
    });
  };

  const searchOrder = (searchInput) => {
    setIsCancelSearch(true);
    setSearchOrderNo(searchInput);
    const filterItem = initialOrderList.orders.filter((order) => {
      return order.order_no.indexOf(searchInput) > -1;
    });
    if (filterItem.length != 0) {
      setNewObj({ orders: filterItem });
      setIsSearchInput(true);
    } else {
      setNewObj({ orders: [] });
      setIsNotFound(true);
      setIsSearchInput(false);
      setSearchNotFound(true);
    }
  };

  const cancelSearch = () => {
    setSearchInput("");
    setIsSearchInput(false);
    setIsCancelSearch(false);
    setIsNotFound(false);
    setSearchNotFound(false);
    setNewObj({ orders: initialOrderList.orders });
  };
  const backpage = () => {
    const location = { pathname: "/grrs/contact-center/สร้างคำขอแทนลูกค้า" };
    history.push(location);
  };

  const backToOtpModal = () => {
    setDisplayOtp(true);
    const location = { pathname: "/grrs/contact-center/สร้างคำขอแทนลูกค้า" };
    history.push(location);
  };

  const getOrderHistoryByMemberId = (memberId, isOrderHistoryCompleted) => {
    let request = {
      memberId,
      isOrderHistoryCompleted,
    };
    postGetOrderHistoryByMemberId(request).then((res) => {
      if (res?.response_data?.orders !== null) {
        setNewObj({ orders: res?.response_data?.orders });
        setInitialOrderList({ orders: res?.response_data?.orders });
      } else {
        setLoadMoreNotFound(true);
      }
    });
  };

  useEffect(() => {
    getOrderHistoryByMemberId(orderStorage.member_id, isOrderHistoryCompleted);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000)
  }, []);
  return (
    <>
      <div className="container">
        <div className="layout">
          <div className="row vh-100">
            <div className="row">
              <ResponsiveSideBar />
              <div className="col-md-9 content">
                <LoadingSpinner isLoading={isLoading} />
                <div className="grrs-container">
                  <div className="grrs-row d-flex flex-nowrap">
                    <button className="back-button" onClick={() => backpage()}>
                      <img src="/assets/icons/backicon.svg" alt="back" />
                    </button>
                    <span>
                      <h1 className="mt-3">รายการสั่งซื้อสินค้าทั้งหมด</h1>
                    </span>
                  </div>
                  {!isLoading &&
                    (
                      <>
                        <div className="form-contact-center-orderlist">
                          <div className="header-customer-info">
                            <span className="hide-icon">
                              <img
                                className=""
                                src={
                                  isDisplay
                                    ? "/assets/grrs/hide-icon.svg"
                                    : "/assets/grrs/unhide-icon.svg"
                                }
                                alt="hide"
                                onClick={() => setIsDisplay(!isDisplay)}
                              />
                            </span>
                            <span className="fw-bold">ข้อมูลลูกค้า</span>
                          </div>
                          {isDisplay && (
                            <div className="body-form-contact-center">
                              <div className="row">
                                <div className="col-md-6 col-orderlist">
                                  <label className="form-label">
                                    ALL member ID / Customer ID
                                  </label>
                                  <input
                                    className="form-control"
                                    value={memberId}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-orderlist">
                                  <label className="form-label">ชื่อผู้สั่ง</label>
                                  <input
                                    className="form-control"
                                    value={`${firstName}  ${lastName}`}
                                    disabled
                                  />
                                </div>
                                <div className="col-md-6 col-orderlist">
                                  <label className="form-label">เบอร์ผู้สั่ง</label>
                                  <input
                                    className="form-control"
                                    value={phoneNo}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="form-contact-center-totalorder">
                          <div className="header-total-order">
                            <span className="hide-icon">
                              <img
                                className=""
                                src={
                                  isOpen
                                    ? "/assets/grrs/hide-icon.svg"
                                    : "/assets/grrs/unhide-icon.svg"
                                }
                                alt="hide"
                                onClick={() => setIsOpen(!isOpen)}
                              />
                            </span>
                            <span className="fw-bold">รายการสั่งซื้อทั้งหมด</span>
                          </div>
                          {isOpen && (
                            <div className="body-form-contact-center">
                              <div className="row mb-3">
                                <div className="col-md-5 col-orderlist">
                                  {isSearchInput && (
                                    <h3>
                                      พบ <span>{newObj?.orders?.length}</span> รายการ
                                      สำหรับการค้นหา "<span>{searchOrderNo}</span>"
                                    </h3>
                                  )}
                                </div>
                                <div className="col-md-7 col-orderlist">
                                  <div className="d-flex">
                                    <input
                                      className="form-control search-order"
                                      placeholder="ค้นหาเลขที่ใบสั่งซื้อ"
                                      onChange={(e) => setSearchInput(e.target.value)}
                                      value={searchInput}
                                      maxLength={10}
                                    />
                                    <button
                                      className="searchInput-button"
                                      onClick={() => searchOrder(searchInput)}
                                      disabled={!searchInput}
                                    >
                                      <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                  </div>
                                  {isCancelSearch && (
                                    <div className="mt-3">
                                      <button
                                        className="cancelSearch-button"
                                        onClick={() => cancelSearch()}
                                      >
                                        ยกเลิกผลการค้นหา
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {isEmptyArr != 0 ? (
                                <>
                                  {newObj?.orders?.map((order, index) => (
                                    <div key={index} className="row mb-5">
                                      <div className="col-md-12">
                                        <div className="card grrs-contact-center-orderlist">
                                          <div className="detail-total-order">
                                            <span className="fw-bold">
                                              {order?.ordersource_name}
                                            </span>
                                            <span className="margin-left">
                                              หมายเลขสั่งซื้อ #{order?.order_no}
                                            </span>
                                            <span className="margin-left">
                                              {order?.order_date}
                                            </span>
                                          </div>
                                          <div className="text-decoration">
                                            {isApprove(order.order_detail_list) ? (
                                              <Link
                                                to={{
                                                  pathname: `/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail/cancellation-detail`,
                                                  mainOrderNo: order.mainorder_no,
                                                  ordersource: order.ordersource,
                                                }}
                                              >
                                                <span>
                                                  รายละเอียดขอยกเลิกการสั่งซื้อ
                                                </span>
                                              </Link>
                                            ) : (
                                              <Link
                                                to={{
                                                  pathname: `/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail`,
                                                  mainOrderNo: order.mainorder_no,
                                                  ordersource: order.ordersource
                                                }}
                                              >
                                                <span>รายละเอียดการสั่งซื้อ</span>
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {order.order_detail_list.map((item, index) => (
                                        <div key={index} className="col-md-12">
                                          <div className="card total-order">
                                            <div className="body-total-order">
                                              <h3>{item?.item_name}</h3>
                                              <h3
                                                className={
                                                  item?.order_detail_status_group === "CANCELLED" || item?.order_detail_status_group === "OTHER_CANCELLED"
                                                    ? "color-red"
                                                    : "color-green"
                                                }
                                              >
                                                {item?.order_detail_status}
                                              </h3>
                                            </div>
                                            <div className="amount-total-order">
                                              <h3>รหัสสินค้า {item?.item_no} </h3>
                                              <h3 className="color-gray">
                                                จำนวน {item?.qty} ชิ้น
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                      <div className="col-md-12">
                                        <div className="card net-amount">
                                          <h3>ยอดสุทธิ ฿ {numberWithCommas(order?.total_price)}</h3>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div
                                    className={
                                      isLoadMore === true
                                        ? "load-more-order"
                                        : "load-more-order d-none"
                                    }
                                  >
                                    <h3>
                                      หากไม่พบ “รายการสั่งซื้อ” ที่ต้องการ
                                      สามารถกดปุ่มด้านล่าง เพื่อโหลดข้อมูลเพิ่ม
                                    </h3>
                                    <button
                                      className="grrs-primary-button"
                                      onClick={() =>
                                        getOrderHistoryByMemberIdFromApi(
                                          orderStorage?.member_id,
                                          orderStorage?.phone_no,
                                          ordersource,
                                          isOrderHistoryCompleted
                                        )
                                      }
                                    >
                                      โหลดข้อมูล
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={
                                    isNotFound === true
                                      ? "load-more-order"
                                      : "load-more-order d-none"
                                  }
                                >
                                  {searchNotFound === true ? (
                                    <h3>
                                      ไม่พบผลการค้นหาสำหรับ “
                                      <span>{searchOrderNo}</span>”{" "}
                                    </h3>
                                  ) : (
                                    <h3>
                                      ระบบไม่พบ “รายการสั่งซื้อ” จากหมายเลข ALL member
                                      ID / Customer ID : {orderStorage?.member_id}{" "}
                                    </h3>
                                  )}
                                  {searchNotFound === true ? (
                                    <h3 className="mt-3">
                                      กรุณตรวจสอบคำค้นหา หรือ กดโหลดข้อมูลเพื่อค้นหา
                                      “รายการสั่งซื้อ” ด้วยเบอร์โทรศัพท์
                                    </h3>
                                  ) : (
                                    <h3 className="mt-3">
                                      กรุณกดโหลดข้อมูลเพื่อค้นหา “รายการสั่งซื้อ”
                                      ด้วยเบอร์โทรศัพท์
                                    </h3>
                                  )}
                                  <button
                                    className="grrs-primary-button"
                                    onClick={() =>
                                      getOrderHistoryByMemberIdFromApi(
                                        orderStorage?.member_id,
                                        orderStorage?.phone_no,
                                        ordersource,
                                        isOrderHistoryCompleted
                                      )
                                    }
                                  >
                                    โหลดข้อมูล
                                  </button>
                                </div>
                              )}
                              {loadMoreNotFound && (
                                <div
                                  className={
                                    isNotFound === false
                                      ? "load-more-order"
                                      : "load-more-order d-none"
                                  }
                                >
                                  <h3>
                                    ระบบไม่พบ “รายการสั่งซื้อ” จากเบอร์โทรศัพท์ :{" "}
                                    {orderStorage.phone_no}{" "}
                                  </h3>
                                  <h3 className="mt-3">
                                    กรุณาตรวจสอบเบอร์โทรศัพท์ของลูกค้าอีกครั้ง
                                  </h3>
                                  <button
                                    className="grrs-white-button"
                                    onClick={() => backToOtpModal()}
                                  >
                                    ย้อนกลับไป เปลี่ยนเบอร์โทรศัพท์
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderList;
