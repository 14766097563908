import axios from "axios";
export { getPreRequests1, uploadFile };
const EcnApiUrl = process.env.REACT_APP_ECN_API_URL;
const xApiKey = process.env.REACT_APP_ETAX_API_KEY;

const getPreRequests1 = async (id) => {
  const data = await axios
    .get(`${EcnApiUrl}/grrs/pre_request?order_no=${id}`, {
      headers: {
        "x-api-key": xApiKey,
      },
    })
    .then((res) => {
      if (res.data.response_code !== 100) return res.data;
      return res.data;
    })
    .catch((e) => {
      console.log("errorPreRequestService", e);
      return [];
    });
  return data;
};
const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const data = await axios({
    method: "post",
    url: `${EcnApiUrl}/grrs/pre_request/order/import`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data", "x-api-key": xApiKey },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log("errorService", e);
      return [];
    });
  return data.data;
};
